import {Injectable} from '@angular/core';
import {StorageHelper} from '../helpers/storage.helper';
import { User } from '../interfaces/user';
import {Location} from '../interfaces/location';
import { Locationuser } from '../interfaces/locationuser';
//import * as moment from 'moment';
import moment from 'moment';
import {AuthOption} from "../interfaces/auth-option";

@Injectable({
  providedIn: 'root'
})
export class Storage {
  MIDDLEWARE_TOKEN = 'TS_NA';
  MIDDLEWARE_TOKEN_ANT = 'TS_A'; //token anterior
  AUTH_TOKEN = 'TS_B';
  ACCESS_TOKEN = 'TS_C';
  USER = 'TS_D';
  APP_VERSION_COUNTER = 'TS_E';
  LOCATION = 'TS_F';
  NOTIFICATION_FLAG = 'TS_G';
  ENTITLEMENT = 'TS_H';
  ANONYMOUS_ID_SEGMENT = 'ajs_anonymous_id';
  TV_AUTH = 'TS_I';
  AUTH_TYPE = 'TS_J';
  TV_AUTH_CODE = 'TS_K';
  TV_AUTH_STATE = 'TS_L';
  DEVICE_UUID = 'TS_M';
  TEXTS = 'TS_N';
  TEXTS_V2 = 'TS_N_V2'
  PREVIOUS_ROUTE = 'TS_O';
  DEVICES_LIMIT = 'TS_P';
  TEAMS = 'TS_Q';
  VIDEO_QUALITY = 'TS_R'
  STATISTICS_CONFIG = "TS_S"
  VIDEO_ACTIVE = "TS_T";
  PROCESS_VERSION = "TS_U";
  CARD_SAVED = "TS_U";
  REFRESH_TOKEN = "TS_V";
  TOKEN_EXPIRE = "TS_W";
  PAYMENT_TEXT = 'TS_X';
  IS_WEB_VIEW = 'TS_Y';
  VIDEO_QUALITY_V2 = 'TS_VQ';
  LOGIN_PROVIDERS = 'TS_PL';
  LOCATION_USER = 'TS_LU';
  TOKEN_PERSONAL = 'TS_AA';
  USER_PERSONAL = 'TS_UP';
  OPEN_TIGO_ID = 'TS_OT';
  MIDDLE_AUTH = 'TS_MD';
  NETWORK_STATUS = 'TS_NS';
  VALID_PAYMENT = 'TS_VP';
  PLAYER_ACTIVE = 'TS_AB';
  TOKEN_TIGO = 'TS_AC';
  ACCESS_ID = 'TS_AD';
  TURN_OFF_SUSCRIPTION = 'TO_SP';
  TURN_OFF_PVV = 'PVV_SP';
  OBJ_PAYMENT = 'PT_GS';
  VERSION_TV = 'TS_AE';
  PERSONAL_FLOW = 'TA_AF';
  ROUTE_DETAIL = 'TS_RD';
  TOKEN_REFRESH_TIGO ='TS_RT';
  MAINTENANCE_OPTS = "TS_MO";
  PLATFORM_INFO = "TS_PI";
  LOGIN_TYPE = "TS_LT";
  LOGIN_TYPE_OPTION = "TS_LTO";
  LOGIN_IMG_CACHE_LOGO = "TS_IMGLOG";
  LOGIN_IMG_CACHE_LOADIGN = "TS_IMGLOAD";
  LOGIN_IMG_CACHE_ALERT = "TS_IMGCALER";
  FLAG_IS_CONECT = "TS_FLAG_IS_CONECT";
  PAYMENT_PAYPAL = 'TS_PMP';
  DEVICE_NT = 'TS_NMTV';
  IMG_CURRENT = 'TS_ICU';
  LOAD_PAGE_USERCONCURRENT = 'TS_UCL';
  CACHE_MASTER_ACCOUNT = 'TS_CMACC';
  CACHE_STREAMING_PLAY = 'TS_CSPY';
  CALLBACK_I = 'TS_INSDE';
  IS_CHMDON = 'TS_HCL';
  FLAG_SHOW_FAVORITE_TEAMS = 'TS_FSFT';
  FOLLOWED_LEAGUES = 'TS_FL';
  TS_AUTH0 = 'TS_MHAT';
  TS_IDTOKEN = 'IdToken';
  TS_REFRESHTOKEN = 'refreshToken';
  TS_PLATFORM = 'TS_PLATFORM';
  VS_DI = 'SV_IP';
  BD_CH = 'DV_HC';
  IS_PIP = 'IS_PIP';
  CHD_INITST = 'CHD_INITST';
  OC_PLUS = 'OC_PLUS';
  KB_TV = 'KB_TV';


  constructor(
    private storageHelper: StorageHelper,
  ) {
  }

  /* Get middleware token authorization from storage */
  public getMiddlewareAuthorization(): string {
    return this.storageHelper.get(this.MIDDLEWARE_TOKEN);
  }

  /* Set middleware token authorization in storage */
  public setMiddlewareAuthorization(value: string): void {
    this.storageHelper.set(this.MIDDLEWARE_TOKEN, value);
  }

  public removeMiddlewareAuthorization(): void {
    this.storageHelper.remove(this.MIDDLEWARE_TOKEN);
  }

  /* Get middleware token authorization from storage */
  public getOldMiddlewareAuthorization(): string {
    return this.storageHelper.get(this.MIDDLEWARE_TOKEN_ANT);
  }

  /* Set middleware token authorization in storage */
  public setOldMiddlewareAuthorization(value: string): void {
    this.storageHelper.set(this.MIDDLEWARE_TOKEN_ANT, value);
  }

  public removeOldMiddlewareAuthorization(): void {
    this.storageHelper.remove(this.MIDDLEWARE_TOKEN_ANT);
  }


  /* Get environment version from storage */
  public getEnvironmentVersion(): string {
    return this.storageHelper.get(this.PROCESS_VERSION);
  }

  /* Set environment version in storage */
  public setEnvironmentVersion(value: string): void {
    this.storageHelper.set(this.PROCESS_VERSION, value);
  }

  public removeEnvironmentVersion(): void {
    this.storageHelper.remove(this.PROCESS_VERSION);
  }

  /* Get user token from storage */
  public getAuthToken(): string {
    return this.storageHelper.get(this.AUTH_TOKEN);
  }

  /* Set user token in storage */
  public setAuthToken(value: string): void {
    this.storageHelper.set(this.AUTH_TOKEN, value);
  }

  /* Remove user token from storage */
  public removeAuthToken(): void {
    this.storageHelper.remove(this.AUTH_TOKEN);
  }

  /* Get user token from storage */
  public getRefreshToken(): string {
    return this.storageHelper.get(this.REFRESH_TOKEN);
  }

  /* Set user token in storage */
  public setRefreshToken(value: string): void {
    this.storageHelper.set(this.REFRESH_TOKEN, value);
  }

  /* Remove user token from storage */
  public removeRefreshToken(): void {
    this.storageHelper.remove(this.REFRESH_TOKEN);
  }

  /* Get user token from storage */
  public getTokenExpires(): string {
    return this.storageHelper.get(this.TOKEN_EXPIRE);
  }

  /* Set user token in storage */
  public setTokenExpires(value: string): void {
    this.storageHelper.set(this.TOKEN_EXPIRE, value);
  }

  /* Remove user token from storage */
  public removeTokenExpires(): void {
    this.storageHelper.remove(this.TOKEN_EXPIRE);
  }

  /* Get user access token from storage */
  public getAccessToken(): string {
    return this.storageHelper.get(this.ACCESS_TOKEN);
  }

  /* set user access token from storage */
  public setAccessToken(value: string): void {
    this.storageHelper.set(this.ACCESS_TOKEN, value);
  }

  /* Remove user access token from storage */
  public removeAccessToken(): void {
    this.storageHelper.remove(this.ACCESS_TOKEN);
  }

  /* Get user data from storage */
  public getUser(): any {
    let user: any;
    const storedUser = this.storageHelper.get(this.USER);
    if (storedUser) {
      user = JSON.parse(storedUser);
    }
    return user;
  }

  /* Set user data in storage */
  public setUser(value: User): void {
    this.storageHelper.set(this.USER, JSON.stringify(value));
  }

  /* Remove user data from storage */
  public removeUser(): void {
    this.storageHelper.remove(this.USER);
  }

  /* Set user data in storage */
  public setUserCarrierBilling(value: any): void {
    this.storageHelper.set(this.USER_PERSONAL, JSON.stringify(value));
  }

  /* Get user data from storage */
  public getUserCarrierBilling() {
    let user;
    const storedUser = this.storageHelper.get(this.USER_PERSONAL);
    if (storedUser) {
      user = JSON.parse(storedUser);
    }
    return user;
  }

  /* Remove user data from storage */
  public removeUserPersonal(): void {
    this.storageHelper.remove(this.USER_PERSONAL);
  }

  /* Increment App Version Counter */
  public incrementAppVersionCounter(): void {
    const currentCounter = this.getAppVersionCounter();
    this.storageHelper.set(this.APP_VERSION_COUNTER, String(currentCounter + 1));
  }

  /* Get App Version Counter from storage */
  public getAppVersionCounter(): number {
    let counter = 0;
    const storedAppVersionCounter = this.storageHelper.get(this.APP_VERSION_COUNTER);
    if (storedAppVersionCounter) {
      counter = Number(storedAppVersionCounter);
    }

    return counter;
  }

  /* Remove App Version Counter in storage */
  public removeAppVersionCounter(): void {
    this.storageHelper.remove(this.APP_VERSION_COUNTER);
  }

  /* Get location from storage */
  public getLocation(): Location {
    let location: Location;
    const storedLocation = this.storageHelper.get(this.LOCATION);
    if (storedLocation) {
      location = JSON.parse(storedLocation);
    }

    return location;
  }

  /* Set location in storage */
  public setLocation(value: Location): void {
    this.storageHelper.set(this.LOCATION, JSON.stringify(value));
  }

  /* Get LocationUser from storage */
  public getLocationUser(): Locationuser {
    let locationuser: Locationuser;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const StorageLocationUser = this.storageHelper.get(this.LOCATION_USER);
    if (StorageLocationUser) {
      locationuser = JSON.parse(StorageLocationUser);
    }
    return locationuser;
  }

  /* Get locationUser in Storage */
  public setLocationUser(value: Locationuser): void {
    const location = {
      data: {},
      createdAt: moment()
    };
    if (value) {
      location.data = value;
    } else {
      location.data = '';
    }
    this.storageHelper.set(this.LOCATION_USER, JSON.stringify(location));
  }

  /* Remove locationuser in sotrage *//* Remove locationuser in sotrage */
  public removelocationUser(): void {
    this.storageHelper.remove(this.LOCATION_USER);
  }

  /* Get favorite config from storage */
  public getFavoriteTeams(): boolean {
    return Boolean(this.storageHelper.get(this.FLAG_SHOW_FAVORITE_TEAMS));
  }

  /* Set favorite config in storage */
  public setFavoriteTeams(value: boolean): void {
    this.storageHelper.set(this.FLAG_SHOW_FAVORITE_TEAMS, value.toString());
  }

  public removeFavoriteTeams(): void {
    this.storageHelper.remove(this.FLAG_SHOW_FAVORITE_TEAMS);
  }

  /* Get followed leagues from storage */
  public getFollowedLeagues() {
    let followedLeagues = [];
    const storedLeagues = this.storageHelper.get(this.FOLLOWED_LEAGUES);
    if (storedLeagues) {
      followedLeagues = JSON.parse(storedLeagues);
    }
    return followedLeagues;
  }

  /* Set followed leagues in storage */
  public setFollowedLeagues(value: any): void {
    this.storageHelper.set(this.FOLLOWED_LEAGUES, JSON.stringify(value));
  }

  public removeFollowedLeagues(): void {
    this.storageHelper.remove(this.FOLLOWED_LEAGUES);
  }

  /* Get notifications flag from storage */
  public getNotificationsFlag(): string {
    return this.storageHelper.get(this.NOTIFICATION_FLAG) ?? 'true';
  }

  /* Set notifications flag in storage */
  public setNotificationsFlag(value: string): void {
    this.storageHelper.set(this.NOTIFICATION_FLAG, value);
  }

  /* Get user entitlement from storage */
  public getEntitlement(): any {
    let entitlement;
    const storedEntitlement = this.storageHelper.get(this.ENTITLEMENT);
    if (storedEntitlement) {
      entitlement = JSON.parse(storedEntitlement);
    } else {
      entitlement = {
        status: false,
        label: ''
      };
    }
    return entitlement;
  }

  /* Set user entitlement in storage */
  public setEntitlement(value: boolean, user: User): any {
    const entitlement = {
      status: value,
      label: '',
      userType: (user.authentication_type === 'other' || user.authentication_method === 'toolbox'? 'non_tigo' : 'tigo')
    };

    if (value) {
      if (entitlement.userType === 'tigo') {
        entitlement.label = 'tigo_entitled';
      } else {
        entitlement.label = 'non_tigo_entitled';
      }
    } else {
      entitlement.label = 'not_entitled';
    }

    this.storageHelper.set(this.ENTITLEMENT, JSON.stringify(entitlement));
  }

  /* Remove user entitlement from storage */
  public removeEntitlement(): void {
    this.storageHelper.remove(this.ENTITLEMENT);
  }

  /* Get anonymous segment ID from storage */
  public getAnonymousIdSegment(): string {
    return localStorage.getItem(this.ANONYMOUS_ID_SEGMENT).replace(/["]/g, '');
  }

  /* Get TV authorization token from storage */
  public getTVAuth(): any {
    let auth;
    const storedTVAuth = this.storageHelper.get(this.TV_AUTH);
    if (storedTVAuth) {
      auth = JSON.parse(storedTVAuth);
    }

    return auth;
  }

  /* Set TV authorization token in storage */
  public setTVAuth(value: any): void {
    this.storageHelper.set(this.TV_AUTH, JSON.stringify(value));
  }

  /* Get TV authorization code from storage */
  public getTVAuthCode(): string {
    return this.storageHelper.get(this.TV_AUTH_CODE);
  }

  /* Set TV authorization code in storage */
  public setTVAuthCode(value: string): void {
    this.storageHelper.set(this.TV_AUTH_CODE, value);
  }

  /* Get TV authorization state from storage */
  public getTVAuthState(): boolean {
    let state = false;
    const storedTVAuthState = this.storageHelper.get(this.TV_AUTH_STATE);
    if (storedTVAuthState) {
      state = storedTVAuthState === 'true'? true:false;
    }
    return state;
  }

  /* Set TV authorization state in storage */
  public setTVAuthState(value: boolean): void {
    this.storageHelper.set(this.TV_AUTH_STATE, String(value));
  }

  /* Get device UUID from storage */
  public getDeviceUUID(): string {
    return this.storageHelper.get(this.DEVICE_UUID);
  }

  /* Set device UUID from storage */
  public setDeviceUUID(value: string): void {
    this.storageHelper.set(this.DEVICE_UUID, value);
  }

  /* Get auth type from storage */
  public getAuthType(): string {
    return this.storageHelper.get(this.AUTH_TYPE);
  }

  /* Set auth type in storage */
  public setAuthType(value: string): void {
    this.storageHelper.set(this.AUTH_TYPE, value);
  }

  /* Get texts from storage */
  public getTexts(): any {
    let texts: any;
    const storedTexts = this.storageHelper.get(this.TEXTS);
    if (storedTexts) {
      texts = JSON.parse(storedTexts);
    }

    return texts;
  }

  /* Set texts in storage */
  public setTexts(value: any): any {
    const texts = {
      data: {},
      createdAt: moment()
    };
    if (value) {
      texts.data = value;
    } else {
      texts.data = '';
    }

    this.storageHelper.set(this.TEXTS, JSON.stringify(texts));
  }


  /* Get payment texts from storage */
  public getPaymentText(): any {
    let texts: any;
    const storedTexts = this.storageHelper.get(this.PAYMENT_TEXT);
    if (storedTexts) {
      texts = JSON.parse(storedTexts);
    }

    return texts;
  }

  /* Set payment texts in storage */
  public setPaymentTexts(value: any): any {
    const texts = {
      data: {},
      createdAt: moment()
    };
    if (value) {
      texts.data = value;
    } else {
      texts.data = '';
    }

    this.storageHelper.set(this.PAYMENT_TEXT, JSON.stringify(texts));
  }

  /* Get Provider Text from storage */
  public getProviderText() {
    let texts: any;
    const storedTexts = this.storageHelper.get(this.LOGIN_PROVIDERS);
    if (storedTexts) {
      texts = JSON.parse(storedTexts);
    }

    return texts;
  }

  /* Set Login Providers in storage */
  public setloginProviders(value: any): any {
    const texts = {
      data: {},
      createdAt: moment()
    };
    if (value) {
      texts.data = value;
    } else {
      texts.data = '';
    }
    this.storageHelper.set(this.LOGIN_PROVIDERS, JSON.stringify(texts));
  }

  /* Set value turn suscription in storage */
  public setTurnSuscription(value: any): void {
    this.storageHelper.set(this.TURN_OFF_SUSCRIPTION, value);
  }

  /* get value turn suscription in storage */
  public getTurnSuscription() {
    return this.storageHelper.get(this.TURN_OFF_SUSCRIPTION);
  }

  /* Set value turn PVV in storage */
  public setTurnPVV(value: any): void {
    this.storageHelper.set(this.TURN_OFF_PVV, value);
  }

  /* get value trun PVV in storage */
  public getTurnPVV() {
    return this.storageHelper.get(this.TURN_OFF_PVV);
  }

  /* set value obj payment */
  public setPaymentObj(value): void {
    this.storageHelper.set(this.OBJ_PAYMENT, JSON.stringify(value));
  }

  /* get value obj payment */
  public getPaymentObj() {
   return this.storageHelper.get(this.OBJ_PAYMENT);
  }

  /* remove storage suscription and pvv */
  public RemovePvv() {
    this.storageHelper.remove(this.TURN_OFF_PVV);
  }

  public RemoveSuscription() {
    this.storageHelper.remove(this.TURN_OFF_SUSCRIPTION);
  }

  public RemoveObjPayment() {
    this.storageHelper.remove(this.OBJ_PAYMENT);
  }


  /* Get previous route from storage */
  public getPreviousRoute(): string {
    return this.storageHelper.get(this.PREVIOUS_ROUTE);
  }

  /* Set previous route in storage */
  public setPreviousRoute(value: string): void {
    this.storageHelper.set(this.PREVIOUS_ROUTE, value);
  }

  /* Remove previous route from storage */
  public removePreviousRoute(): void {
    this.storageHelper.remove(this.PREVIOUS_ROUTE);
  }

  /* Get devices limit from storage */
  public getDevicesLimit(): string {
    return this.storageHelper.get(this.DEVICES_LIMIT);
  }

  /* Set devices limit in storage */
  public setDevicesLimit(value: string): void {
    this.storageHelper.set(this.DEVICES_LIMIT, value);
  }

  /* Remove devices limit from storage */
  public removeDevicesLimit(): void {
    this.storageHelper.remove(this.DEVICES_LIMIT);
  }

  /* Get teams from storage */
  public getTeams(): number[] {
    let values = [];
    const storedValues = this.storageHelper.get(this.TEAMS);

    if (storedValues) {
      values = storedValues.split(',').map(Number);
    }

    return values;
  }

  /* Set teams in storage */
  public setTeams(values: number[]): void {
    if (values.length > 0) {
      this.storageHelper.set(this.TEAMS, values.toString());
    } else {
      this.storageHelper.set(this.TEAMS, '0');
    }
  }

  /* Remove teams from storage */
  public removeTeams(): void {
    this.storageHelper.remove(this.TEAMS);
  }

  /* Set quality in storage */
  public setVideoQuality(value: string): void {
    this.storageHelper.set(this.VIDEO_QUALITY_V2, value);
  }

  public getVideoQuality(): string {
    return this.storageHelper.get(this.VIDEO_QUALITY_V2);
  }

  /* Set statistics configuration */
  public setStatisticsConfig(value: any): void {
    const config ={
      data: {},
      createdAt: moment()
    };
    if (value) {
      config.data = value;
    } else {
      config.data = '';
    }
    this.storageHelper.set(this.STATISTICS_CONFIG,JSON.stringify(config));
  }

  public getStatisticsConfig(): string {
    return this.storageHelper.get(this.STATISTICS_CONFIG);
  }

  // set if the player is active
  public setVideoActive(value:string):void{
    this.storageHelper.set(this.VIDEO_ACTIVE,value)
  }

  public getVideoActive():string{
    return this.storageHelper.get(this.VIDEO_ACTIVE);
  }

  // set if the player is active
  public setCard(value:any):void{
    const cardString = JSON.stringify(value);
    this.storageHelper.set(this.CARD_SAVED,cardString)
  }

  public getCard():any{
    const cardOjb = JSON.parse(this.storageHelper.get(this.CARD_SAVED));
    return cardOjb;
  }

  // set if the player is active
  public setIsWebView(value:string):void{
    this.storageHelper.set(this.IS_WEB_VIEW,value);
  }

  public getIsWebView(): any{
    return this.storageHelper.get(this.IS_WEB_VIEW);
  }

  public setTokenPersonal(value: string): void{
    this.storageHelper.set(this.TOKEN_PERSONAL,value);
  }

  public getTokenPersonal(): any{
    return this.storageHelper.get(this.TOKEN_PERSONAL);
  }

  public removeTokenPersonal(): any{
    this.storageHelper.remove(this.TOKEN_PERSONAL);
  };

  public setOpenTigoID(value: string): void{
    this.storageHelper.set(this.OPEN_TIGO_ID,value);
  }

  public getOpenTigoID(): any{
    return this.storageHelper.get(this.OPEN_TIGO_ID);
  }

  public removeOpenTigoID(): any{
    this.storageHelper.remove(this.OPEN_TIGO_ID);
  }

  public setMiddleAuth(value: string): void{
    this.storageHelper.set(this.MIDDLE_AUTH,value);
  }

  public getMiddleAuth(): any{
    return this.storageHelper.get(this.MIDDLE_AUTH);
  }

  public removeMiddleAuth(): any{
    this.storageHelper.remove(this.MIDDLE_AUTH);
  }

  public setmakePayment(value: string): void{
    this.storageHelper.set(this.VALID_PAYMENT,value);
  }

  public getmakePayment(): any{
    return this.storageHelper.get(this.VALID_PAYMENT);
  }

  public removeMakePayment() :any{
    this.storageHelper.remove(this.VALID_PAYMENT);
  }

  public setPlayerActive(value:string):void{
    this.storageHelper.set(this.PLAYER_ACTIVE,value)
  }

  public getplayerActive():string{
    return this.storageHelper.get(this.PLAYER_ACTIVE);
  }

  public setNetworkStatus(value: string): void {
    this.storageHelper.set(this.NETWORK_STATUS, value);
  }

  public getNetworkStatus(): any {
    return this.storageHelper.get(this.NETWORK_STATUS);
  }

  public removeNetworkStatus(): any {
    this.storageHelper.remove(this.NETWORK_STATUS);
  }

  public setTokenTigoId(value: string): void{
    this.storageHelper.set(this.TOKEN_TIGO, value);
  }

  public getTokenTigoId():any{
    return this.storageHelper.get(this.TOKEN_TIGO);
  }

  public removeTokenTigoId(): any {
    this.storageHelper.remove(this.TOKEN_TIGO);
  }

  public setRefreshTokenTigoId(value: string): void{
    this.storageHelper.set(this.TOKEN_REFRESH_TIGO, value);
  }

  public getRefreshTokenTigoId():any{
    return this.storageHelper.get(this.TOKEN_REFRESH_TIGO);
  }

  public removeRefreshTokenTigoId(): any {
    this.storageHelper.remove(this.TOKEN_REFRESH_TIGO);
  }

  public setAccessTigoId(value: string): void{
    this.storageHelper.set(this.ACCESS_ID, value);
  }

  public getAccessTigoId():any{
    return this.storageHelper.get(this.ACCESS_ID);
  }

  public removeAccessTigoId(): any {
    this.storageHelper.remove(this.ACCESS_ID);
  }

  public setVersionTv(value: string): void{
    this.storageHelper.set(this.VERSION_TV, value);
  }

  public getVersionTv():any{
    return this.storageHelper.get(this.VERSION_TV);
  }

  public removeVersionTv(): any {
    this.storageHelper.remove(this.VERSION_TV);
  }

  public setPersonalFlow(value: string): void{
    this.storageHelper.set(this.PERSONAL_FLOW, value);
  }

  public getPersonalFlow():any{
    return this.storageHelper.get(this.PERSONAL_FLOW);
  }

  public removePersonalFlow(): any {
    this.storageHelper.remove(this.PERSONAL_FLOW);
  }

  public setRouteDetail(value: string): void{
    this.storageHelper.set(this.ROUTE_DETAIL, value);
  }

  public getRouteDetail():any{
    return this.storageHelper.get(this.ROUTE_DETAIL);
  }

  public removeRouteDetail(): any {
    this.storageHelper.remove(this.ROUTE_DETAIL);
  }

  public setMaintenanceInfo(value: any): void{
    const options = {
      data: {},
      createdAt: moment()
    };
    if (value) {
      options.data = value;
    } else {
      options.data = '';
    }

    this.storageHelper.set(this.MAINTENANCE_OPTS, JSON.stringify(options));
  }

  public getMaintenanceInfo():any{
    let options: any;
    const storedTexts = this.storageHelper.get(this.MAINTENANCE_OPTS);
    if (storedTexts) {
      options = JSON.parse(storedTexts);
    }

    return options;
  }

  /* Set texts_v2 from storage */
  public setTextsV2(value: string): void{
    const options = {
      data: {},
      createdAt: moment()
    };
    if (value) {
      options.data = value;
    } else {
      options.data = '';
    }

    this.storageHelper.set(this.TEXTS_V2, JSON.stringify(options));
  }

  /* Get texts_v2 from storage */
  public getTextsV2():any{
    let options: any;
    const storedTexts = this.storageHelper.get(this.TEXTS_V2);
    if (storedTexts) {
      options = JSON.parse(storedTexts);
    }

    return options;
  }

  public removeMaintenanceInfo(): any {
    this.storageHelper.remove(this.MAINTENANCE_OPTS);
  }

  public setPlatformInfo(value: any ): void{
    this.storageHelper.set(this.PLATFORM_INFO, JSON.stringify(value));
  }

  public getPlatformInfo():any{
    return JSON.parse(this.storageHelper.get(this.PLATFORM_INFO));
  }

  public removePlatformInfo(): any {
    this.storageHelper.remove(this.PLATFORM_INFO);
  }

  public setLoginType(value: any ): void{
    this.storageHelper.set(this.LOGIN_TYPE, value);
  }

  public getLoginType():any{
    return this.storageHelper.get(this.LOGIN_TYPE);
  }

  public removeLoginType(): any {
    this.storageHelper.remove(this.LOGIN_TYPE);
  }

  public setOptionLogin(value: AuthOption): void {
    this.storageHelper.set(this.LOGIN_TYPE_OPTION, JSON.stringify(value));
  }

  public getOptionLogin(): AuthOption {
    return JSON.parse(this.storageHelper.get(this.LOGIN_TYPE_OPTION));
  }

  public setImgCacheLoadign(value: string): any {
    this.storageHelper.set(this.LOGIN_IMG_CACHE_LOADIGN, value);
  }

  public getImgCacheLoadign(): any {
    return this.storageHelper.get(this.LOGIN_IMG_CACHE_LOADIGN);
  }

  public setImgCacheLogo(value: string): any {
    this.storageHelper.set(this.LOGIN_IMG_CACHE_LOGO, value);
  }

  public getImgCacheLogo(): any {
    return this.storageHelper.get(this.LOGIN_IMG_CACHE_LOGO);
  }

  public setImgCacheAlert(value: string): any {
    this.storageHelper.set(this.LOGIN_IMG_CACHE_ALERT, value);
  }

  public getImgCacheAlert(): any {
    return this.storageHelper.get(this.LOGIN_IMG_CACHE_ALERT);
  }

  public setFlagIsConcet(value: boolean) {
    this.storageHelper.set(this.FLAG_IS_CONECT, value.toString());
  }

  public getFlagIsConcet(): boolean {
    return Boolean(this.storageHelper.get(this.FLAG_IS_CONECT));
  }

  public setImageContent(value: string): any {
    this.storageHelper.set(this.IMG_CURRENT, value);
  }

  public getImageContent(): string {
    return this.storageHelper.get(this.IMG_CURRENT);
  }

  public setLoadpage(value: string) {
    this.storageHelper.set(this.LOAD_PAGE_USERCONCURRENT, value);
  }

  public getLoadPage() {
    return this.storageHelper.get(this.LOAD_PAGE_USERCONCURRENT);
  }

  public removeLoadPage() {
    this.storageHelper.remove(this.LOAD_PAGE_USERCONCURRENT);
  }

  public setAccountMasterAppSync(value: any): void {
    this.storageHelper.set(this.CACHE_MASTER_ACCOUNT, JSON.stringify(value));
  }

  public getAccountMasterAppSync(): any {
    return JSON.parse(this.storageHelper.get(this.CACHE_MASTER_ACCOUNT));
  }

  public removeAccountMasterAppSync(): void {
    this.storageHelper.remove(this.CACHE_MASTER_ACCOUNT);
  }

  public setIsChromecast(value: string): void {
    this.storageHelper.set(this.IS_CHMDON, value);
  }

  public getIsChromecast(): string {
    return this.storageHelper.get(this.IS_CHMDON);
  }

  public removeIsChromecast(): void {
    this.storageHelper.remove(this.IS_CHMDON);
  }

  public setPayloadPaypal(value: string) {
    this.storageHelper.set(this.PAYMENT_PAYPAL, value);
  }

  public getPayloadPaypal() {
   return  this.storageHelper.get(this.PAYMENT_PAYPAL);
  }

  public removePayloadPaypal() {
    this.storageHelper.remove(this.PAYMENT_PAYPAL);
  }


  public setDeviceNative(value: string) {
    this.storageHelper.set(this.DEVICE_NT, value);
  }

  public getDeviceNative() {
    return  this.storageHelper.get(this.DEVICE_NT);
  }

  public removeDeviceNative() {
    this.storageHelper.remove(this.DEVICE_NT);
  }

  public setIsModalAuth0(value:string):void{
    this.storageHelper.set(this.TS_AUTH0,value);
  }

  public getIsModalAuth0(): any{
    return this.storageHelper.get(this.TS_AUTH0);
  }

  public removeModalAuth0() {
    this.storageHelper.remove(this.TS_AUTH0);
  }

  public setIsTigoIdWC(token: string) {
    localStorage.setItem(this.TS_IDTOKEN, token);
  }

  public getIsTigoIdWC() {
   return  localStorage.getItem(this.TS_IDTOKEN);
  }

  public removeTigoIdWC() {
    localStorage.removeItem(this.TS_IDTOKEN);
  }

  public setIsRefreshTokenTigoIdWC(refreshtoken: string) {
    localStorage.setItem(this.TS_REFRESHTOKEN, refreshtoken);
  }

  public getefreshTokenTigoIdWC() {
    return localStorage.getItem(this.TS_REFRESHTOKEN);
  }

  public removeefreshTokenTigoIdWC() {
    localStorage.removeItem(this.TS_REFRESHTOKEN);
  }

  public getPlatform() {
    return localStorage.getItem(this.TS_PLATFORM);
  }

  public setPlatform(item) {
    localStorage.setItem(this.TS_PLATFORM, item);
  }

  public setVecimaSession(id: string) {
    this.storageHelper.set(this.VS_DI, id);
  }

  public getVecimaSession() {
    return this.storageHelper.get(this.VS_DI);
  }

  public removeVecimaSession() {
    this.storageHelper.remove(this.VS_DI);
  }

  public setChromecastBody(value) {
    this.storageHelper.set(this.BD_CH, JSON.stringify(value))
  }

  public getChromecastBody() {
    return JSON.parse(this.storageHelper.get(this.BD_CH));
  }

  public removeChromecastBody() {
    this.storageHelper.remove(this.BD_CH);
  }

  public setStatusInitChromecast(value) {
    this.storageHelper.set(this.CHD_INITST, value)
  }

  public getStatusInitChromecast() {
    return this.storageHelper.get(this.CHD_INITST);
  }

  public removeStatusInitChromecast() {
    this.storageHelper.remove(this.CHD_INITST);
  }

  public setIsPIP(value) {
    this.storageHelper.set(this.IS_PIP, JSON.stringify(value))
  }

  public getIsPIP() {
    return JSON.parse(this.storageHelper.get(this.IS_PIP));
  }

  public removeIsPIP() {
    this.storageHelper.remove(this.IS_PIP);
  }

  public setOtherContentPlus(value: string) {
    this.storageHelper.set(this.OC_PLUS, value);
  }

  public getOtherContentPlus() {
    return this.storageHelper.get(this.OC_PLUS);
  }

  public removeOtherContentPlus() {
    this.storageHelper.remove(this.OC_PLUS);
  }

  public setActivateKeyboardTV(value: string) {
    this.storageHelper.set(this.KB_TV, value);
  }

  public getActivateKeyboardTV() {
    return this.storageHelper.get(this.KB_TV);
  }

  public removeActivateKeyboardTV() {
    this.storageHelper.remove(this.KB_TV);
  }

}


