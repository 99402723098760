import {Injectable} from '@angular/core';
import {Capacitor} from '@capacitor/core';
import {NavController} from '@ionic/angular';
import {Network} from '@capacitor/network';
import {SegmentAnalytics} from '../providers/segment-analytics';
import {Storage} from '../storage/storage';
import {EVENT_TRACK_112} from '../../config/analytics/events-track';
import {LOCATION} from '../../config/endpoints';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {Router} from '@angular/router';
import {LoadingService} from '../../services/loading.service';
import {delay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NetworkUtil {
  eventtrack112 = EVENT_TRACK_112;
  constructor(
    private navController: NavController,
    private segmentAnalytics: SegmentAnalytics,
    private storage: Storage,
    private httpClient: HttpClient,
    private route: Router,
    private loadingService: LoadingService
  ) {}

  checkNetwork = async (connected) => {
    if (this.loadingService.isProcessNetwork.value === false) {
      this.loadingService.isProcessNetwork.next(true);
      if (!connected) {
        this.loadingService.isProcessNetwork.next(false);
        if (this.route.url !== '/-/red') {
          this.navController.navigateRoot('/-/red').then();
        }
      } else {
        let response: any;
        try {
          response = await this.httpClient.get(LOCATION, {observe: 'response'}).pipe(delay(1000)).toPromise() as HttpResponse<any>;
        } catch(e) { response = e as HttpErrorResponse; }
        if (response.status !== 0) {
          this.navController.navigateRoot('/').then();
          if(this.storage.getNetworkStatus()){
            this.eventTrack(this.eventtrack112).then();
            this.storage.removeNetworkStatus();
          }
        }
        this.loadingService.isProcessNetwork.next(false);
      }
    }
  };

  async eventTrack(event: string) {
    await this.segmentAnalytics.eventTrack(event);
  }

  /* Verify network status only in mobile devices */
  public async verifyCurrentNetworkStatus(): Promise<void> {
    if (Capacitor.getPlatform() !== 'web') {
      const status = await Network.getStatus();
      if (!status.connected) {
        if (this.route.url !== '/-/red') {
          this.navController.navigateRoot('/-/red').then();
        }
        this.storage.setNetworkStatus('disconnected');
      }
      this.networkStatusChange();
    }
  }

  /* Listener for network status change */
  private networkStatusChange(): void {
    Network.addListener('networkStatusChange', (status) => {
      if (this.route.url === '/-/red') {
        this.checkNetwork(status.connected);
      }
    });
  }
}
