import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GET_EVENT_DETAIL } from '../config/endpoints';
import { ENTITLEMENT } from '../config/settings';
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';
//import * as moment from "moment";
import moment from 'moment';
import { AuthService } from './auth.service';
import {IS_ADAPTABLE} from "../config/constants";
import {Platform} from "@ionic/angular";
import { Storage } from '../core/storage/storage';
import {SegmentAnalytics} from '../core/providers/segment-analytics';
import {EVENT_TRACK_044} from '../config/analytics/events-track';

@Injectable({
  providedIn: 'root'
})
export class EventDetailService {

  isLive = false;
  isReplay = false;
  isProgrammed = false;
  noSchedule = false;
  isNative = Capacitor.isNativePlatform();
  urlContent: any;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private platform: Platform,
    private storage: Storage,
    private segmentAnalytics: SegmentAnalytics,
  ) { }

  /* Get event detail from middleware */
  public async getEventDetailByShare(contentful_id : string, eventType: string): Promise<any> {
    let data : any = {};
    try {
      const dataResponse : any = await this.httpClient.get(`${GET_EVENT_DETAIL}/${contentful_id}/${eventType}`, {}).toPromise();
      if (dataResponse){
        data = dataResponse;
      }
    } catch (error) {}
    return data;
  }

  /* Get event detail from middleware */
  public async getEventDetail(contentful_id : string, eventType: string, assetDomain: any, multiEntitlementConfig?:any): Promise<any> {
    let detail : any = {};
    let streamingUrl = '';
    let streamingUrlDash = '';
    let streamingUrlHls = '';
    let replayUrl = '';
    let replayUrlDash = '';
    let replayUrlHls = '';
    let streamingType = '';
    let channelNumber = 1;
    let securityLevel = 1;
    let dhcpValue = '';
    let replayEndpoints : any = {};
    const actualPlatform = Capacitor.getPlatform();
    const deviceInfo = await Device.getInfo();
    const os_name = deviceInfo.operatingSystem;
    let deviceType = '';

    let platform = 'androidTv';

    if(IS_ADAPTABLE){
      platform = actualPlatform;
      if(platform === 'android'){
        platform = 'androidMovil'
      }
    }

    if (os_name === "mac" || os_name === "ios") {
      deviceType = "ios";
    } else {
      deviceType = os_name;
    }

      try {
      const headers = new HttpHeaders({deviceType, isNative: this.isNative.toString(),platform:platform});
      const dataResponse : any = await this.httpClient.get(`${GET_EVENT_DETAIL}/${contentful_id}/other/${eventType}`, {headers}).toPromise();
      if(dataResponse.data.refreshToken){
        this.authService.refreshAuthentication();
      }
      if (dataResponse){
        if (dataResponse.event.startDate){
          this.defineTypeVideo(dataResponse.event.startDate, dataResponse.event.endDate,dataResponse?.data?.previous);
          const realSecurityLevel = await this.defineSecurityLevel(dataResponse.data);
          const realDhcpValue = await this.defineDhcpValue(dataResponse.data);
          replayUrl = dataResponse.data.replayUrl ? dataResponse.data.replayUrl : '';
          replayUrlDash = dataResponse.data.replayUrlDash ? dataResponse.data.replayUrlDash : '';
          replayUrlHls = dataResponse.data.replayUrlHls ? dataResponse.data.replayUrlHls : '';
          streamingUrl = dataResponse.data.streamingUrl ? dataResponse.data.streamingUrl : '';
          streamingUrlDash = dataResponse?.event.channel_data?.json ? dataResponse.event.channel_data.json.fields.livestreamingUrl.streamingUrlDash : '';
          streamingUrlHls = dataResponse?.event.channel_data?.json ? dataResponse.event.channel_data.json.fields.livestreamingUrl.streamingUrlHls : '';
          channelNumber = dataResponse?.event.channel_data?.json ? dataResponse.event.channel_data.json.fields.livestreamingUrl.channelNumber : 1;
          securityLevel = realSecurityLevel ? realSecurityLevel : 1;
          dhcpValue = realDhcpValue ? realDhcpValue : '';
          streamingType = dataResponse.data.streamingType ? dataResponse.data.streamingType : '';
          replayEndpoints = dataResponse.data.replayEndpoints ?  dataResponse.data.replayEndpoints  : '';
        } else if (eventType === "highlights") {
          this.isLive = false;
          this.isProgrammed = false;
          this.isReplay = true;
          this.noSchedule = false;
          replayUrl = dataResponse.data.videoUrl;
          streamingUrl = dataResponse.data.videoUrl;
          streamingType = ENTITLEMENT.AUTHENTICATED_FREE_TYPE;
        } else {
          this.isLive = false;
          this.isProgrammed = false;
          this.isReplay = true;
          this.noSchedule = false;
          replayUrl = dataResponse.data.replayUrl ? dataResponse.data.replayUrl : '';
          streamingUrl = dataResponse.data.streamingUrl ? dataResponse.data.streamingUrl : '';
          streamingType = dataResponse.data.streamingType ? dataResponse.data.streamingType : '';
        }
        let multiEntitlement = this.getMultiEntitlement(dataResponse.data, multiEntitlementConfig);
        let data = {
          contentful_id : contentful_id,
          title : dataResponse.data.name,
          allowedRegion : dataResponse.data.allowedRegion,
          schedule : dataResponse.event.startDate ?
                      this.getFormattedSchedule(new Date(dataResponse.event.startDate), 1) :
                      this.getFormattedSchedule(new Date(dataResponse.event.created_at), 2),

          streamingType,
          description : dataResponse.data.description.content[0].content[0].value,
          imgCover : this.getImageCover(dataResponse, assetDomain, eventType),
          region : dataResponse.event.region,
          isDrm : dataResponse.data.isDrm ? dataResponse.data.isDrm : false,
          securityLevel,
          dhcpValue,
          isGame : dataResponse.data.isGame ? dataResponse.data.isGame : false,
          startDate : dataResponse.event.startDate,
          endDate : dataResponse.event.endDate,
          isReplay: this.isReplay,
          isLive : this.isLive,
          isProgrammed: this.isProgrammed,
          noSchedule : this.noSchedule,
          replayUrl,
          replayUrlDash,
          replayUrlHls,
          streamingUrl,
          streamingUrlDash,
          streamingUrlHls,
          channelNumber,
          replayEndpoints,
          verticalImgV2 : this.getImageVertical(dataResponse, assetDomain, eventType, false),
          verticalImg : this.getImageVertical(dataResponse, assetDomain, eventType),
          championship_id : dataResponse.event.league_data ? dataResponse.event.league_data.contentful_id : '',
          championship_name : dataResponse.event.league_data ? dataResponse.event.league_data.json.fields.name : '',
          airdate : dataResponse.event.created_at,
          sport_name : dataResponse.event.sport,
          video_title : dataResponse.data.name,
          journey : 'player',
          content_id : contentful_id,
          home_team_id : dataResponse.event.home_team_data ? dataResponse.event.home_team_data.contentful_id : '',
          home_team : dataResponse.event.home_team_data ? dataResponse.event.home_team_data.json.fields.name : '',
          away_team_id : dataResponse.event.away_team_data ? dataResponse.event.away_team_data.contentful_id : '' ,
          away_team : dataResponse.event.away_team_data ? dataResponse.event.away_team_data.json.fields.name : '' ,
          total_length : dataResponse.data ? (dataResponse.data.totalLength ? dataResponse.data.totalLength : (dataResponse.data.videoTime ? dataResponse.data.videoTime : 0)) : 0,
          video_position : 0,
          session_id : '',
          video_quality : '',
          content_type : eventType === 'highlights' ? 'highlights' : this.defineContentTypeSegment(dataResponse.event.endDate),
          eventType: eventType,
          token: dataResponse.data.token ? dataResponse.data.token : '',
          deviceControl: dataResponse.deviceControl,
          multi_entitlement: multiEntitlement,
          is_multi_entitlement_content: multiEntitlement !== ''
        };
        detail = data;
      }
    } catch (error) {
        const objError = {
          journey: 'Video Failed - LoadEvent',
          error_id: error?.status ? error?.status : '',
          error_message: error?.message ? error?.message : '',
          error_type: error?.error?.errors[0]?.message ? error?.error?.errors[0]?.message  : ''
        };
        this.segmentAnalytics.eventTrack(EVENT_TRACK_044, objError).then();
      //console.log(error);
    }
    return detail;
  }

  public async getStreamType(contentful_id : string, eventType: string): Promise<string> {
    let streamingType = '';
    const deviceInfo = await Device.getInfo();
    const os_name = deviceInfo.operatingSystem;
    let deviceType = '';

    if (os_name === "mac" || os_name === "ios") {
      deviceType = "ios";
    } else {
      deviceType = os_name;
    }
    try {
    const headers = new HttpHeaders({deviceType, isNative: this.isNative.toString()});
      const dataResponse : any = await this.httpClient.get(`${GET_EVENT_DETAIL}/${contentful_id}/other/${eventType}`, {headers}).toPromise();

      if (dataResponse){
        if (dataResponse.event.startDate){
          this.defineTypeVideo(dataResponse.event.startDate, dataResponse.event.endDate,dataResponse?.data?.previous);
          streamingType = dataResponse.data.streamingType ? dataResponse.data.streamingType : '';
        } else if (dataResponse.data.videoType.toLowerCase() === "highlights") {
          streamingType = ENTITLEMENT.AUTHENTICATED_FREE_TYPE;
        } else {
          streamingType = dataResponse.data.streamingType ? dataResponse.data.streamingType : '';
        }
      }
    } catch (error) {}

    return streamingType;
  }

  /* Get formated schedule */
  private getFormattedSchedule(startDate: Date, format: number) {
    let date = startDate.getDate();
    let month = startDate.getMonth();
    let year = startDate.getFullYear();
    let startHour = moment(startDate).format("HH:mm");
    let formattedDate = '';
    if (format === 1) {
      formattedDate = `${date}/${month + 1}/${year} ${startHour}`;
    } else {
      formattedDate = `${date}/${month + 1}/${year}`;
    }
    return formattedDate;
  }


  private defineSecurityLevel(data: any){
    if(!IS_ADAPTABLE){
      return data.widevineSecurityLevelAndroidTv ? data.widevineSecurityLevelAndroidTv : 1;
    }else if(this.platform.is('android') && !this.platform.is('mobileweb')){
      return data.widevineSecurityLevel ? data.widevineSecurityLevel : 1;
    }else{
      return data.widevineSecurityLevelWeb ? data.widevineSecurityLevelWeb : 1;
    }
  }

  private defineDhcpValue(data: any){
    if(!IS_ADAPTABLE){
      return data.dhcpValue ? data.dhcpValue.androidTv : '';
    }else if(this.platform.is('android') && !this.platform.is('mobileweb')){
      return data.dhcpValue ? data.dhcpValue.androidMovil : '';
    }else{
      return data.dhcpValue ? data.dhcpValue.web : '';
    }
  }

  /* Define video type */
  private defineTypeVideo(startDate: Date, endDate: Date,prev) {
    let now = moment(new Date()).utc().format();
    let start = moment(startDate).utc().format();
    if(Number(prev) > 0){
      let currrent_time = new Date(startDate);
      currrent_time.setMinutes(currrent_time.getMinutes() - Number(prev));
      start = moment(currrent_time).utc().format();
    }
    let end = moment(endDate).utc().format();
    if (now >= start && now <= end) {
      this.isLive = true;
      this.isProgrammed = false;
      this.isReplay = false;
      this.noSchedule = false;
    } else if (now < start) {
      this.isLive = false;
      this.isProgrammed = true;
      this.isReplay = false;
      this.noSchedule = false;
    } else if (now > end) {
      this.isLive = false;
      this.isProgrammed = false;
      this.isReplay = true;
      this.noSchedule = false;
    } else {
      this.isLive = false;
      this.isProgrammed = false;
      this.isReplay = false;
      this.noSchedule = true;

    }
  }

  private defineContentTypeSegment(end_date: Date): string {
    let contentTypeFixed = '';
    const endDate = end_date;
    if (endDate) {
      const now = moment();
      const deadline = moment(endDate);

      const isAfter = moment(deadline).isAfter(now);
      if (isAfter) {
        contentTypeFixed = 'live';
      } else {
        contentTypeFixed = 'replay';
      }
    } else {
      contentTypeFixed = 'live';
    }
    return contentTypeFixed;
  }

  public setUrlImageContent(imageUrl) {
    this.urlContent = imageUrl;
  }

  public getUrlImageContent() {
    return this.urlContent;
  }

  /* Get image url from Amazon S3 or Contentful */
  private getImageCover(obj, assetDomain, eventType){
    let domain = assetDomain?.default
    if(eventType === 'highlights')
      domain = assetDomain?.highlights
    else if(eventType === 'game')
      domain = assetDomain?.gameSchedules

    if(assetDomain?.status && assetDomain.status === true && obj?.event?.coverS3){
      return domain + obj?.event?.coverS3;
    }else
      return `https:${obj.data.cover.file.fields.file.url}`
  }

  /* Get image url from Amazon S3 or Contentful */
  /**
    Elimina el string 'https:' de la url de la imagen que es usada para guardar en favoritos,
    para visualizar la imagen en la pagina de detalle, no altera la url
   */
  private getImageVertical(obj, assetDomain, eventType, domainEnabled = true){
    let domain = assetDomain?.default
    if(eventType === 'highlights')
      domain = assetDomain?.highlights
    else if(eventType === 'game')
      domain = assetDomain?.gameSchedules

    if(assetDomain?.status && assetDomain.status === true && obj?.event?.verticalThumbnailsS3){
      if (domainEnabled)
        return domain + obj?.event?.verticalThumbnailsS3;
        domain = domain.replace('https:', '')
      return domain + obj?.event?.verticalThumbnailsS3;
    }else{
      if (domainEnabled)
        return `https:${obj.data.vertical_thumbnails.file.fields.file.url}`
      return obj.data.vertical_thumbnails.file.fields.file.url
    }
  }

  /* Get multi entitlement */
  private getMultiEntitlement(contentful_data, multiEntitlementConfig){
    //Si la configuración de multiEntitlement esta desativada, retornar String ''
    if(!multiEntitlementConfig?.status)
      return ''

    let key = ''
    if(contentful_data?.entitlementUrn && contentful_data?.entitlementUrn.length > 0)
      key = contentful_data.entitlementUrn[0]


    //buscar key en las categorias urn
    if(key !== '' && multiEntitlementConfig?.urns && multiEntitlementConfig?.urns[key])
      return multiEntitlementConfig?.urns[key]
    return ''
  }

}
