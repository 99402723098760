import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ConfigurationsService} from './configurations.service';
import { Storage } from '../core/storage/storage';
//import * as moment from 'moment';
import moment from 'moment';
import { NavController, Platform } from '@ionic/angular';
import { LocationService } from './location.service';
import {Capacitor} from '@capacitor/core';
import {IS_ADAPTABLE} from '../config/constants';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  isAdaptable = IS_ADAPTABLE;

  constructor(private configurationService: ConfigurationsService,private storage: Storage, private navController: NavController,
              private locationService: LocationService, private platform: Platform) { }

  public async getMaintenanceRedirect() {
   try{
       let storedOptions = this.storage.getMaintenanceInfo();
       if (storedOptions) {
         const day = moment().diff(storedOptions.createdAt, 'minutes',true);
         if (day >= 1) {
           await this.saveMaintenance();
         }
         if(this.storage.getMaintenanceInfo()?.data?.active){
          this.navController.navigateRoot('/-/Mantenimiento');
         }
       } else {
         await this.saveMaintenance();
         if(this.storage.getMaintenanceInfo()?.data?.active){
          this.navController.navigateRoot('/-/Mantenimiento');
         }
         return this.storage.getMaintenanceInfo();
       }
   }catch(e){
     console.log(e);
   }
  }

  private async saveMaintenance(){
    await this.locationService.locate();
    try{
    const maintenanceOptions =  await this.configurationService.getConfigurationByVariable('ott_maintenance_redirect');
    if(maintenanceOptions) {
      const arrayAllOptions: [] = maintenanceOptions?.options;
      const activeOptions=  [];
      let responseMaintenance = {};
      arrayAllOptions.forEach((res: any) => {
        if (res?.active) {
          activeOptions.push(res);
        }
      });
      if (!Capacitor.isNativePlatform()) {
        responseMaintenance = this.returnOptionsActivePlatform(activeOptions, 'web');
      } else if (this.platform.platforms().indexOf('android') >= 0 && this.isAdaptable) {
        responseMaintenance = this.returnOptionsActivePlatform(activeOptions, 'androidMobile');
      } else if (this.platform.platforms().indexOf('android') >= 0 && !this.isAdaptable) {
        responseMaintenance = this.returnOptionsActivePlatform(activeOptions, 'androidTV');
      } else if (this.platform.platforms().indexOf('ios') >= 0) {
        responseMaintenance = this.returnOptionsActivePlatform(activeOptions, 'ios');
      }
      if (responseMaintenance) {
        this.storage.setMaintenanceInfo(responseMaintenance);
      } else {
        this.storage.setMaintenanceInfo({active: false, title: maintenanceOptions?.titleofMaintenance,
          buttonBack: maintenanceOptions?.buttonBack});
      }
    }
    //this.storage.setMaintenanceInfo(maintenanceOptions);
   }catch(e){
    console.log(e);
   }
  }

  private returnOptionsActivePlatform(Options, type: string) {
    return Options.find((E: any) => E?.nameOptions?.toUpperCase() === type.toUpperCase());
  }

}
