import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { SearchService } from '../../../services/search.service';
import {Storage} from '../../../core/storage/storage';
import {NavController} from '@ionic/angular';
import { SearchResult } from 'src/app/core/interfaces/search-result';
import { IS_ADAPTABLE } from 'src/app/config/constants';
import { DevicesService } from 'src/app/services/devices.service';
import { AuthService } from 'src/app/services/auth.service';
import { TextManagementV2Service } from 'src/app/services/text-management-v2.service';

@Component({
  selector: 'app-finder-web-tv',
  templateUrl: './finder-web-tv.component.html',
  styleUrls: ['./finder-web-tv.component.scss'],
})
export class FinderWebTVComponent implements OnInit {

  txtSearch = '';
  data=[];
  finderFlag: Boolean;
  isAdaptable = IS_ADAPTABLE;
  @Input() set finderFlagParent(_finderFlag:Boolean) {
    this.finderFlag = _finderFlag;
  };
  @Output() cerrarFinder = new EventEmitter<boolean>();
  textsV2: any;
  assetDomain: any;
  constructor(
    private searchService: SearchService,
    private storage: Storage,
    private navController: NavController,
    private devicesService: DevicesService,
    private textManagementV2Service: TextManagementV2Service
  ) { }

  async ngOnInit() {
    this.textsV2 = await this.textManagementV2Service.getTexts();
    this.assetDomain = this.textsV2?.ASSET_DOMAIN;
  }

  public ngOnChange(event) {
    this.txtSearch = event.target.value;
    this.getItems();
  }

  /* Get items based on user input */
  async getItems() {
    this.textsV2 = await this.textManagementV2Service.getTexts();
    this.assetDomain = this.textsV2?.ASSET_DOMAIN;
    const results : SearchResult[] = await this.searchService.getResults(this.assetDomain, this.txtSearch);
    this.data = results;
  }

  /* If user is authenticated redirect to detail page, else redirect to login page */
  async viewDetail(content_id: String, content_type: String){
    let authenticated = this.storage.getAccessToken();
    let route = '';
    let eventType = '';
    if (content_type === "gameSchedules"){
      eventType = "game"
    } else {
      eventType = "highlights"
    }
    route = `/detail/${content_id}/${eventType}`;
    this.storage.setPreviousRoute(route);
    if (!authenticated){
      if (this.isAdaptable){
        route = '/-/iniciar-sesion';
      } else {
        route = '/-/iniciar-sesion-tv';
      }
    }
    this.cerrarFinder.emit(true);
    this.navController.navigateRoot(route);
  }

  /* Get input from custom keyboard */
  textFromCustomKeyboard(e){
    let inputSearch = document.getElementById("txtSearch");
    //inputSearch.textContent = e;
    inputSearch.innerText = e;
    this.txtSearch = e;
    this.getItems();
  }
}
