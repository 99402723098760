import {Component, HostListener, Input, OnInit} from '@angular/core';
import {MessageDialogService} from '../../../services/message-dialog.service';
import {ModalController} from '@ionic/angular';
import {Storage} from '../../../core/storage/storage';
import {DevicesService} from '../../../services/devices.service';
import {TextManagementService} from '../../../services/text-management.service';
import {VideoStreamingService} from '../../../services/video-streaming.service';
import {ChromecastService} from '../../../services/chromecast.service';
import {DeviceHelper} from '../../../core/helpers/device.helper';
import {EVENT_TRACK_142, EVENT_TRACK_143, EVENT_TRACK_144} from '../../../config/analytics/events-track';
import {SegmentAnalytics} from '../../../core/providers/segment-analytics';

@Component({
  selector: 'app-message-concurrentuser-nomaster',
  templateUrl: './message-concurrentuser-nomaster.component.html',
  styleUrls: ['./message-concurrentuser-nomaster.component.scss'],
})
export class MessageConcurrentuserNomasterComponent  implements OnInit {
  text: any = {};
  timeOut = 0;
  messageChromecast;
  @Input() data: any;
  constructor(
    private msgService: MessageDialogService,
    private modalCtrl: ModalController,
    private storage: Storage,
    private deviceservice: DevicesService,
    private textmanagementservice: TextManagementService,
    private videoStreamingService: VideoStreamingService,
    private chromecastservice: ChromecastService,
    private deviceHelper: DeviceHelper,
    private segmentAnalytics: SegmentAnalytics
  ) { }

  ngOnInit() {
    this.getText();
    this.timeOut = Date.now();
  }

  ngOnDestroy(): void {}

  async getText() {
    const infoDevice = this.storage.getAccountMasterAppSync();
    const text: any = await this.textmanagementservice.getTexts();
    this.text = text?.USERCONCURRENTNOMASTER;
    this.messageChromecast = text?.MASTERUSERCONCURRENT;
    this.text.numberDevice = infoDevice && infoDevice?.limitViewDevice ? infoDevice?.limitViewDevice : this.text.numberDevice;
  }

 async PlayNow() {
    const date = Math.floor((Date.now() - this.timeOut) / 1000) > Number(this.text?.timeWaitSession);
    if (date) {
      let objSegment = {};
      if (this.data?.objContent?.isChromecast) {
        objSegment = {
          version: '1',
          total_length: this.chromecastservice.getTransmitionChromecast()?.total_length,
          video_position: this.chromecastservice.getTransmitionChromecast()?.video_position,
          video_quality: 'UHD',
          video_title: this.chromecastservice.getTransmitionChromecast()?.video_title,
          video_percentage_start: 0,
          video_percentage_viewed: 0
        };
      } else {
        objSegment = {
          version: '1',
          total_length: this.data?.objContent?.total_length,
          video_position: this.data?.objContent?.video_position,
          video_quality: 'UHD',
          video_title:this.data?.objContent?.video_title,
          video_percentage_start: 0,
          video_percentage_viewed: 0
        };
      }
      this.trackSegment(EVENT_TRACK_142, objSegment);
      this.timeOut = Date.now();
      let isEnableDevice = true;
      try {
        const deviceId = await this.deviceHelper.getUUID().then();
        let contentTitle = this.data?.objContent?.title ? this.data?.objContent?.title : '';
        if (this.data?.objContent?.isChromecast) {
          if (contentTitle !== '') {
            contentTitle = contentTitle+this.messageChromecast?.messageChromecast;
          }
        }
        const resConnectionDevice: any = await this.deviceservice.setConnectionDevice('', contentTitle);
        if (!resConnectionDevice?.enableView && resConnectionDevice?.deviceId !== deviceId) {
          isEnableDevice = false;
        }
        if (isEnableDevice) {
          this.trackSegment(EVENT_TRACK_143, objSegment);
          this.close().then();
          if (this.data?.objContent?.isChromecast) {
           // this.chromecastservice.validateUrlStream(this.chromecastservice.getTransmitionChromecast()).then();
          } else {
            this.videoStreamingService.streamVideo(this.data?.objContent).then();
          }
        }
      } catch (e) {
        this.trackSegment(EVENT_TRACK_144, objSegment);
      }
    }
  }

  async close() {
   await this.modalCtrl.dismiss({cancel: true});
  }

  trackSegment(event, obj) {
    this.segmentAnalytics.eventTrack(event, obj).then();
  }

}
