import {Component, Input, OnChanges, OnInit, ViewChild, ElementRef, SimpleChanges} from '@angular/core';
import {NavController, Platform} from '@ionic/angular';
import {IS_ADAPTABLE} from 'src/app/config/constants';
import {Storage} from 'src/app/core/storage/storage';
import {SegmentAnalytics} from '../../../core/providers/segment-analytics';
import {EVENT_TRACK_034, EVENT_TRACK_035, EVENT_TRACK_072} from '../../../config/analytics/events-track';
import {BrowserService} from 'src/app/services/browser.service';
import { BannersService } from 'src/app/services/banners.service';
import { DevicesService } from 'src/app/services/devices.service';
import { AuthService } from 'src/app/services/auth.service';
//import * as moment from 'moment';
import moment from 'moment';

import SwiperCore, {
  Pagination,
  Autoplay
} from 'swiper';
import { SwiperComponent } from "swiper/angular";

SwiperCore.use([Pagination, Autoplay]);

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnChanges {
  @Input() data: any;
  @ViewChild('swiper', { static: false }) swiperRef?: SwiperComponent;

  isAdaptable = IS_ADAPTABLE;
  banners = [];
  showBanner = false;
  slideOpts = {
    initialSlide: 0,
    speed: 500,
    slidesPerView: 1,
    autoplay: {
      delay: 10000
    },
    loop: true
  };

  constructor(
    private navController: NavController,
    private storage: Storage,
    private segmentAnalytics: SegmentAnalytics,
    private browserService: BrowserService,
    private bannersService: BannersService,
    private devicesService: DevicesService
  ) {
  }

  ngAfterViewInit() {
    this.swiperRef?.swiperRef.update();
  }

  ionViewWillEnter() {
    /*
    if (this.slides) {
      await this.slides.update();
      await this.slides.startAutoplay();
      await this.slides.autoplay.start();
    }*/
  }

  ionViewWillLeave(){
    //await this.slides.stopAutoplay();
    //await this.slides.autoplay.stop();
    //this.swiperRef?.swiperRef.autoplay.stop();
  }

  handleSlidePrev() {
    //this.slides.slidePrev();
    this.swiperRef?.swiperRef.slidePrev();
  }

  handleSlideNext() {
    //this.slides.slideNext();
    this.swiperRef?.swiperRef.slideNext();
  }

  ngOnChanges(change: SimpleChanges) {
    const results = change.data;
    if (results && results.currentValue.length > 0) {
      this.banners = results.currentValue as [];
      setTimeout(()=> {this.swiperRef?.swiperRef.update();}, 500);
    }
  }

  /* Redirect the user to detail page */
  async viewDetail(event): Promise<void> {
    this.bannerEventTrack(event, EVENT_TRACK_035);

    let contentTypeFixed = '';
    switch (event.content_type) {
      case 'news':
        contentTypeFixed = 'news';
        break;
      case 'highlights':
        contentTypeFixed = 'highlights';
      case 'vod':
        contentTypeFixed = 'vod';
        break;
      case 'web':
        contentTypeFixed = 'tigopromotions';
        break;
      case 'games':
        const endDate = event.endDate;
        if (endDate) {
          const now = moment();
          const deadline = moment(endDate);

          const isAfter = moment(deadline).isAfter(now);
          if (isAfter) {
            contentTypeFixed = 'live';
          } else {
            contentTypeFixed = 'replay';
          }
        } else {
          contentTypeFixed = 'live';
        }

        break;
    }

    let segmentData = {
      championship_id: event.championship_id,
      championship_name: event.championship_id,
      airdate: event.createdAt,
      sport_name: event.sport_name,
      video_title: event.video_title,
      journey: 'player',
      content_id: event.contentful_id,
      home_team_id: event.home_team_id,
      home_team: event.home_team,
      away_team_id: event.away_team_id,
      away_team: event.away_team,
      total_length: event.total_length,
      video_position: 0,
      session_id: event.session_id,
      video_quality: event.video_quality,
      content_category: event.content_category,
      content_type: contentTypeFixed
    }
    await this.segmentAnalytics.eventTrack(EVENT_TRACK_072, segmentData);


    let eventType = event.content_type;
    const authenticated = this.storage.getAccessToken();
    let route: string;
    if (event.section.toLowerCase() === 'gameschedules') {
      eventType = 'game';
    } else if (event.section.toLowerCase() === 'web') {
      eventType = 'web';
    } else {
      eventType = 'highlights';
    }

    route = `/detail/${event.valueEntry}/${eventType}`;
    this.storage.setPreviousRoute(route);
    if (eventType === 'web') {
      this.browserService.openExternalLink(event.valueEntry);
    } else if (!authenticated) {
      if (this.isAdaptable) {
        route = '/-/iniciar-sesion';
      } else {
        route = '/-/iniciar-sesion-tv';
      }
    }
    if (eventType !== 'web') {
      this.navController.navigateRoot(route);
    }
  }

  /* Every time banners change send data to segment analytics */
  public slidePromotion(event) {
    if (this.swiperRef && this.swiperRef?.swiperRef && this.swiperRef?.swiperRef.activeIndex) {
      this.bannerEventTrack(this.banners[this.swiperRef?.swiperRef.activeIndex], EVENT_TRACK_034).then();
    }
  }

  /* Build segment data */
  private async bannerEventTrack(banner: any, action: string) {
    const type = banner?.content_type;

    if (type) {
      const bannerProperties = {
        championship_id: '',
        championship_name: '',
        publisher_country: banner.publisher_country,
        airdate: banner ? moment(banner.createdAt).toISOString(true) : '',
        journey: 'user interface',
        sport_name: banner.sport_name,
        content_type: '',
        promotion_id: banner.id,
        promotion_creative_id: banner.promotion_creative_id,
        promotion_name: banner.video_title,
        promotion_position: banner.position,
        video_title: banner.video_title,
        content_id: banner.content_id,
        news_date: banner.createdAt,
        news_title: banner.title,
      };
      switch (type) {
        case 'news':
          bannerProperties.content_type = 'news';
          break;
        case 'highlights':
          bannerProperties.content_type = 'vod';
          bannerProperties.championship_id = banner.championship_id_highlights;
          bannerProperties.championship_name = banner.championship_name_highlights;
          break;
        case 'web':
          bannerProperties.content_type = 'tigopromotions';
          break;
        case 'games':
          bannerProperties.championship_id = banner.championship_id_games;
          bannerProperties.championship_name = banner.championship_name_games;
          const endDate = banner.endDate;
          if (endDate) {
            const now = moment();
            const deadline = moment(endDate);

            const isAfter = moment(deadline).isAfter(now);
            if (isAfter) {
              bannerProperties.content_type = 'live';
            } else {
              bannerProperties.content_type = 'replay';
            }
          } else {
            bannerProperties.content_type = 'live';
          }

          break;
      }
      await this.segmentAnalytics.eventTrack(action, bannerProperties);
    }
  }

  protected readonly AuthService = AuthService;
}
