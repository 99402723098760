import {Component, Input, OnInit} from '@angular/core';
import {DevicesService} from '../../../services/devices.service';
import {LoadingService} from '../../../services/loading.service';
import {ModalController} from '@ionic/angular';
import {TextManagementService} from '../../../services/text-management.service';
import {Storage} from '../../../core/storage/storage';
import {VideoStreamingService} from '../../../services/video-streaming.service';
import {ChromecastService} from '../../../services/chromecast.service';
import {DeviceHelper} from '../../../core/helpers/device.helper';
import {EVENT_TRACK_142, EVENT_TRACK_143, EVENT_TRACK_144} from '../../../config/analytics/events-track';
import {SegmentAnalytics} from '../../../core/providers/segment-analytics';

@Component({
  selector: 'app-message-concurrent-usermaster-device',
  templateUrl: './message-concurrent-usermaster-device.component.html',
  styleUrls: ['./message-concurrent-usermaster-device.component.scss'],
})
export class MessageConcurrentUsermasterDeviceComponent implements OnInit {

  activeDevices: any = [];
  text;
  deviceColor = '';
  deviceId = '';
  @Input() data: any;
  messageChromecast;

  constructor(
    private deviceservice: DevicesService,
    private loadingService: LoadingService,
    private modalCtrl: ModalController,
    private textmanagementservice: TextManagementService,
    private deviceService: DevicesService,
    private storage: Storage,
    private videoStreamingService: VideoStreamingService,
    private chromecastservice: ChromecastService,
    private deviceHelper: DeviceHelper,
    private segmentAnalytics: SegmentAnalytics
  ) { }

  ngOnInit() {
    this.getDeviceActive();
    this.getText().then();
  }

  async getText() {
    const text: any = await this.textmanagementservice.getTexts();
    this.text = text?.PLAYDEVICECURRENTMASTER;
    this.messageChromecast = text?.MASTERUSERCONCURRENT;
  }


  getDeviceActive() {
    try {
      this.activeDevices = this.deviceservice.getDevicesActive();
      this.activeDevices = this.moveChromecastToEnd(this.activeDevices);
    } catch (e) {
      this.close();
      console.error(e);
    }
  }

  moveChromecastToEnd(devices) {
    devices.map((ad, index) => {
      if(ad?.contentView?.includes('(Chromecast)')){
          devices.splice(index, 1);
          devices.push(ad);
      }
    });
    return devices;
  }

  selectDevice(index, deviceId) {
    this.deviceId = deviceId;
    let device = 'device';
    device = device+index;
    const element: any = document.getElementById(device);
    if (element) {
      if (device !== this.deviceColor && this.deviceColor !== '') {
        const element2: any = document.getElementById(this.deviceColor);
        element2.style.border = 'none';
      }
      element.style.border = '2px solid var(--ion-color-warning)';
    }
    this.deviceColor = device;
  }

  async close() {
    await this.modalCtrl.dismiss({cancel: true});
  }

  play() {
    if (this.deviceId !== '') {
      let objSegmentMaster = {};
      if (this.data?.objContent?.isChromecast) {
        objSegmentMaster = {
          version: '1',
          total_length: this.chromecastservice.getTransmitionChromecast()?.total_length,
          video_position: this.chromecastservice.getTransmitionChromecast()?.video_position,
          video_quality: 'UHD',
          video_title: this.chromecastservice.getTransmitionChromecast()?.video_title,
          video_percentage_start: 0,
          video_percentage_viewed: 0
        };
      } else {
        objSegmentMaster = {
          version: '1',
          total_length: this.data?.objContent?.total_length,
          video_position: this.data?.objContent?.video_position,
          video_quality: 'UHD',
          video_title:this.data?.objContent?.video_title,
          video_percentage_start: 0,
          video_percentage_viewed: 0
        };
      }
      this.trackSegment(EVENT_TRACK_142, objSegmentMaster);
      let contentTitle = this.data?.objContent?.title ? this.data?.objContent?.title : '';
      try {
        if (this.data?.objContent?.isChromecast) {
          if (contentTitle !== '') {
            contentTitle = contentTitle+this.messageChromecast?.messageChromecast;
          }
        }
        this.deviceService.setConnectionDevice(this.deviceId, contentTitle).then(async (resConnectionDevice)  => {
          const deviceId = await this.deviceHelper.getUUID().then();
          const isEnableDevice = resConnectionDevice && resConnectionDevice?.enableView && resConnectionDevice?.deviceId === deviceId;
          if (isEnableDevice) {
            this.trackSegment(EVENT_TRACK_143, objSegmentMaster);
            this.close().then();
            // const data = this.storage.getDataStreamingPlay();
            if (this.data?.objContent.isChromecast) {
              //this.chromecastservice.validateUrlStream(this.chromecastservice.getTransmitionChromecast()).then();
            } else {
              this.videoStreamingService.streamVideo(this.data?.objContent).then();
            }
          }
        });
      } catch (e) {
        this.trackSegment(EVENT_TRACK_144, objSegmentMaster);
      }
    }
  }

  trackSegment(event, obj) {
    this.segmentAnalytics.eventTrack(event, obj).then();
  }

}
