import {Injectable} from '@angular/core';
import {ConfigurationsService} from './configurations.service';
import {Storage} from '../core/storage/storage';
//import * as moment from 'moment';
import moment from 'moment';
import { DEFAULT_TEXTS } from '../config/constants';
import { LocationService } from './location.service';
import {EventEmitter} from '@angular/core';
import {OnOfPayment} from '../core/interfaces/on-of-payment';

@Injectable({
  providedIn: 'root'
})
export class TextManagementService {
  textKey = 'ott_text';
  paymentContentful = 'ott_payment_text';
  providersContentful = 'ott_login_providers';
  banneradvertising = 'ott_advertising_banner';
  texts: any;
  csutoinfo$ = new EventEmitter<any>();

  constructor(
    private configurationsService: ConfigurationsService,
    private storage: Storage,
    private locationService: LocationService,
  ) {
  }

  public async getTexts(): Promise<any> {
   // await this.locationService.locate();
    let storedTexts = this.storage.getTexts();

    if (storedTexts) {
      const day = moment().diff(storedTexts.createdAt, 'day');

      if (day >= 1) {
        await this.saveTexts();
      }
    } else {
      await this.saveTexts();
    }
    storedTexts = this.storage.getTexts();

    const texts = this.getFormattedTexts(storedTexts);

    return texts;
  }


  public async getPaymentConfig(): Promise<any> {
  //  await this.locationService.locate();
    let storedTexts = this.storage.getPaymentText();

    if (storedTexts) {
      const day = moment().diff(storedTexts.createdAt, 'day');

      if (day >= 1) {
        await this.setPaymentConfig();
      }
    } else {
      await this.setPaymentConfig();
    }
    storedTexts = this.storage.getPaymentText();

    return storedTexts;
  }


  public async getProviders() {
  //  await this.locationService.locate();
    let providertext = this.storage.getProviderText();

    if (providertext) {
      const day = moment().diff(providertext.createdAt, 'day');

      if (day >= 1) {
        await this.setProviders();
      }
    } else {
      await this.setProviders();
    }
    providertext = this.storage.getProviderText();

    return providertext;

  }

  public async getturnoffsuscription() {
    let data: any;
    try {
      data = await this.getCurrentPayementConfig();
    } catch (e) {
      console.error();
    }
    return data;
  }

  public async setTurnoffsuscription(value: any) {
    this.storage.setTurnSuscription(value);
  }


  public async getTurnoffsuscription() {
    return this.storage.getTurnSuscription();
  }

  public async setTurnoffPvv(value: any) {
    this.storage.setTurnPVV(value);
  }

  public async getTurnoPayment() {
    return {
      pvv: this.storage.getTurnPVV(),
      suscription: this.storage.getTurnSuscription()
    };
  }

  public getTurnoPaymentState(): OnOfPayment {
    return {
      pvv: this.storage.getTurnPVV(),
      suscription: this.storage.getTurnSuscription()
    };
  }

  public async setObjpayment(value) {
      this.storage.setPaymentObj(value);
  }

  public async getObjPayment() {
    return this.storage.getPaymentObj();
  }


  public async setPaymentConfig(){
    const currentConfig = await this.getCurrentPayementConfig();
    if(currentConfig){
      this.storage.setPaymentTexts(currentConfig);
    }
  }

  private async saveTexts(): Promise<void> {
    const currentTexts = await this.getCurrentTexts();
    if (currentTexts) {
      this.storage.setTexts(currentTexts);
    }
  }

  private async setProviders() {
    const PROVIDERS = await this.getloginProvders();
    if (PROVIDERS) {
      this.storage.setloginProviders(PROVIDERS);
    }
  }

  private async getCurrentPayementConfig(): Promise<any> {
    let currentConfig: any;
    try{
    currentConfig = this.configurationsService.getConfigurationByVariable(this.paymentContentful);
    }catch (e){}
    return currentConfig;
  }


  private async getloginProvders(): Promise<any> {
    let providers: any;
    try {
      providers = this.configurationsService.getConfigurationByVariable(this.providersContentful);
    } catch (e) {
    }
    return providers;
  }


  private async getCurrentTexts(): Promise<any> {
    let currentTexts: any;
    try {
      currentTexts = await this.configurationsService.getConfigurationByVariable(this.textKey);
    } catch (e) {}

    return currentTexts;
  }

  public async getBannerContentfull(): Promise<any> {
    let currentbanner: any;
    try {
      currentbanner = await this.configurationsService.getConfigurationByVariable(this.banneradvertising);
    } catch (e) {
    }
    return currentbanner;
  }

  private getFormattedTexts(storedTexts: any): any {
    const currentTexts = (storedTexts) ? storedTexts?.data : '';

    return {
      NEWS: (currentTexts) ? (currentTexts.content.new) : (DEFAULT_TEXTS.NEWS),
      RECOMMENDATIONS: (currentTexts) ? (currentTexts.content.recommended) : (DEFAULT_TEXTS.RECOMMENDATIONS),
      MYRECOMMENDED: (currentTexts) ? (currentTexts.content['my-recommended']) : (DEFAULT_TEXTS.MYRECOMMENDED),
      REPETITIONS: (currentTexts) ? (currentTexts.content.repetitions) : (DEFAULT_TEXTS.REPETITIONS),
      OTHER_CONTENT: (currentTexts) ? (currentTexts.content['other-content']) : (DEFAULT_TEXTS.OTHER_CONTENT),
      LIVE: (currentTexts) ? (currentTexts.content['live-now-cards']) : (DEFAULT_TEXTS.LIVE),
      UPCOMING_EVENTS: (currentTexts) ? (currentTexts.content['upcoming-events']) : (DEFAULT_TEXTS.UPCOMING_EVENTS),
      COVER_TITLE: (currentTexts?.portada?.title) ? (currentTexts.portada.title) : (DEFAULT_TEXTS.COVER_TITLE),
      COVER_SUBTITLE: (currentTexts?.portada?.subtitle) ? (currentTexts.portada.subtitle) : (DEFAULT_TEXTS.COVER_SUBTITLE),
      COVER_TEXT: (currentTexts?.portada?.text) ? (currentTexts.portada.text) : (DEFAULT_TEXTS.COVER_TEXT),
      COVER_IMG: (currentTexts?.portada?.cover) ? (currentTexts.portada.cover) : (DEFAULT_TEXTS.COVER_IMG),
      NETWORK: (currentTexts?.settings?.NETWORK) ? (currentTexts.settings.NETWORK) : (DEFAULT_TEXTS.NETWORK),
      PENDING_EVENT: (currentTexts?.settings?.PENDING_EVENT) ? (currentTexts.settings.PENDING_EVENT) : (DEFAULT_TEXTS.PENDING_EVENT),
      REPLAY_PENDING: (currentTexts?.settings?.REPLAY_PENDING) ? (currentTexts.settings.REPLAY_PENDING) : (DEFAULT_TEXTS.REPLAY_PENDING),
      DEVICE_RESTRICTION: (currentTexts?.settings?.DEVICE_RESTRICTION) ? (currentTexts.settings.DEVICE_RESTRICTION) : (DEFAULT_TEXTS.DEVICE_RESTRICTION),
      DEVICE_NOT_REGISTERED: (currentTexts?.settings?.DEVICE_NOT_REGISTERED) ? (currentTexts.settings.DEVICE_NOT_REGISTERED) : (DEFAULT_TEXTS.DEVICE_NOT_REGISTERED),
      EVENT_NOT_AVAILABLE: (currentTexts?.settings?.EVENT_NOT_AVAILABLE) ? (currentTexts.settings.EVENT_NOT_AVAILABLE) : (DEFAULT_TEXTS.EVENT_NOT_AVAILABLE),
      PREMIUM_RESTRICTION: (currentTexts?.settings?.PREMIUM_RESTRICTION) ? (currentTexts.settings.PREMIUM_RESTRICTION) : (DEFAULT_TEXTS.PREMIUM_RESTRICTION),
      PREMIUM_RESTRICTION_TOOLBOX: (currentTexts?.settings?.PREMIUM_RESTRICTION_TOOLBOX) ? (currentTexts?.settings?.PREMIUM_RESTRICTION_TOOLBOX) : (DEFAULT_TEXTS.PREMIUM_RESTRICTION_TOOLBOX),
      NOT_AVAILABLE_REGION: (currentTexts?.settings?.NOT_AVAILABLE_REGION) ? (currentTexts.settings.NOT_AVAILABLE_REGION) : (DEFAULT_TEXTS.NOT_AVAILABLE_REGION),
      PURCHASE_NOT_AVAILABLE: (currentTexts?.settings?.PURCHASE_NOT_AVAILABLE) ? (currentTexts.settings.PURCHASE_NOT_AVAILABLE) : (DEFAULT_TEXTS.PURCHASE_NOT_AVAILABLE),
      HEADER_EVENT_NOT_AVAILABLE: (currentTexts?.settings?.HEADER_EVENT_NOT_AVAILABLE) ? (currentTexts.settings.HEADER_EVENT_NOT_AVAILABLE) : (DEFAULT_TEXTS.HEADER_EVENT_NOT_AVAILABLE),
      ACTIVE_DEVICES_LIMIT: (currentTexts?.settings?.ACTIVE_DEVICES_LIMIT) ? (currentTexts.settings.ACTIVE_DEVICES_LIMIT) : (DEFAULT_TEXTS.ACTIVE_DEVICES_LIMIT),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      LOGIN: (currentTexts?.login) ? (currentTexts?.login) : (DEFAULT_TEXTS.LOGINDEFAULT),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      SUSCRIPTION: (currentTexts?.suscripcion) ? (currentTexts?.suscripcion) : (DEFAULT_TEXTS.MESSAGESUSCRIPTIONDEFAULT),
      CURRENTOPTION: (currentTexts.portada?.currentoption) ? (currentTexts.portada?.currentoption) : ('orreo electrónico o teléfono'),
      CURRENTREGISTER: (currentTexts.portada?.currentregister) ? (currentTexts.portada?.currentregister) : ('REGÍSTRATE'),
      CURRENTACCOUNT: (currentTexts.portada?.currentaccount) ? (currentTexts.portada?.currentaccount) : ('¿Ya tienes una cuenta?'),
      MENUSETTINGS: (currentTexts?.menuSettings) ? (currentTexts?.menuSettings) : (DEFAULT_TEXTS.MENUSETTINGS),
      FINDER_TV: (currentTexts?.finderTv) ? (currentTexts?.finderTv) : (DEFAULT_TEXTS.finderTv),
      FINDER_WEB: (currentTexts?.finderWeb) ? (currentTexts?.finderWeb) : (DEFAULT_TEXTS.finderWeb),
      VIDEO_STREAMING: (currentTexts?.videoStreaming) ? (currentTexts?.videoStreaming) : DEFAULT_TEXTS.videoStreaming,
      TV_LOGIN: (currentTexts?.tvLogin) ? (currentTexts?.tvLogin) : DEFAULT_TEXTS.tvLogin,
      TV_LOGIN_CONFIRM: (currentTexts?.tvLoginConfirm) ? (currentTexts?.tvLoginConfirm) : DEFAULT_TEXTS.tvLoginConfirm,
      PROFILE: (currentTexts?.profile) ? (currentTexts?.profile) : DEFAULT_TEXTS.profile,
      NOTAVAILABLE_TS: (currentTexts?.NotavailableTSOpions) ? (currentTexts?.NotavailableTSOpions) : null,
      TV_LOGIN_ERROR: (currentTexts?.loginTvFailed) ? (currentTexts?.loginTvFailed) : DEFAULT_TEXTS.loginTvFailed,
      AVAILABLE_DRM: (currentTexts?.availableDrm) ? (currentTexts?.availableDrm) : DEFAULT_TEXTS.availableDrm,
      FINGER_PRINTING: currentTexts ? (currentTexts.fingerprinting ?? {}) : {},
      ERROR_LOGIN: currentTexts ? (currentTexts.errroLogin ?? {}) : {},
      CHROMECAST: currentTexts ? (currentTexts.chromecastoptions) : DEFAULT_TEXTS.chromecastoptions,
      MASTERUSERCONCURRENT: currentTexts ? (currentTexts.masterCurrentuSER) : DEFAULT_TEXTS.masterCurrentuSER,
      DEVICECURRENTUSERMASTER: currentTexts ? (currentTexts.deviceCurrentUserMaster) : {},
      PLAYDEVICECURRENTMASTER: currentTexts ? (currentTexts.playDeviceCurrentUserMaster) : {},
      USERCONCURRENTNOMASTER: currentTexts ? (currentTexts.DeviceCurrentUserNoMaster) : {},
      DELETEDEVICECURRENTUSERMASTER: currentTexts ? (currentTexts.deleteDeviceCurrentUserMaster) : {},
      DELETEDEVICECURRENTUSER: currentTexts ? (currentTexts.deleteDeviceCurrentUser) : {},
      DEVICEDETAILPAGE: currentTexts ? (currentTexts.deviceDetailPage) : {},
      PAYMENT_PAYPAL_COMPONENTS: currentTexts ? (currentTexts.paymentPaypalComponents) : {},
      INFOAUTH0: currentTexts ? (currentTexts.infoAuth0) : {},
      FAVORITE_TEAMS: currentTexts ? (currentTexts.favoriteTeams) : {},
      TEAM_SEARCH: currentTexts ? (currentTexts.teamSearch) : {},
      FOLLOWED_TEAMS: currentTexts ? (currentTexts.followedTeams): {},
      FOLLOWED_LEAGUES: currentTexts ? (currentTexts.followedLeagues): {},
      FOLLOWED_PLAYERS: currentTexts ? (currentTexts.followedPlayers): {},
      ACCOUNTASSOCIATION: currentTexts ? (currentTexts.accountassociation) : {},
      COVERALL: currentTexts ? (currentTexts.portada) : {},
      LOGOUTMODALTV: currentTexts ? (currentTexts.modalLogoutSessionTV) : {}
    };
  }

}
