import {Component, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {SegmentAnalytics} from '../../../../../core/providers/segment-analytics';
import {EVENT_TRACK_057, EVENT_TRACK_116} from '../../../../../config/analytics/events-track';
import {ActivatedRoute, Router} from '@angular/router';
import { IS_ADAPTABLE, INLINE_LOGO_PATH } from '../../../../../config/constants';
import { Storage } from 'src/app/core/storage/storage';
import {Platform} from '@ionic/angular';
import {TextManagementService} from '../../../../../services/text-management.service';
import { ChromecastService } from 'src/app/services/chromecast.service';
import { Subscription }  from 'rxjs';
import {Capacitor} from '@capacitor/core';

@Component({
  selector: 'app-main-mobile-toolbar',
  templateUrl: './main-mobile-toolbar.component.html',
  styleUrls: ['./main-mobile-toolbar.component.scss'],
})
export class MainMobileToolbarComponent implements OnInit, OnDestroy {
  EVENT_TRACK_057 = EVENT_TRACK_057;
  initLogoTigoSports = INLINE_LOGO_PATH
  datachromecast: any;
  off: boolean;
  appid: string;
  //seechromecast: boolean = false;
  //iconchromecaston = 'assets/graphics/chromecast/ico_castON.svg';
  //iconchromecastoff = 'assets/graphics/chromecast/ico_castOFF.svg';
  isDeviceControl: Subscription;
  isadaptable = IS_ADAPTABLE;
  native = Capacitor.isNativePlatform();
  ios = Capacitor.getPlatform().includes('ios');
  //@Output() isdevicecontrol = new EventEmitter<string>();
  @Output() clodemodaldevicecontrol = new EventEmitter<string>();

  constructor(
    private segmentAnalytics: SegmentAnalytics,
    private storage: Storage,
    private route: Router,
    private platform: Platform,
    private textManagementService: TextManagementService,
    private chromecastservice: ChromecastService,
    public activatedRoute: ActivatedRoute,

  ) {
    this.ValidateAndroidChromecast().then();
  }

  ngOnDestroy(): void {
    if (this.datachromecast && this.platform.platforms().indexOf('android') >= 0) {
      this.datachromecast?.unsubscribe();
      this.isDeviceControl?.unsubscribe();
    }
  }

  ngOnInit() {}

  public async eventTrack(action: string, properrties: any): Promise<void> {
    await this.segmentAnalytics.eventTrack(action, properrties);
  }

  getRoute(): boolean {

    if (this.route.url.includes('ajustes')) {
      return true;
    }

    return false;
  }

  goToSettings(){
    this.eventTrack(EVENT_TRACK_057, { journey: 'settings' });
    this.validateReplaceUrl('/ajustes');
  }

  validateReplaceUrl(route){
    if(this.storage.getVideoActive() == '1'){
      this.route.navigate([route],{ replaceUrl: true });
      this.storage.setVideoActive('0');
    }
  }

  async ValidateAndroidChromecast() {
    const idContentful = this.activatedRoute.snapshot.params.id;
    const eventType = this.activatedRoute.snapshot.params.type;
    if ((idContentful && eventType && this.route.url.includes('detail/'))
      && this.platform.platforms().indexOf('android') >= 0 && this.isadaptable && Capacitor.isNativePlatform()) {
      const { CHROMECAST } = await this.textManagementService.getTexts();
      if (CHROMECAST?.enable) {
        this.appid = CHROMECAST?.appId;
        // is init plugin values
        this.ValidateIsInitializedChromecast().then();
      } else {
        this.storage.setStatusInitChromecast('false');
      }
    }
  }

  async ValidateIsInitializedChromecast() {
 /*   this.isdrm = this.chromecastservice.addItemIsDrm$.subscribe(isdrm => {
      /* if (!isdrm) {
          this.chromecastservice.Initializatedplugin(this.appid);
          this.seechromecast = true;
       } else {
         this.seechromecast = false;
       }
      this.seechromecast = true;
      this.chromecastservice.Initializatedplugin(this.appid);
    });*/
   await this.chromecastservice.Initializatedplugin(this.appid).then(() => {
      this.storage.setStatusInitChromecast('true');
   }).catch(() => {
     this.storage.removeStatusInitChromecast();
   });
  }

}
