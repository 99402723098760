import { Injectable,Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { STATE_PAYMENT,PURCHASE,TRANSACTION_DETAIL,DEVICE_FINGER,REFRESH_TOKEN_OTT, PRUCHASE_SINGLE} from '../config/endpoints';
import { HttpClient } from '@angular/common/http';
import { Storage } from '../core/storage/storage';
import { COUNTRY_CODE_KEY } from '../config/constants';
import { DeviceHelper } from 'src/app/core/helpers/device.helper';
import { AES, enc } from "crypto-ts";
import { repeatWhen, delay, find } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoadingService } from './loading.service';
import { MessageDialogService } from './message-dialog.service';
import {SegmentAnalytics} from "../core/providers/segment-analytics";
import {SuscriptionService} from "./suscription.service";
import {Location} from "../core/interfaces/location";
import {
  EVENT_TRACK_105,
  EVENT_TRACK_106,
  EVENT_TRACK_110,
  EVENT_TRACK_113,
  EVENT_TRACK_114, EVENT_TRACK_133, EVENT_TRACK_134, EVENT_TRACK_135
} from '../config/analytics/events-track';
import { TextManagementService } from './text-management.service';
import { SettingsService } from './settings.service';
import { DOCUMENT } from '@angular/common';
import {environment} from "../../environments/environment";
import { DevicesService } from './devices.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {SecurityHelper} from './../core/helpers/security.helper';
import { AuthService } from './auth.service';
import {GlobalProperties} from "../core/interfaces/analytics/global-properties";
import {SegmentUtil} from "../core/utils/segment.util";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private renderer: Renderer2;
  eventtrack105 = EVENT_TRACK_105;
  eventtrack106 = EVENT_TRACK_106;
  eventrack113 = EVENT_TRACK_113;
  eventrack114 = EVENT_TRACK_114;
  transaction : any;
  enablepayment = false;
  invoice = false;
  product_id = '';
  product_name = '';
  product_price = '';
  datasegment: any = {};
  messageSuccess: any;
  messageError: any;
  messageEnrollment:any;
  private transactionSuscription;
  constructor( private jwtHelper: JwtHelperService, private devicesService: DevicesService, private httpClient: HttpClient ,private storage: Storage, private deviceHelper: DeviceHelper,private loadingService: LoadingService, private messageDialogService: MessageDialogService,
               private segmentAnalytics: SegmentAnalytics,  private suscriptionservice: SuscriptionService,private textManagementService: TextManagementService, private security: SecurityHelper,private authService:AuthService,
               private settingsService: SettingsService,  private segmentUtil: SegmentUtil, rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document) { this.renderer = rendererFactory.createRenderer(null, null);}

  async getStatePayment():Promise<any[]>{
    try{
      const user = this.storage.getUser();
      const {id} = user;
      let url = `${STATE_PAYMENT}/${id.replace(/-/g, '')}/${COUNTRY_CODE_KEY}`;
      const response :any  = await this.httpClient.get(url).toPromise();
      return response ;
    }catch(e){
      //console.log(e);
    }
  }

  //method to generate device finger print id
  async generateDeviceFingerPrint(){
    try{
      let url = `${DEVICE_FINGER}`;
      const deviceFingerId :any  = await this.httpClient.get(url).toPromise();
      if(deviceFingerId){
        let text = (deviceFingerId.data.html.split('<noscript>')[1]).replace("</noscript>","")
        //create img element
        var img = document.createElement('img');
        img.src = text.split("\"")[1].replace('amp;','')+'&ts='+ (new Date()).getTime();
        this.renderer.appendChild(this.document.body, img);
        return deviceFingerId.data.deviceFingerprintTransactionId;
      }
      return deviceFingerId;
    }catch(e){
     // console.log(e);
    }
  }

  async sendPurchase(card:any,cvv:string,deviceFingerId:string):Promise<any>{
    const objSegment = {
      journey: 'ppv', payment_account: card?.accountNumber
    };
    const paymentOptions = await  this.textManagementService.getPaymentConfig();
    this.messageSuccess = paymentOptions.data.paymentSuccess;
    this.messageError = paymentOptions.data.paymentFailed;
    this.messageEnrollment = paymentOptions.data.enrollmentSucces;
    this.datasegment = {};
    const user = this.storage.getUser();
    await this.ValidateEnrolmetnts(user);
    await this.validateTransactions(user);
    const { id,email } = user;
    let deviceId = await this.deviceHelper.getUUID().then();
    deviceId = deviceId.replace(/-/g, '');
    this.loadingService.setLoading(true,"purchase");
    const defaultConfiguration = await this.settingsService.getDefaultConfiguration();
    const data = {
      deviceId,
      deviceFingerprintId: deviceFingerId,
      phoneNumber: user.phone_number ? user.phone_number : '',
      email: email ? email : '',
      documentType: card.billing.documentType ? card.billing.documentType : defaultConfiguration?.documentType ? defaultConfiguration?.documentType : 'RUC',
      cvv,
      tokenizedCardId: card.card_token_id ? card.card_token_id : card.id,
      documentNumber: card.billing.documentNumber ? card.billing.documentNumber : 'CF'
    };
    const { PYT_IV, PYT_VALUE} = environment;
    const secret = enc.Utf8.parse(this.security.decrypt(PYT_VALUE,1));
    const iv = enc.Utf8.parse(this.security.decrypt(PYT_IV,1));
    const dataEncrypted = AES.encrypt(JSON.stringify(data), secret, { iv });
    const url = `${PURCHASE}${id.replace(/-/g, '')}/${COUNTRY_CODE_KEY}`;


    try{
      const purchaseResult: any = await this.httpClient
        .post(url, {data: dataEncrypted.toString()})
        .toPromise();
      if(purchaseResult.httpStatusCode != 200){
        this.EvenTrack(EVENT_TRACK_134, objSegment).then();
        this.errorMessage();
        return;
      }


      const { body } = purchaseResult;
      if(purchaseResult.httpStatusCode == 200 && body.enrollmentId){
        this.EvenTrack(EVENT_TRACK_135, objSegment).then();
        this.successEnrollmentMessage();
        this.loadingService.setLoading(false,"purchase");
        this.loadingService.setLoading(false,"send-card");
        return;
      }
      if (body) {
        const { transactionId } = body;

        await this.GetDataSegment(transactionId);


        let that = this
        let timeOut = true;
        setTimeout(function(){
          if(timeOut){

            let title = String(that.messageError.title);
            let subtitle = String(that.messageError.message);
            let img = 'assets/graphics/payment/transaction-error.svg';
            let buttonMessage = that.messageError.buttonMessage;
            that.transactionSuscription.unsubscribe();
            that.datasegment.error_type = 'Timeout';
            that.datasegment.email_invoice = email ? email : '';
            that.datasegment.error_id = '408';
            that.datasegment.error_message = 'Timeout';
            that.EvenTrack(that.eventtrack105, that.datasegment);
            that.loadingService.setLoading(false,"send-card");
            that.loadingService.setLoading(false,"purchase");
            that.EvenTrack(EVENT_TRACK_134, objSegment).then();
            that.messageDialogService.messageDialogSuscroption(title,subtitle,img,"",buttonMessage);
          }
        }, 40000);//wait 40 seconds



        this.transactionSuscription =  this.getTransactionDetails(transactionId)
          .subscribe(async (response) => {
            this.EvenTrack(EVENT_TRACK_133, objSegment).then();
            if(response?.code == 400 || response?.data?.payment?.paymentApproved == false  || response?.data?.fulfillment?.fulfillmentSucceded == false || response.data.fulfillment == null){
              timeOut = false
              this.datasegment.error_type = 'Transacción fallida';
              this.datasegment.email_invoice = email ? email : '';
              this.datasegment.error_id = response?.code;
              this.datasegment.error_message = response?.message ? response?.message.error : 'Transacción fallida';
              this.loadingService.setLoading(false, 'send-card');
              that.loadingService.setLoading(false,"purchase");
              await this.EvenTrack(this.eventtrack105, this.datasegment);
              this.EvenTrack(EVENT_TRACK_134, objSegment).then();
              this.errorMessage();
              return
            }
            if (response && response?.data?.payment?.paymentApproved == true) {
              timeOut = false
              this.datasegment.product_variant = 'subscription';
              await this.EvenTrack(this.eventtrack106, this.datasegment);
              // this.cicleToken();
              this.loadingService.setLoading(false, 'send-card');
              this.loadingService.setLoading(false,'purchase');
              const defaultProperties: GlobalProperties = await this.segmentUtil.getGlobalProperties();
              const objPaymentConfirm = {
                business_category: defaultProperties.business_category,
                authentication_type: defaultProperties.authentication_type,
                page_name: 'Payment Confirmation',
                journey: 'user interface',
                payment_method: 'credit_card',
                amount: this.product_price,
                product_name: this.product_name
              };
              await this.EvenTrack(EVENT_TRACK_110, objPaymentConfirm);
              this.EvenTrack(EVENT_TRACK_135, objSegment).then();
              this.successMessage();
            }
          });
      }else{
        await this.GetDataSegment();
        this.datasegment.error_type = 'Transacción fallida';
        this.datasegment.email_invoice = email ? email : '';
        this.datasegment.error_id = purchaseResult.httpStatusCode;
        this.datasegment.error_message = 'Transacción fallida';
        await this.EvenTrack(this.eventtrack105, this.datasegment);
        this.loadingService.setLoading(false, 'send-card');
        this.loadingService.setLoading(false,"purchase");
        this.EvenTrack(EVENT_TRACK_134, objSegment).then();
        this.errorMessage();
      }
      return;
    }catch{
      await this.GetDataSegment();
      this.datasegment.error_type = 'Transacción fallida';
      this.datasegment.email_invoice = email ? email : '';
      this.datasegment.error_id = '500';
      this.datasegment.error_message = 'Transacción fallida';
      await this.EvenTrack(this.eventtrack105, this.datasegment);
      this.loadingService.setLoading(false, 'send-card');
      this.loadingService.setLoading(false,'purchase');
      this.EvenTrack(EVENT_TRACK_134, objSegment).then();
      this.errorMessage();
    }



  }


  getTransactionDetails(transactionId: number): Observable<any> {
    const url = TRANSACTION_DETAIL +'/'+ transactionId;
    try {


      return this.httpClient.get(url).pipe(
        repeatWhen(obervable => obervable.pipe(delay(5000))),
        find(data => this.checkStatus(data, url))
      );
    } catch (e) {
      console.error(e);

    }
  }

  checkStatus(response: any, url: string): boolean {
    const { status,code } = response;
    if(code == 400 || response.data?.payment?.paymentApproved == false ){
      return true;
    }

    return status;
  }

  getTransaction(): any {
    return this.transaction;
  }

  private errorMessage(){

    let title =  this.messageError.title;
    let subtitle = this.messageError.message;
    let img = 'assets/graphics/payment/transaction-error.svg';
    let buttonMessage = this.messageError.buttonMessage;
    this.messageDialogService.messageDialogSuscroption(title,subtitle,img,"",buttonMessage);
  }

  // private successMessage(){
  //   let title =  this.messageSuccess.title;
  //   let subtitle = this.messageSuccess.message;
  //   let img = 'assets/graphics/payment/transaction-check.svg';
  //   this.messageDialogService.messageDialogSuscroption(title,subtitle,img,"/inicio",false);
  // }

  private successMessage(){
    let title = this.messageSuccess.title;
    let subtitle = this.messageSuccess.message;
    let img = 'assets/graphics/payment/transaction-check.svg';
    let buttonMessage = this.messageSuccess.buttonMessage;
    if(this.storage.getIsWebView() == 'true'){
      this.messageDialogService.messageDialogSuscroption(title,subtitle,img,"OTT",buttonMessage);
    }else{
      this.authService.refreshAuthentication();
      this.messageDialogService.messageDialogSuscroption(title,subtitle,img,"/inicio",buttonMessage);
    }
  }

  private successEnrollmentMessage(){
    let title = this.messageEnrollment.title;
    let subtitle = this.messageEnrollment.message;
    let img = 'assets/graphics/payment/transaction-check.svg';
    let buttonMessage = this.messageEnrollment.buttonMessage;
    if(this.storage.getIsWebView() == 'true'){
      this.messageDialogService.messageDialogSuscroption(title,subtitle,img,"OTT",buttonMessage);
    }else{
      this.authService.refreshAuthentication();
      this.messageDialogService.messageDialogSuscroption(title,subtitle,img,"/inicio",buttonMessage);
    }

  }


  // eslint-disable-next-line @typescript-eslint/member-ordering
  async sendPurchaseSingle(card: any,cvv: string,deviceFingerId: string, idoffer: string, bodycard?: any) {
    let data = {};
    const paymentOptionss = await  this.textManagementService.getPaymentConfig();
    this.messageSuccess = paymentOptionss.data.paymentSuccess;
    this.messageError = paymentOptionss.data.paymentFailed;
    this.messageEnrollment = paymentOptionss.data.enrollmentSucces;
    const dataoffset: any = paymentOptionss.data.OfferSelectionOption;
    this.datasegment = {};
    const user = this.storage.getUser();
    // const city: any = this.storage.getUser().location.city_name;
    // await this.ValidateEnrolmetnts(user);
    //  await this.validateTransactions(user);
    const { id,email } = user;
    const deviceId = await this.deviceHelper.getUUID().then();
    deviceId.replace(/-/g, '');
    this.loadingService.setLoading(true,'purchase');
    const defaultConfigurations = await this.settingsService.getDefaultConfiguration();
    if (bodycard) {
      const currentYear = new Date().getFullYear().toString();
      const expirationdate = currentYear.substring(0,2);
      const text = bodycard.cardExpiration ? bodycard.cardExpiration.split('/') :  null;
      data = {
        deviceId,
        deviceFingerprintId: deviceFingerId,
        phoneNumber: user.phone_number ? user.phone_number : '',
        customerName: user ? user.userInfo.userName : '',
        email: email ? email : bodycard?.registrationEmail,
        documentNumber: bodycard.billingForm.documentNumber ? bodycard.billingForm.documentNumber : 'CF',
        documentType: bodycard.billingForm.documentType ? bodycard.billingForm.documentType
          : defaultConfigurations?.documentType ? defaultConfigurations?.documentType : 'RUC',
        cvv: bodycard.cvv,
        updatePaymentSeparately: false,
        createPaymentToken: false,
        creditCardDetails: {
          accountNumber: bodycard.accountNumber.replace(' ', '').replace(' ', '').replace(' ', ''),
          expirationMonth: text ? text[0].toString() : '',
          expirationYear: text ? expirationdate+text[1].toString() : '',
          cardType: bodycard.type,
          cvv: bodycard.cvv
        },
        billToAddress: {
          firstName: user ? user.given_name : '',
          lastName:  user ? user.family_name : '',
          documentNumber: bodycard.billingForm.documentNumber ? bodycard.billingForm.documentNumber : 'CF',
          country: bodycard.billingForm.country ? bodycard.billingForm.country : '',
          email: email ? email : bodycard?.registrationEmail,
          city: bodycard.billingForm?.city ? bodycard.billingForm?.city :  'Mountain View',
          street: bodycard.billingForm?.street ? bodycard.billingForm?.street : '1295 Charleston Road',
          postalCode: bodycard.billingForm?.postalCode ? bodycard.billingForm?.postalCode : '94043',
          state: bodycard.billingForm?.state ? bodycard.billingForm?.state : 'CA',
        }
      };
    } else {
      data = {
        deviceId,
        deviceFingerprintId: deviceFingerId,
        phoneNumber: user.phone_number ? user.phone_number : '',
        email: email ? email : '',
        // eslint-disable-next-line max-len
        documentType: card.billing.documentType ? card.billing.documentType : defaultConfigurations?.documentType ? defaultConfigurations?.documentType : 'RUC',
        cvv,
        tokenizedCardId: card.card_token_id ? card.card_token_id : card.id,
        documentNumber: card.billing.documentNumber ? card.billing.documentNumber : 'CF'
      };
    }
    const { PYT_IV, PYT_VALUE} = environment;
    const secret = enc.Utf8.parse(this.security.decrypt(PYT_VALUE,1));
    const idofferdes = AES.decrypt(idoffer.toString(), secret).toString(enc.Utf8);
    const iv = enc.Utf8.parse(this.security.decrypt(PYT_IV,1));
    const dataaccess: any = dataoffset.PaymentOffer.find(E => E.idAccess === idofferdes);
    const dataEncrypted = AES.encrypt(JSON.stringify(data), secret, { iv });
    const url = `${PRUCHASE_SINGLE}${id.replace(/-/g, '')}/${COUNTRY_CODE_KEY}/${idofferdes}`;
    try {
      const purchaseResult: any = await this.httpClient
        .post(url, {data: dataEncrypted.toString()})
        .toPromise();
      if (this.storage.getPayloadPaypal()) {
        this.storage.removePayloadPaypal();
      }
      if (this.storage.getDeviceNative()) {
        this.storage.removeDeviceNative();
      }
      if(purchaseResult.httpStatusCode !== 200){
        this.errorMessage();
        return;
      }
      const { body } = purchaseResult;
      if (body) {
        const  { transactionId, orderId } = body;
        const that = this;
        let timeOut = true;
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        setTimeout(function(){
          if(timeOut){
            const title = String(that.messageError.title);
            const subtitle = String(that.messageError.message);
            const img = 'assets/graphics/payment/transaction-error.svg';
            const buttonMessage = that.messageError.buttonMessage;
            that.transactionSuscription.unsubscribe();
            that.datasegment.error_type = 'Timeout';
            that.datasegment.email_invoice = email ? email : '';
            that.datasegment.product_id = dataaccess ? dataaccess.billingCode : '';
            that.datasegment.product_name = dataaccess ? dataaccess.name : '';
            that.datasegment.amount = dataaccess ? dataaccess.total : '';
            that.datasegment.product_category = 'ppv';
            that.datasegment.error_id = '408';
            that.datasegment.error_message = 'Timeout';
            that.EvenTrack(that.eventrack114, that.datasegment);
            that.loadingService.setLoading(false,'send-card');
            that.loadingService.setLoading(false,'purchase');
            that.transactionSuscription.unsubscribe();
            that.messageDialogService.messageDialogSuscroption(title,subtitle,img,'',buttonMessage);
          }
        }, 40000);//wait 40 seconds
        this.transactionSuscription = this.getTransactionDetails(transactionId).subscribe((async (result: any) => {
          if(result?.code === 400 || result?.data?.payment?.paymentApproved === false
            || result?.data?.fulfillment?.fulfillmentSucceded === false || result.data.fulfillment == null) {
            timeOut = false;
            this.getPaymentSingeSegment(dataaccess);
            this.loadingService.setLoading(false, 'send-card');
            that.loadingService.setLoading(false,'purchase');
            await this.EvenTrack(this.eventtrack105, this.datasegment);
            this.errorMessage();
            return;
          }
          if (result && result?.data?.payment?.paymentApproved === true) {
            timeOut = false;
            this.getPaymentSingeSegment(dataaccess, orderId);
            this.loadingService.setLoading(false, 'send-card');
            this.loadingService.setLoading(false,'purchase');

            const defaultProperties: GlobalProperties = await this.segmentUtil.getGlobalProperties();
            const objPaymentConfirm = {
              business_category: defaultProperties.business_category,
              authentication_type: defaultProperties.authentication_type,
              page_name: 'Payment Confirmation',
              journey: 'user interface',
              payment_method: 'credit_card',
              amount: dataaccess?.total,
              product_name: dataaccess?.name
            };
            await this.EvenTrack(EVENT_TRACK_110, objPaymentConfirm);
            this.successMessage();
          }
        }));
      } else {
        this.getPaymentSingeSegment(dataaccess);
        this.loadingService.setLoading(false, 'send-card');
        this.loadingService.setLoading(true,'purchase');
        this.errorMessage();
      }
    } catch (e) {
      this.getPaymentSingeSegment(dataaccess);
      this.loadingService.setLoading(false, 'send-card');
      this.loadingService.setLoading(false,'purchase');
      this.errorMessage();
      console.error(e);
    }
  }


  // eslint-disable-next-line @typescript-eslint/member-ordering
  getPaymentSingeSegment(dataaccess, orderId?) {
    this.datasegment.product_id = dataaccess ? dataaccess.billingCode : '';
    this.datasegment.journey = 'ppv';
    this.datasegment.automatic_payment_enabled = false;
    this.datasegment.payment_method = '';
    this.datasegment.product_category = 'ppv';
    this.datasegment.product_name = dataaccess ? dataaccess.name : '';
    this.datasegment.amount = dataaccess ? dataaccess.total : '';
    this.datasegment.product_variant = 'subscription';
    this.datasegment.product_price = dataaccess ? dataaccess.messagebutton : '';
    this.datasegment.order_id = orderId ? orderId : '';
    if (orderId) {
      this.EvenTrack(this.eventrack113, this.datasegment);
    } else {
      this.EvenTrack(this.eventrack114, this.datasegment);
    }
  }


  /** segment **/

  async EvenTrack(event: string, datasegment: any) {
    await this.segmentAnalytics.eventTrack(event, datasegment);
  }

  async ValidateEnrolmetnts(user) {
    if (user) {
      try {
        const { id } = user;
        const iduser = id.replace(/-/g, '');
        const response: any = await this.suscriptionservice.getsuscription(iduser);
        this.enablepayment = !!(response.httpStatusCode === 200 && response.body)
      } catch (e) {
       // console.log(e);
      }
    }
  }

  async validateTransactions(user) {
    if (user) {
      try {
        const { id } = user;
        const iduser = id.replace(/-/g, '');
        const location: Location = this.storage.getLocation();
        if (location) {
          const result: any = await this.suscriptionservice.gettransaction(iduser, location.code);
          this.product_id = result && result.data ? result.data.product_id : '';
          this.product_name = result && result.data ? result.data.product_name : '';
          this.product_price = result && result.data ? result.data.product_price : '';
          this.invoice = result && result.data ? result.data.invoice_selected : false;
        }
      } catch(e) {
       // console.log(e);
      }
    }
  }

  GetDataSegment(order_id?) {
    this.datasegment = {
      product_id: this.product_id,
      product_name: this.product_name,
      product_price: this.product_price,
      amount: this.product_price,
      journey: 'ppv',
      product_category: 'ppv',
      payment_method: 'credit_card',
      order_id: order_id ? order_id : '',
      invoice_selected: this.invoice,
      automatic_payment_enabled: this.enablepayment
    };
  }

}
