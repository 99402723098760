import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {HttpClient} from "@angular/common/http";
import { DEFAULT_CONFIG_S3 } from '../config/constants';

@Injectable({
  providedIn: 'root'
})
export class AwsS3ServicesService {

  constructor(private httpClient: HttpClient) { }

  async getConfigValuesS3(): Promise<any> {
    let response: any = {}
    try {
      const url: string = environment.PATH_CONFIG_S3;
      response = await this.httpClient.get(url).toPromise();
      if (typeof response !== 'object' || response === null) {
        response  = DEFAULT_CONFIG_S3;
      }
    } catch (e) {
      response  = DEFAULT_CONFIG_S3;
    }
    return response;
  }

}
