import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {CONFIGURATIONS} from '../config/endpoints';
import {ConfigurationRequest} from '../core/interfaces/configuration-request';
import {Storage} from '../core/storage/storage';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsService {

  constructor(private httpClient: HttpClient,
              private storage: Storage) {
  }

  /* Get app configurations from middleware */
  public async getConfigurationByVariable(variableName: string): Promise<any> {
    const location = this.storage.getLocation();
    const configurationRequest: ConfigurationRequest = {
      country: location.code,
      name: variableName,
      type: 'json'
    };
    let data: any;
    try {
      data = await this.httpClient.post(CONFIGURATIONS, configurationRequest).toPromise();
    } catch (e) {}

    return data;
  }
}
