/**
 * Constants for general application configurations.
 *
 * @remarks - These constants are used for generic settings of the application in general.
 **/

export const ANDROID_PLATFORM_STATUS_BAR_COLOR = '#071029';
export const CONTENT_LABEL = 'VER CONTENIDO';
//export const INLINE_LOGO_PATH = 'assets/graphics/app/logo_tigo-sport_inline.svg';
export const INLINE_LOGO_PATH = 'assets/graphics/app/logo_tigo-sport_inline_2.svg';
export const STACKED_LOGO_PATH = 'assets/graphics/app/logo_tigo-sport_stacked_2.svg';
export const IS_ADAPTABLE = true;
export const COUNTRY_CODE_KEY = 'COUNTRY_CODE';
export const PPV_PG = 'STANDARD';
export const CARROUSEL_LIMIT = 50;
export const PAGINATION_LIMIT = 20;
export const COUNTRIES = [
  {
    productionCode: 'BO',
    debugCode: 'BODEV',
    name: 'Bolivia',
    currency: 'BOB'
  },
  {
    productionCode: 'CR',
    debugCode: 'CRDEV',
    name: 'Costa Rica',
    currency: 'CRC'
  },
  {
    productionCode: 'GT',
    debugCode: 'DEV',
    name: 'Guatemala',
    currency: 'GTQ'
  },
  {
    productionCode: 'HN',
    debugCode: 'HNDEV',
    name: 'Honduras',
    currency: 'HNL'
  },
  {
    productionCode: 'PY',
    debugCode: 'PYDEV',
    name: 'Paraguay',
    currency: 'PYG'
  },
  {
    productionCode: 'SV',
    debugCode: 'SVDEV',
    name: 'El Salvador',
    currency: 'USD'
  },
  {
    productionCode: 'PA',
    debugCode: 'PADEV',
    name: 'Panama',
    currency: 'PAB'
  },
  {
    productionCode: 'NI',
    debugCode: 'NIDEV',
    name: 'Nicaragua',
    currency: 'NIO'
  }
];
export const BROWSER = {
  iOS_OPTIONS: {
    location: 'no',
    hidden: 'no',
    closebuttoncolor: '#EFF2F7',
    clearcache: 'yes',
    clearsessioncache: 'yes',
    zoom: 'no',
    shouldPauseOnSuspend: 'no',
    disallowoverscroll: 'no',
    toolbar: 'yes',
    toolbarcolor: '#00377B',
    enableViewportScale: 'no',
    allowInlineMediaPlayback: 'no',
    presentationstyle: 'fullscreen',
    footer: 'no',
    hideurlbar: 'yes',
    usewkwebview: 'yes',
    hidenavigationbuttons: 'no',
    closebuttoncaption: 'Cerrar'
  },
  iOS_TARGET: {
    target: '_blank'
  },
  ANDROID_OPTIONS: {
    location: 'yes',
    hidden: 'no',
    closebuttoncolor: '#EFF2F7',
    clearcache: 'yes',
    clearsessioncache: 'yes',
    zoom: 'no',
    shouldPauseOnSuspend: 'no',
    disallowoverscrollv: 'no',
    toolbar: 'no',
    toolbarcolor: '#00377B',
    enableViewportScale: 'no',
    allowInlineMediaPlayback: 'no',
    presentationstyle: 'pagesheet',
    footer: 'no',
    hideurlbar: 'yes',
    hidenavigationbuttons: 'yes'
  },
  ANDROID_TARGET: {
    target: '_self'
  },
  WEB_TARGET: {
    target: '_self'
  }
};
export const DEFAULT_TEXTS = {
  NEWS: 'Noticiero',
  RECOMMENDATIONS: 'Recomendados',
  MYRECOMMENDED: 'Recomendaciones para Ti',
  REPETITIONS: 'Repeticiones',
  OTHER_CONTENT: 'Otros contenidos',
  LIVE: 'En vivo ahora',
  UPCOMING_EVENTS: 'Próximos eventos',
  COVER_TITLE: '¡VIVE LA PASIÓN DEL FÚTBOL!',
  COVER_SUBTITLE: 'Disfruta de tus partidos y torneos favoritos con Tigo Sports',
  COVER_TEXT: 'Activa Tigo Sports por 30 días por sólo Gs 88,000',
  COVER_IMG: 'assets/graphics/cover/cover.jpg',
  NETWORK: 'La conexión a la red no esta disponible. Verifica nuevamente más tarde.',
  PENDING_EVENT: 'El evento aún no ha comenzado. Verifica el horario e ingresa nuevamente.',
  REPLAY_PENDING: 'El juego ha finalizado. Próximamente podrás disfrutar la repetición en la sección Juegos en Vivo.  Intenta más tarde.',
  DEVICE_RESTRICTION: 'Su usuario únicamente permite la visualización de partidos en 1 dispositivo, debe ir a configuración y gestionar los permisos de dispositivos',
  DEVICE_NOT_REGISTERED: 'Su dispositivo no ha podido ser registrado, para continuar primero debe completar esta operación.',
  EVENT_NOT_AVAILABLE: 'El evento no está disponible.',
  PREMIUM_RESTRICTION: 'Para disfrutar contenido premium debes tener autorización.',
  PREMIUM_RESTRICTION_TOOLBOX: 'Para disfrutar el contenido premium con el usuario personal, debe tener autorizacion',
  NOT_AVAILABLE_REGION: 'Este evento no está disponible en tu región.',
  PURCHASE_NOT_AVAILABLE: 'No esta disponible realizar una compra en tu región',
  HEADER_EVENT_NOT_AVAILABLE: 'No puedes acceder al evento',
  ACTIVE_DEVICES_LIMIT: 'Lo sentimos, has llegado al máximo de dispositivos activos permitidos, debes desactivar o eliminar uno para poder continuar',
  LOGINDEFAULT: {
    welcome: '¡Bienvenido!',
    title: '¿Cómo deseas ingresar tú sesión y unirse al juego?',
    nameaccess: 'COMPRA TÚ ACCESO POR GS. 88.000',
    iconaccess: 'ico_tigo-sports.svg',
    enableaccess: true,
    nameprovider: '¿Tienes Tigo Sports a través de otro proveedor?',
    nameoptionprovider: 'INGRESAR CON MI PROVEEDOR',
    enableprovider: true,
    optionslogin: [
      {
        ios: true,
        web: true,
        icon: 'ico-mail.png',
        type: 'other',
        scope: 'profile+email+convergent',
        title: 'CONTRASEÑA',
        android: true,
        subtitle: 'Utilizá tu número de cliente o correo electrónico',
        originType: 'login_home',
        description: '',
        flags: 'api_version_2,preset_country_py'
      },
      {
        ios: true,
        web: true,
        icon: 'ico-mobile.png',
        type: 'mobile',
        scope: 'phone',
        title: 'LINEA TIGO',
        android: true,
        subtitle: 'Usá tu número de teléfono Tigo',
        originType: 'login_mobile',
        description: '',
        flags: 'api_version_2'
      }
    ]},
  MESSAGESUSCRIPTIONDEFAULT: {
    tittle: 'Suscripción',
    tittlesuscription: 'Detalles de la membresía',
    messagebutton: 'SUSCRIBIRTE',
    optionshopinhistory: 'Historial de facturación',
    optionpaymenmethod: 'Método de pago',
    optioncancelsuscription: 'Cancelar suscripción'
  },
  MENUSETTINGS:{
    tittle: 'Ajustes',
    subtittle: 'Mi cuenta',
    optionnotification: {
      title: 'Notificaciones',
      iconnotification: 'ico_tigo-sports.svg'
    },
    optiondevice: {
      tittle: 'Dispositivos',
      icondevice: 'ico_devices.svg'
    },
    optionvideo: {
      tittle: 'Video',
      subtittle: 'Calidad de video',
      iconvideo: 'ico_video.svg'
    },
    optionabout: {
      tittle: 'Acerca de',
      subtittle: 'Aplicación',
      iconabout: 'information-circle-outline'
    },
    optionsuscription: {
      tittle: 'Suscripción',
      iconsuscription: 'ico_subscription.svg'
    },
    textAuthenticated: 'Iniciar sesión',
    textcloseAuthenticated: 'Cerrar sesión',
    termsandconditions: 'Términos y Condiciones',
    privacypolicy: 'Política de privacidad'
  },
  finderTv: {
    userMessage: 'Ingresa un término de búsqueda',
    noResult: 'No hay coincidencias con tu búsqueda. Seleccioná otras palabras para encontrar lo que buscás.'
  },
  finderWeb: {
    userMessage: 'Ingresa un término de búsqueda',
    noResult: 'No hay coincidencias con tu búsqueda. Seleccioná otras palabras para encontrar lo que buscáss.'
  },
  login: {
    currenttitle: 'Ingresa o regístrate'
  },
  videoStreaming: {
    wServer: 'https://multidrm.vsaas.verimatrixcloud.net/widevine',
    fServer : 'https://multidrm.vsaas.verimatrixcloud.net/fairplay',
    fCert: 'https://s3.amazonaws.com/ott-content/gdvcas-develop/assets/fps_certificate.der'
  },
  tvLogin: {
    title: 'INGRESA A TU CUENTA',
    stepOne: 'Ingresa a la siguiente página web desde tu celular o computadora',
    stepTwo: 'Ingresa el siguiente código',
    url: 'HTTPS://WWW.TIGOSPORTS.COM.PY/-/INGRESA',
    urlV4: 'HTTPS://WWW.TIGOSPORTS.COM.PY/-/INGRESAR',
    qrUrl: "https://www.tigosports.com.py/-/ingresa",
    image: '/assets/graphics/tv/phone-code-py.png',
    message: 'Se ha generado un nuevo código, ingresa el nuevo',
    header: 'Alerta',
    subheader: 'Código vencido'
  },
  tvLoginConfirm: {
    title: '¡Listo!',
    subtitle: 'Ahora podés disfrutar los juegos Tigo Sports',
    buttonMessage: 'Ingresa'
  },
  profile:{
    pageProfileTitle:"Perfil",
    name: "Nombre y Apellido",
    mail: "Correo principal de ingreso",
    phone: "Teléfono principal",
    deleteMessage: "¿Quieres borrar este usuario?",
    pageDeleteTitle:"Borrar Cuenta",
    deleteTitle: "¿Estás seguro de que quieres borrar tu usuario?",
    warnings: ["Perderás toda la información asociada a este perfil",
                "Se cancelarán todos tus autopagos y servicios",
                "Si tienes una cuenta de empresa, este servicio no aparecerá a sus usuarios",
                "Al borrar tu perfil no cancelas tu servicio, solo borras toda la información asociada a él"],
    footerMessage:"Importante: al borrar usuario tu sesión se cerrará. El acceso a tu cuenta Tigo Quedará bloqueado hasta que tu información se haya borrado de manera segura.",
    buttonDelete:"BORRAR",
    modalMessage:"Escribe la palabra BORRAR para confirmar esta acción",
    modalButton:"FINALIZAR"
 },
 loginTvFailed: {
  title: "¡Uy! Parece que hubo un error",
  message: "Código no válido",
  buttonMessage: "INTENTA DE NUEVO"
},
  availableDrm: {
  title: 'El contenido no es compatible',
  subtitle: 'Para disfrutar del contenido debes descargar e instalar nuestra aplicación móvil',
  textButton: 'Descargar app',
  titlecomplement: 'con tu navegador en este dispositivo',
  availableDrmAndroid: false,
    androidDeepLink:'https://play.google.com/store/apps/details?id=py.com.tigosports',
    iosDeepLink:'https://apps.apple.com/app/tigo-sports-paraguay/id963028258'
},
  chromecastoptions: {
    enable: true
  },
  masterCurrentuSER: {
    title: 'Llegaste al límite de pantallas en tu plan',
    subTitle: 'Hay demasiadas personas usando la cuenta en este momento. Selecciona un dispositivo para detener la reproducción, y presiona',
    messageConfirm: 'REPRODUCIR AQUÍ:',
    buttonConfirm: 'REPRODUCIR AQUÍ',
    buttonCancel: 'CANCELAR'
  },
  subscriptionModePhone: {
    title: 'Tu membresía de Tigo Sports está incluída con tu proveedor TIGO.',
    subTitle: 'Si deseas realizar algún cambio en tu cuenta de TIGO, chatea con nuestro asistente virtual'
  },
  textPageSuscription: {
    title: 'Se aplicará un cargo de ',
    subTitle: ' a tu cuenta el '
  }
};
export const DEFAULT_CONFIG_S3 = {
  UserConcurrentsOn: {
    androidMovil: true,
    androidTv: true,
    ios: true,
    web: true
  },
  concurrentMasterUsersOn: {
    androidMovil: false,
    androidTv: false,
    ios: false,
    web: false
  },
  entitlement: {
    androidMovil: true,
    androidTv: true,
    ios: true,
    web: true
  },
  multiEntitlement: {
    androidMovil: false,
    androidTv: false,
    ios: false,
    web: false
  }
}
