import { Injectable } from '@angular/core';
import { registerPlugin } from '@capacitor/core';
const ExoPlayerPlugin = registerPlugin<any>('ExoPlayerPlugin');
export default ExoPlayerPlugin;
@Injectable({
  providedIn: 'root'
})
export class ExoplayerService {

  constructor() { }

  public setPlayer(data:any, url:string, user:string, isDrm: boolean, licenseToken: string, wServer: string, bodywm: any, isVecima: boolean, adsbody?: any, isValidChromecast?: any){
    const type = url.includes('m3u8') ? 'm3u8' : 'mpd'

     this.init(
      url,
      data.video_title,
      "FHD",
      type,
      data.token,
      isDrm,
      data.isLive,
      user,
      licenseToken,
      wServer,
      bodywm,
      isVecima,
      adsbody,
      isValidChromecast,
    );
  }

  async init(url: string,title: string,subtitle: string,type: string,token: string,isDrm: boolean,isLive: boolean,user:string, licenseToken:string, wServer:string, bodywm: any, isVecima: boolean, adsbody?: any, isValidChromecast?: any){
    //console.log({mediaUrl: url,title:title,subtitle:subtitle,token:token,drm:isDrm,type:type});
    const response = await ExoPlayerPlugin.initialize({mediaUrl: url,title:title,subtitle:subtitle,token:token,drm:isDrm,type:type,isLive:isLive,user:user,licenseT:licenseToken,wServer:wServer,objectwm:bodywm, adsbody,isVecima, isValidChromecast});
  }
}
