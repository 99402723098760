import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
//import * as moment from 'moment';
import moment from 'moment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TOKENIZATION } from '../config/endpoints';
import { Storage } from '../core/storage/storage';
import { DevicesService } from './devices.service';
import { SettingsService } from './settings.service';
import { DeviceHelper } from 'src/app/core/helpers/device.helper';
import { LocationService } from './location.service';
import { LoadingService } from './loading.service';
import { AES, enc } from "crypto-ts";
import {environment} from "../../environments/environment";
import {SecurityHelper} from './../core/helpers/security.helper';
import {SegmentAnalytics} from '../core/providers/segment-analytics';
import {EVENT_TRACK_129} from '../config/analytics/events-track';

@Injectable({
  providedIn: 'root'
})
export class TokenizationService {

  constructor(private authService:AuthService,
    private httpClient: HttpClient,
    private storage:Storage,
    private deviceService:DevicesService,
    private settingsService:SettingsService,
    private deviceHelper:DeviceHelper,
    private locationService:LocationService,
    private loadingService: LoadingService,
    private security: SecurityHelper,
    private segmentanalytics: SegmentAnalytics,
    ) { }

  async getCreditCards(): Promise<any[]> {
    this.loadingService.setLoading(true,'get-cards');
    const user = this.storage.getUser();
    const { id } = user;
    let deviceId = await this.deviceHelper.getUUID().then();
    deviceId.replace(/-/g, '');
    let cards: any = [];
    const defaultConfiguration = await this.settingsService.getDefaultConfiguration();
    let query_string = new HttpParams()
    .set('accountType',defaultConfiguration ? defaultConfiguration.accountType : '')
    .set('applicationName', defaultConfiguration ? defaultConfiguration.applicationName : '')
    .set('accountNumber',user ? (id).replace(/-/g, '') : '')
    .set('deviceId', deviceId);

    const url = `${TOKENIZATION}/${id.replace(/-/g, '')}?${query_string.toString()}`;

    try {
      const response: any = await this.httpClient.get(url).toPromise();
      cards = response ? response.body : [];
    } catch (e) {
      console.error(e);
      this.loadingService.setLoading(false,'get-cards');
    }

    cards = cards ? cards : [];
    const creditCards = this.buildCreditCards(cards);
    this.loadingService.setLoading(false,'get-cards');
    return creditCards;
  }

  buildCreditCards(creditCards: any): any[] {
    const cards = [];
    for (const creditCard of creditCards) {
      try {
        let type: string;
        let code: any;
        const { maskedCreditCardNumber } = creditCard;
        // if (!isNaN(maskedCreditCardNumber.substr(0, 4))) {
        //   const identifiedCard = CreditCard(maskedCreditCardNumber);
        //   type = identifiedCard.type;
        //   code = identifiedCard.code;
        // }
        creditCard.accountNumber = `**** ${maskedCreditCardNumber.substr(maskedCreditCardNumber.length - 4)}`;
        const month = moment(creditCard.expirationMonth, 'M').format('MM');
        const year = moment(creditCard.expirationYear, 'YYYY').format('YY');
        creditCard.month = month;
        creditCard.year = year;
        creditCard.cardExpiration = `${month}/${year}`;
        creditCard.type = type;
        creditCard.code = code;
        // creditCard.icon = this.defineIcon(type);
        cards.push(creditCard);
      } catch (e) {
      }
    }

    return cards;
  };

  public async removeCreditCard(card: any): Promise<any> {
    const user = this.storage.getUser();
    const { id } = user;
    const url = `${TOKENIZATION}/${id.replace(/-/g, '')}/${card?.card_token_id ? card.card_token_id : card.id}`;
    try {
      const response = await this.httpClient.delete(url).toPromise();
      return response;
    }
    catch (e) {
      console.error(e);
    }
  }

  async sendTokenizationCard(cardInfo: any): Promise<void> {
    const { PYT_IV, PYT_VALUE} = environment;
    const user = this.storage.getUser();
    const { id } = user;
    const url = `${TOKENIZATION}/${id.replace(/-/g, '')}`;
    const card = await this.buildNewCard(cardInfo, user);
    const secret = enc.Utf8.parse(this.security.decrypt(PYT_VALUE,1));
    const iv = enc.Utf8.parse(this.security.decrypt(PYT_IV,1));
    const dataEncrypted = AES.encrypt(JSON.stringify(card), secret, { iv });
     try {
       await this.httpClient.post(url, {data: dataEncrypted.toString()}).toPromise().finally(() => {
       });
     } catch (e) {
       this.segmentanalytics.eventTrack(EVENT_TRACK_129, {journey: 'ppv', error_id: e?.error?.errors[0]?.code, error_message: e?.error?.errors[0]?.description,
         error_type: e?.error?.errors[0]?.userMessage});
     }
  }

  private async buildNewCard(cardInfo: any, user: any): Promise<any> {

    const defaultConfiguration = await this.settingsService.getDefaultConfiguration();
    let deviceId = await this.deviceHelper.getUUID().then();
    deviceId.replace(/-/g, '');
    let card = {
      accountType: (defaultConfiguration ? defaultConfiguration.accountType : ''),
      accountNumber: (user ? (user.id).replace(/-/g, '') : ''),
      deviceId,
      applicationName: (defaultConfiguration ? defaultConfiguration.applicationName : ''),
      creditCardDetails: this.defineCreditCardDetails(cardInfo),
    };
    card = this.defineBillToAddress(card, cardInfo, defaultConfiguration);
    card = await this.defineBillingUser(card,user,cardInfo);
    return card;
  }

  defineBillToAddress(card: any, cardInfo: any, configuration: any): any {

    const { billingForm,registrationEmail } = cardInfo;
      const user = this.storage.getUser();

      const { email } = user;
      // let md5 = new Md5();
      // md5.appendStr('somestring')
      // .appendAsciiStr('a different string')

      // md5.end();
      // console.log(email,"correo sin md5");
      // console.log(md5,"mi correo con md5");
      // console.log(Md5.hashStr(email),"other try");
      // console.log(Md5.hashAsciiStr(email),"prueba");

  // Generate the MD5 hex string
  // md5.end();
  let mail = registrationEmail ? registrationEmail : email;
      if (billingForm) {
        const isEmpty = Object.keys(billingForm).length === 0;
        if (!isEmpty) {
          card.billToAddress = { ...billingForm, email:mail };
          if(billingForm.documentNumber || billingForm.documentNumber == ""){
            card.documentNumber = card.documentNumber ? card.documentNumber : "CF"
          }
        }
      }

    return card;
  }


  async defineBillingUser(card:any,user:any,cardInfo:any){

    const { billingForm,registrationEmail } = cardInfo;
    const currentLocation: any = await this.locationService.getLocation();
      const {given_name,family_name,phone_number,email} = user
      let mail = registrationEmail ? registrationEmail : email;
      if(card.billToAddress){
      card.billingUser = {
        "firstName": given_name ? given_name : 'APPROVED',
        "lastName": family_name ? family_name : 'APPROVED',
        "phoneNumber": phone_number ? phone_number : '',
        "email": mail,
        "country": currentLocation.country
      }
      }
      return card;
  }

  defineCreditCardDetails(cardInfo: any): any {
    const card = {
      accountNumber: (cardInfo.accountNumber).replace(/\s/g, ''),
      expirationMonth: moment(cardInfo.cardExpiration, 'MM/YY').format('MM'),
      expirationYear: moment(cardInfo.cardExpiration, 'MM/YY').format('YYYY'),
      cardType: cardInfo.type,
      cvv: (cardInfo.cvv).toString()
    };
    return card;
  }

}
