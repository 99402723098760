<div class="contenedor">
  <app-custom-keyboard (sendText)="textFromCustomKeyboard($event)" [finderFlagParent]="finderFlag"></app-custom-keyboard>
    <ion-col class="tv-toolbar-container">
      <ion-row size="9" class="p-0 tv-toolbar-options">
        <ion-item>
          <ion-input type="text" aria-label="Search" name="txtSearch" id="txtSearch" (ionChange)="ngOnChange($event)" debounce="400" [disabled]="true" ></ion-input>
        </ion-item>
      </ion-row>
      <ion-row size="9" class="p-0 tv-toolbar-options">
        <h2>Búsquedas principales</h2>
        <div class="gallery tv-row">
          <div class="finder__Item tv-control" *ngFor="let item of data" (click)="viewDetail(item.id, item.contentType)" [id]="'finderItemsTV-' + item.id" tabindex="150">
            <ion-img src={{item.imgUrl}} alt="1"></ion-img>
            <img class="icoPlayInternal" src="assets/graphics/options/buttons/ico_play_internal.svg" alt="">
            <div class="tagLive" *ngIf="item.live">
              <img src="assets/graphics/options/buttons/ico_record.svg" alt="2">
              <ion-label>En Vivo</ion-label>
            </div>
            <div class="finder__Info">
              <ion-label class="infoTitle">{{item.title}}</ion-label>
              <ion-label class="infoDisabled">{{item.date}} {{item.schedule}}</ion-label>
            </div>
          </div>
        </div>
      </ion-row>
    </ion-col>
</div>
