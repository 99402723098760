<div class="d-lg-none">
  <ion-header>
    <ion-toolbar [ngClass]="{'native-top': native && !ios,'no-native' : !native,'toolbar-ios': ios}">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-auto align-self-center cursor-toolbar">
         <!-- <img src="assets/graphics/app/logo_tigo-sport_inline.svg" width="101.11" height="25.4" routerLink="/inicio"> -->
          <img [src]="initLogoTigoSports" width="160" height="40" routerLink="/inicio">
        </div>
        <div class="col-auto align-self-center">
          <div class="row">
            <div class="col-auto toolbar-option" style="display: none;">
              <ion-button expand="full" class="m-0 p-0" color="tertiary">
                <ion-icon src="assets/graphics/options/toolbar/ico_cast.svg"></ion-icon>
              </ion-button>
            </div>

           <!-- <div *ngIf="seechromecast"  id="chmd" class="col-auto toolbar-option" > -->
             <!-- <ion-button expand="full" class="m-0 p-0" color="tertiary" (click)="CaseChromecast()"> -->
             <!-- <ion-button expand="full" class="m-0 p-0" (click)="CaseChromecast()"> -->
               <!-- <ion-icon  src="assets/graphics/chromecast/ico_castOFF.svg"></ion-icon> -->
               <!-- <ion-icon id="iconcom" src="{{iconchromecastoff}}" ></ion-icon> -->
             <!-- </ion-button> -->
            <!-- </div> -->

            <div class="col-auto toolbar-option">

             <!-- <ion-button expand="full" class="m-0 p-0" color="tertiary" routerLink="/ajustes" (click)="goToSettings()"> -->
              <ion-button expand="full" class="m-0 p-0" routerLink="/ajustes" (click)="goToSettings()">
                <ion-icon src="assets/graphics/options/toolbar/ico_settings.svg" [ngClass]="getRoute() ? 'settings': ''"></ion-icon>
              </ion-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-toolbar>
  </ion-header>
</div>
