import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  REFRESH_TOKEN,
  USER_LOGIN,
  REMOVE_SESSION,
  REFRESH_TOKEN_OTT,
  REFRESH_CODE,
  REFRESH_TOKEN_OTT_AUTH0,
  REFRESH_TOKEN_OTT_HE, LOGOUT_SESSION_HE
} from '../config/endpoints';
import {Response} from '../core/interfaces/response';
import {Storage} from '../core/storage/storage';
import {Auth} from '../core/interfaces/auth';
import {JwtHelperService} from '@auth0/angular-jwt';
import {User} from '../core/interfaces/user';
import {AuthRequest} from '../core/interfaces/auth-request';
import {
  AUTHENTICATION_METHOD,
  HOME_AUTH_TYPE,
  HOME_BUSINESS_CATEGORY, HOME_ENTITLEMENT_CATEGORY, HOME_TYPE_LOGIN,
  MOBILE_AUTH_TYPE,
  MOBILE_BUSINESS_CATEGORY, MOBILE_ENTITLEMENT_CATEGORY, MOBILE_TYPE_LOGIN,
  OTHER_AUTH_TYPE,
  OTHER_BUSINESS_CATEGORY, OTHER_ENTITLEMENT_CATEGORY, OTHER_TYPE_LOGIN
} from '../config/analytics/properties';
import {SegmentAnalytics} from '../core/providers/segment-analytics';
import {EVENT_TRACK_012, EVENT_TRACK_013, EVENT_TRACK_015, EVENT_TRACK_016} from '../config/analytics/events-track';
import {UserService} from './user.service';
import {Location} from '../core/interfaces/location';
import {EntitlementService} from './entitlement.service';
import {Capacitor} from '@capacitor/core';
import {ConfigurationsService} from './configurations.service';
import {DevicesService} from './devices.service';
import { MiddlewareService } from './middleware.service';
import { NotificationsService } from './notifications.service';
import { UpdateService } from './update.service';
import { ScriptRegisterUtil } from '../core/utils/script-register.util';
import { LocationService } from './location.service';
import { TextManagementService } from './text-management.service';
import {SplashScreen} from '@capacitor/splash-screen';
import {StatusBar} from '@capacitor/status-bar';
import {ANDROID_PLATFORM_STATUS_BAR_COLOR, IS_ADAPTABLE} from 'src/app/config/constants';
import { Platform } from '@ionic/angular';
import { BROWSER } from '../config/constants';
import { InAppBrowser,InAppBrowserObject } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { NavController } from '@ionic/angular';
import { LoadingService } from './loading.service';
import { Router } from '@angular/router';
import { DeviceHelper } from '../core/helpers/device.helper';
import {AuthOption} from '../core/interfaces/auth-option';
import {SUBSCRIPTION_CONECTION, SUBSCRIPTION_SESION} from '../queries/query';
import {Apollo} from 'apollo-angular';
import {Broadcaster} from '@awesome-cordova-plugins/broadcaster/ngx';
import {HttpLink} from 'apollo-angular/http';
import {createApollo} from '../graphql.module';
import {environment} from '../../environments/environment';
import { TextManagementV2Service } from './text-management-v2.service';
import {Device} from '@capacitor/device';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {PlayerService} from './player.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private  LOGIN_OPTIONS = 'ott_config_login_';
  private  WHITELIST_MOVIL = 'whitelist_countries_movil';
  private  WHITELIST_HOME = 'whitelist_countries_home';
  private  WHITELIST_OTHER = 'whitelist_countries_ppv';
  private  WHITELIST_PROVIDER = 'whitelist_countries_providers';
  private  WHITELIST_SUSCRIPTION = 'whitelist_countries_optionsuscription';
  private  WHITELIST_CONFIGPAYMENT = 'store_config_payment_whitelist';
  private isWhitelistMovil = false;
  private isWhitelistHome = false;
  private isWhitelistOther = false;
  private isWhitelistProvider = false;
  private isWhitelistSuscription = false;
  private observableConnect = null;
  private observableSession = null;
  private onUserConcurrent: any;
  private appNumberVersion: string = '';


  authRequest: AuthRequest;
  type: string;
  private valueenrollment = '';

  constructor(
    private httpClient: HttpClient,
    private storage: Storage,
    private jwtHelper: JwtHelperService,
    private segmentAnalytics: SegmentAnalytics,
    private userService: UserService,
    private entitlementService: EntitlementService,
    private configurationsService: ConfigurationsService,
    private devicesService: DevicesService,
    private middlewareService: MiddlewareService,
    private notificationsService: NotificationsService,
    private updateService: UpdateService,
    private scriptRegister: ScriptRegisterUtil,
    private locationService: LocationService,
    private textManagementService: TextManagementService,
    private textManagementV2Service: TextManagementV2Service,
    private platform: Platform,
    private iab: InAppBrowser,
    private navController: NavController,
    private loadService: LoadingService,
    private route: Router,
    private deviceHelper: DeviceHelper,
    private apollo: Apollo,
    private broadCasterNative: Broadcaster,
    private httpLink: HttpLink,
    private appVersion: AppVersion,
    private playerService: PlayerService
  ) {
    const bc = new BroadcastChannel('eventRegreshTokenStart');
    bc.onmessage = (messageEvent) => {
      let isRefresh = null;
      if(this.storage.getUserCarrierBilling()){
        isRefresh = this.storage.getRefreshToken();
      }else{
        isRefresh = this.storage.getRefreshTokenTigoId();
      }
      if (isRefresh) {
        if (Capacitor.getPlatform() === 'web' && !Capacitor.isNativePlatform()) {
          if (!document.hidden) {
            this.refreshAuthentication().then(()=>{
              this.subscription().then(()=>{
                const bcFinish = new BroadcastChannel('eventRegreshTokenFinish');
                bcFinish.postMessage('START');
              });
            });
          }
        } else {
          this.refreshAuthentication().then(()=>{
            this.subscription().then(()=>{
              const bcFinish = new BroadcastChannel('eventRegreshTokenFinish');
              bcFinish.postMessage('START');
            });
          });
        }
      } else {
        this.observableConnect?.unsubscribe();
        this.observableSession?.unsubscribe();
      }
    };
    const bc2 = new BroadcastChannel('eventSubscription');
    bc2.onmessage = (messageEvent) => {
      this.subscription(true).then();
    };

    if (Capacitor.isNativePlatform()) {
      this.broadCasterNative.addEventListener('eventGetConfigSocket').subscribe((event) => {
        this.sendConfigSocket();
      });
      this.broadCasterNative.addEventListener('exitPlayerClosedSession').subscribe((event)=> {
        this.isValidateUserNative().then();
        const router = IS_ADAPTABLE ? '/-/iniciar-sesion': '/-/iniciar-sesion-tv';
        this.observableConnect?.unsubscribe();
        this.observableSession?.unsubscribe();
        this.resetValuesEventsSuscription();
        if (this.storage.getVecimaSession() || this.playerService.vecimaSessionId) {
          this.closeSesionIdVecima().then((statusCode) => {
            if (statusCode === 200) {
              //this.removeAuthenticationRefresh(IS_ADAPTABLE ? '/-/iniciar-sesion': '/-/iniciar-sesion-tv').then();
              this.removeAuthenticationEventsSuscription(true).then(() => {
                this.navController.navigateRoot(router);
              });
            } else {
              if (!IS_ADAPTABLE) {
                this.removeAuthenticationEventsSuscription(true).then(() => {
                  this.navController.navigateRoot(router);
                });
              }
            }
          });
        } else {
          this.removeAuthenticationEventsSuscription(true).then(() => {
            this.navController.navigateRoot(router);
          });
        }
      });
    }
   this.setAppVersion().then();
  }

  public headerEncode = (obj: any) => btoa(JSON.stringify(obj));

  public sendConfigSocket = () => {
    const uri = environment.APP_SYNC_PATH; // <-- add the URL of the GraphQL server here
    const uriWs = environment.APP_SYNC_PATH_WS; // <-- add the URL of the GraphQL server here WS
    const token = this.storage.getMiddlewareAuthorization();
    const apiHeader = {
      host: uri.replace('https://','').replace('/graphql',''),
      Authorization: `Bearer ${token}`,
    };
    const item = {
      url: uriWs + '?header=' + this.headerEncode(apiHeader) + '&payload=' + this.headerEncode({}),
      host: uri.replace('https://','').replace('/graphql',''),
      authorization: token
    };
    this.broadCasterNative.fireNativeEvent('eventSetConfigSocket', false, item).then();
  };

  /* Get login options from middleware */
  public async getLoginOptions(loginType = 'cognito'): Promise<any> {
    let options = [];
    const optionsprovider = [];
    const optionssuscription = [];
    const optionCreateAccount = [];
    const data_request = await this.configurationsService.getConfigurationByVariable(this.LOGIN_OPTIONS+loginType);
    await this.setAllowedRegion();
    if(!Capacitor.isNativePlatform()){
      this.LoginOptionsByPlatform('web',data_request,options, optionsprovider, optionssuscription, optionCreateAccount);
    }else if(this.platform.platforms().indexOf('android') >= 0){
      this.LoginOptionsByPlatform('android',data_request,options, optionsprovider, optionssuscription, optionCreateAccount);
    }else if(this.platform.platforms().indexOf('ios') >= 0 ){
      this.LoginOptionsByPlatform('ios',data_request,options, optionsprovider, optionssuscription, optionCreateAccount);
    }

    /* unitLogin Auth0*/
    const unitOptions: any = [];
    if (options.length > 0) {
      const findUnitLogin: any = options.find((E: any) => E.hasOwnProperty('unifyLogin'));
      if (findUnitLogin && findUnitLogin?.unifyLogin) {
        unitOptions.push(findUnitLogin);
        options = unitOptions;
      }
    }

    return {options, optionsprovider, optionssuscription, optionCreateAccount};

  }
  private async setAllowedRegion(){
    const list_movil = await this.configurationsService.getConfigurationByVariable(this.WHITELIST_MOVIL); //allowed regions for mobile option
    const list_home = await this.configurationsService.getConfigurationByVariable(this.WHITELIST_HOME); //allowed regions for home option
    const list_other = await this.configurationsService.getConfigurationByVariable(this.WHITELIST_OTHER); //allowed regions for other option
    const list_provider = await this.configurationsService.getConfigurationByVariable(this.WHITELIST_PROVIDER); //allowed regions for provider login
    const list_optionsuscription = await this.configurationsService.getConfigurationByVariable(this.WHITELIST_SUSCRIPTION); //allowed regions for login suscription

    const currentLocation: any = await this.locationService.getLocation();

    const currentCountry = currentLocation?.country;
    const currentRegion = currentLocation?.region;
    //const body = await this.getConfigPaymentWhitelist();
    this.isWhitelistMovil =  list_movil[currentCountry] ? list_movil[currentCountry].includes(currentRegion) : false; //the region is allowed for movil option
    this.isWhitelistHome = list_home[currentCountry] ? list_home[currentCountry].includes(currentRegion) : false;  //the regions is allowed for home option
    this.isWhitelistOther = list_other[currentCountry] ? list_other[currentCountry].includes(currentRegion) : false; //the region is allowed for other option
    this.isWhitelistProvider = list_provider[currentCountry] ? list_provider[currentCountry].includes(currentRegion) : false; //the region is allowed for provider login
    this.isWhitelistSuscription = list_optionsuscription[currentCountry] ? list_optionsuscription[currentCountry].includes(currentRegion) : false; //the region is allowed for suscription login
    //await this.TurnoffPayment(body, currentCountry); // the region is allowed for turn off suscription
   }


    // set value for paymentOptions
   public async setvalueForMethodPayment() {
    try {
      const currentLocation: any = await this.locationService.getLocation();
      const currentCountry = currentLocation?.country;
      const body = await this.getConfigPaymentWhitelist();
      await this.TurnoffPayment(body, currentCountry);
    } catch (e)  {
    }
   }


    // set login options depending on the platform
    LoginOptionsByPlatform(platform_name: string,data_request: any,options: any, optionsprovider: any, optionssuscription: any, optionCreateAccount: any){
      for (const option of data_request){
        if(option[platform_name]){
            if(option.type === 'mobile' && this.isWhitelistMovil){
              options.push(option);
            }else if(option.type === 'home' && this.isWhitelistHome){
              options.push(option);
            }else if(option.type === 'other' && this.isWhitelistOther){
              options.push(option);
            } else if (option.type === 'provider' && this.isWhitelistProvider) {
              optionsprovider.push(option);
            } else if (option.type === 'suscription' && this.isWhitelistSuscription) {
              optionssuscription.push(option);
            } else if (option.type === 'createAccount' && this.isWhitelistHome) {
              optionCreateAccount.push(option);
            }
        }
      }
   }



  /* Get auth type from storage */
  public getAuthType(): string {
    return this.storage.getAuthType();
  }

  /* Get auth type from storage */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  getOptionLogin(): AuthOption {
    return this.storage.getOptionLogin();
  }

  /* Set auth type from storage */
  public setAuthType(type: string): void {
    this.storage.setAuthType(type);
  }

  /* Validate user login */
  public async validateUser(body: AuthRequest, type: string): Promise<boolean> {
    let status = false;

    try {
      const response: Response = await this.httpClient.post<Response>(USER_LOGIN, body).toPromise();

      if (response.code !== 401) {
        status = true;
        this.saveBody(body, type);
      }
    } catch (error) {}

    return status;
  }

  /* Validate user code login */
  public async validateCode(code: string): Promise<boolean> {
    let status = false;

    try {
      const existingBody: AuthRequest = this.getBody();
      if (existingBody) {
        existingBody.code = code;
        const response: Response = await this.httpClient.post<Response>(USER_LOGIN, existingBody).toPromise();
        status = response.success;

        if (status) {
          const auth: Auth = response.body;
          this.storage.setAuthToken(auth.IdToken);
          this.storage.setAccessToken(auth.AccessToken);
          let user: User = this.jwtHelper.decodeToken(auth.IdToken);
          user = await this.defineUserProperties(user);
          await this.storage.setUser(user);
          // await this.devicesService.addUserSession();
          // await this.entitlementService.setEntitlement(user);
          await this.notificationsService.initPush();
          this.removeBody();
        }
      }
    } catch (error) {}

    return status;
  }

  /* Set authentication data in storage and starts braze service */
  public async setAuthentication(auth: any, type: string,isWebView?: boolean,route?: string, isRefreshing?: boolean): Promise<void> {
    try {
      //  this.loadService.setLoading(true,'setuser');
      if(auth?.device?.systemInfo){
        const deviceData = {
          systemInfo: auth.device.systemInfo,
          country:auth.device.country,
          deviceId:auth.device.deviceId
        };
        this.storage.setPlatformInfo(deviceData);
      }
      this.storage.setAuthToken(auth.accessToken);
      this.storage.setAccessToken(auth.accessToken);
      this.storage.setRefreshToken(auth.refreshToken);
      if(auth?.data?.IdToken) {
        if (auth?.data?.option_login) {
          const authOption: AuthOption = auth?.data?.option_login;
          this.storage.setOptionLogin(authOption);
          const isAuth0 = Boolean(this.storage.getOptionLogin()?.webviewUrlAuth0);
        }

        this.storage.setTokenTigoId(auth.data.IdToken);
        this.storage.setAccessTigoId(auth.data.AccessToken);
        this.storage.setRefreshTokenTigoId(auth?.data?.refresh_token_tigo_id ? auth.data.refresh_token_tigo_id : auth?.data?.RefreshToken);
      }

      // this.storage.setTokenExpires(auth.expires_in ? (Date.parse(Date()) + parseInt(auth.expires_in)).toString() : (Date.parse(Date()) + 3600000).toString());
      this.storage.setAuthType(type);
      let user = this.jwtHelper.decodeToken(auth.accessToken);
      user = await this.defineUserProperties(user);
      this.storage.setUser(user);
      this.storage.setMiddlewareAuthorization(auth.accessToken);
      //await this.entitlementService.getEntitlement();
      if(isWebView && route){
        this.navController.navigateForward(route+'&status=webview');
        return;
      }
      await this.notificationsService.initPush();
      //await this.devicesService.addUserSession();
      // this.loadService.setLoading(false,'setuser');
      this.subscription().then();
    } catch (e) {
      const objError = {
        journey: 'login authS TigoId',
        error_id: e?.status ? e?.status : '',
        error_message: e?.message ? e?.message : '',
        error_type: e?.error?.msg ? e?.error?.msg : ''
      };
      this.segmentAnalytics.eventTrack(EVENT_TRACK_013, objError).then();
    }
  }

  public async setAuthPersonal(auth,isRefreshing?: boolean) {
    try {
      if(auth?.deviceUser?.data?.systemInfo){
        const deviceData = {
          systemInfo: auth?.deviceUser?.data?.systemInfo,
          country:auth?.deviceUser?.data?.country,
          deviceId:auth?.deviceUser?.data?.deviceId
        };
        this.storage.setPlatformInfo(deviceData);
      }
      this.storage.setAuthToken(auth.accessToken);
      this.storage.setAccessToken(auth.accessToken);
      this.storage.setRefreshToken(auth.refreshToken);
      this.storage.setMiddlewareAuthorization(auth.accessToken);
      const user: any = this.jwtHelper.decodeToken(auth.accessToken);
      const data = {
        id: user.uuid,
        suscriber_id: user.accountNumber,
        token: user.token,
        entitlement: user.entitlement,
        login_type: user?.bussinesUnit, //'personal',
        authentication_method: 'toolbox',
        authentication_type: user?.platformH, //'phone',
        authentication_provider: user?.bussinesUnit, //'personal',
        business_category: user?.bussinesUnit, //'personal',
        exp: user.exp
      };
      this.storage.setUserCarrierBilling(data);
      //await this.devicesService.addUserSession();
      this.subscription().then();
    } catch (e) {
      const objError = {
        journey: 'login authS Provider',
        error_id: e?.status ? e?.status : '',
        error_message: e?.message ? e?.message : '',
        error_type: e?.error?.msg ? e?.error?.msg : ''
      };
      this.segmentAnalytics.eventTrack(EVENT_TRACK_013, objError).then();
    }
  }

  //methood to refresh token for tigo ID
  public refreshTokenTigoID(){
    const deviceInfo: any = this.storage.getPlatformInfo();
    deviceInfo.refreshToken = this.storage.getRefreshTokenTigoId();
    deviceInfo.idToken = this.storage.getTokenTigoId();
    deviceInfo.versionApp = Capacitor.getPlatform() === 'web' ? environment.WEB_VERSION : this.appNumberVersion;
    const isAuth0 = Boolean(this.storage.getOptionLogin()?.webviewUrlAuth0);
    const isHEPhone = Boolean(this.storage.getOptionLogin()?.webviewUrlHE);
    return this.httpClient.post((isAuth0 ? REFRESH_TOKEN_OTT_AUTH0 : isHEPhone ? REFRESH_TOKEN_OTT_HE : REFRESH_TOKEN_OTT), deviceInfo);
  }

  //methood to refresh token for carrier billing
  public refreshCarrierBilling(){
    const deviceInfo = this.storage.getPlatformInfo();
    delete deviceInfo.deviceId;
    deviceInfo.refreshAccessToken = this.storage.getRefreshToken();
    deviceInfo.versionApp = Capacitor.getPlatform() === 'web' ? environment.WEB_VERSION : this.appNumberVersion;
    return this.httpClient
    .post(REFRESH_CODE, deviceInfo);
  }

  public async refreshAuthentication(code?,route?) {
    try{
      const deviceInfo = await this.devicesService.getDeviceData();
      delete deviceInfo.deviceId;
      deviceInfo.refreshAccessToken = this.storage.getRefreshToken();
      deviceInfo.versionApp = Capacitor.getPlatform() === 'web' ? environment.WEB_VERSION : await this.appVersion.getVersionNumber();
      const type = this.getAuthType();
      //if refresh token expires



      this.loadService.setLoading(true,'key-refresh');
      if(this.storage.getUserCarrierBilling()){
        deviceInfo.refreshAccessToken = this.storage.getRefreshToken();
        const refreshResult: any = await this.httpClient
        .post(REFRESH_CODE, deviceInfo)
        .toPromise().catch((err) => {
            const objError = {
              journey: 'login refreshToken Provider',
              error_id: err?.status ? err?.status : '',
              error_message: err?.message ? err?.message : '',
              error_type: err?.error?.msg ? err?.error?.msg : ''
            };
            this.segmentAnalytics.eventTrack(EVENT_TRACK_013, objError).then();
          });
        await this.removeAuthenticationRefresh();
        await this.setAuthPersonal(refreshResult,code === 403);

      }else{
        deviceInfo.refreshToken = this.storage.getRefreshTokenTigoId();
        deviceInfo.idToken = this.storage.getTokenTigoId();
        deviceInfo.deviceId = await this.deviceHelper.getUUID().then();
        const isAuth0 = Boolean(this.storage.getOptionLogin()?.webviewUrlAuth0);
        const isHEPhone = Boolean(this.storage.getOptionLogin()?.webviewUrlHE);
        const refreshResult: any = await this.httpClient
          .post((isAuth0 ? REFRESH_TOKEN_OTT_AUTH0 : isHEPhone ? REFRESH_TOKEN_OTT_HE : REFRESH_TOKEN_OTT), deviceInfo)
          .toPromise().catch((err) => {
            const objError = {
              journey: 'login refreshToken tigoId',
              error_id: err?.status ? err?.status : '',
              error_message: err?.message ? err?.message : '',
              error_type: err?.error?.msg ? err?.error?.msg : ''
            };
            this.segmentAnalytics.eventTrack(EVENT_TRACK_013, objError).then();
          });
        await this.removeAuthenticationRefresh();
        await this.setAuthentication(refreshResult,type,false,route,code === 403);
      }

      this.loadService.setLoading(false,'key-refresh');
     }catch(e){
      //if endpoint fails
      //console.log(e);
      this.loadService.setLoading(false,'key-refresh');
      const route = IS_ADAPTABLE ? '/-/iniciar-sesion': '/-/iniciar-sesion-tv';
      await this.removeAuthenticationRefresh(route);
     }

  }

  /* Track Firebase Refresh event Failed */
  public trackSendEventFail(err) {
    const objError = {
      journey: 'login refreshToken interceptor',
      error_id: err?.status ? err?.status : '',
      error_message: err?.error?.data?.error_description ? err?.error?.data?.error_description : '',
      error_type: err?.error?.data?.error ? err?.error?.data?.error : ''
    };
    this.segmentAnalytics.eventTrack(EVENT_TRACK_013, objError).then();
  }

  /* Build user properties object for analytics */
  private async defineUserProperties(user, type?: string): Promise<User> {
    user.id = user.uniqueIdentifier ? user.uniqueIdentifier : user.uuid;
    user.phone_number = user.phone_number ? user.phone_number : (user.phone ? user.phone : '');
    user.username = user.username ? user.username : (user.user_model ? user.user_model.username : '');
    user.mobile_contract_id = user.mobile_contract_id ? user.mobile_contract_id : '';
    user.home_contract_id = user.home_contract_id ? user.home_contract_id : '';
    user.national_id =  user?.planType?.nationalId  ? user?.planType?.nationalId : '';
    user.national_id_type = user?.userInfo?.nationalIdType ? user?.userInfo?.nationalIdType : '';
    user.plan_id = user?.userInfo?.planId ? user.userInfo.planId : '';
    user.plan_name =  user?.userInfo?.planName ? user?.userInfo?.planName : '';
    user.plan_type = user?.userInfo?.planType ? user?.userInfo?.planType : '';
    user.registration_date = user?.userInfo?.registrationDate ? user?.userInfo?.registrationDate : '';
    user.stored_cards = user.stored_cards ? user.stored_cards : 0;
    user.authentication_method = AUTHENTICATION_METHOD;
    user.msisdns = user.userInfo.linkedMsisdns ? user.userInfo.linkedMsisdns  : '';
    user.family_name = user.tokenTigoId.fName;
    user.given_name = user.tokenTigoId.gName;
    user.value_entetliment = this.valueenrollment;

     //TODO consultar si  hay que realizar cambio
    if (user.bussinesUnit === 'mobile') {
      user.authentication_type = MOBILE_AUTH_TYPE;
      user.business_category = MOBILE_BUSINESS_CATEGORY;
      user.entitlement_category = MOBILE_ENTITLEMENT_CATEGORY;
      user.login_type = MOBILE_TYPE_LOGIN;
    } else if (user.bussinesUnit === 'home') {
      user.authentication_type = HOME_AUTH_TYPE;
      user.business_category = HOME_BUSINESS_CATEGORY;
      user.entitlement_category = HOME_ENTITLEMENT_CATEGORY;
      user.login_type = HOME_TYPE_LOGIN;
    } else if (user.bussinesUnit === 'other') {
      user.authentication_type = OTHER_AUTH_TYPE;
      user.business_category = OTHER_BUSINESS_CATEGORY;
      user.entitlement_category = OTHER_ENTITLEMENT_CATEGORY;
      user.login_type = OTHER_TYPE_LOGIN;
    }

    return user;
  }

  /* Save body response locally */
  private saveBody(body: AuthRequest, type): void {
    this.authRequest = body;
    this.type = type;
  }

  /* Get body response */
  private getBody(): AuthRequest {
    return this.authRequest;
  }

  /* Remove body response */
  private removeBody(): void {
    this.authRequest = null;
    this.type = null;
  }
  private async removeSessionPersonal(id: string){
    const headers: any = {
      'Content-Type': 'application/json',
    };
    const url = `${REMOVE_SESSION}`;
    const response: any = await this.httpClient.get(url, { headers }).toPromise();
  }

  /* Remove authentication data from storage */
  public async removeAuthentication(deletePayment?: boolean): Promise<void> {

    try {
    //remove auth personal and HE
     this.loadService.setLoading(true, 'action-logout');
     const method = this.storage.getUserCarrierBilling() ? this.storage.getUserCarrierBilling()?.authentication_method : null;
     const isHEPhone = Boolean(this.storage.getOptionLogin()?.webviewUrlHE);
   if (method === 'toolbox') {
     const token = this.storage.getUserCarrierBilling().token;
     await this.httpClient.delete(`${REMOVE_SESSION}/${token}`).toPromise();
   } else if (isHEPhone) {
     const refreshToken = this.storage.getRefreshTokenTigoId();
     const accessID = this.storage.getTokenTigoId();
     const platform = Capacitor.getPlatform() === 'web' ? Capacitor.getPlatform() : 'movil';
     await this.httpClient.delete(`${LOGOUT_SESSION_HE}/${platform}`, {body: {refreshToken, accessID}}).toPromise();
    }

     //remove actual device session
      await this.segmentAnalytics.eventTrack(EVENT_TRACK_015, {journey: 'login', logout_method: 'logout clicked'});
      await this.devicesService.deleteActualUserSessionDevice();
      this.loadService.setLoading(false, 'action-logout');

      //remove all user information+
      this.storage.removeAccessToken();
      this.storage.removeAuthToken();
      this.storage.removeRefreshToken();
      this.storage.removeTokenExpires();
      this.storage.removeRefreshTokenTigoId();
      this.storage.removeTokenTigoId();
      this.storage.removeUser();
      this.storage.removeUserPersonal();
      this.storage.removeEntitlement();
      this.storage.removeTokenPersonal();
      this.storage.removeMiddlewareAuthorization();
      this.storage.removeAccountMasterAppSync();
      this.storage.removeStatusInitChromecast();
      if (deletePayment !== true) {
        this.storage.RemovePvv();
        this.storage.RemoveSuscription();
      }
      this.storage.RemoveObjPayment();
      this.storage.removePlatformInfo();
      if (this.storage.getPayloadPaypal()) {
        this.storage.removePayloadPaypal();
      }
      if (this.storage.getDeviceNative()) {
        this.storage.removeDeviceNative();
      }
      if (this.storage.getIsModalAuth0()) {
        this.storage.removeModalAuth0();
      }
    } catch (error) {
      this.loadService.setLoading(false, 'action-logout');
      await this.segmentAnalytics.eventTrack(EVENT_TRACK_016, {
        error_id: error.name,
        error_message: error.message,
        journey: 'login',
        error_type: error.name
      });
    }
  }


    /* Remove for refrehs data from storage */
    public async removeAuthenticationRefresh(route?): Promise<void> {
      try {
        this.storage.removeAccessToken();
        this.storage.removeAuthToken();
        this.storage.removeRefreshToken();
        this.storage.removeTokenExpires();
        //this.storage.removeRefreshTokenTigoId();
        this.storage.removeTokenTigoId();
        this.storage.removeUser();
        this.storage.removeUserPersonal();
        this.storage.removeEntitlement();
        this.storage.removeTokenPersonal();
        this.storage.removeMiddlewareAuthorization();
        this.storage.removeAccountMasterAppSync();
        if(route){
          this.navController.navigateRoot(route);
        }

      } catch (error) {
      }
    }


    public async removeAuthenticationEventsSuscription(deletePayment) {
      try {
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_015, {journey: 'login', logout_method: 'logout clicked'});
      }catch (error) {
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_016, {
          error_id: error.name,
          error_message: error.message,
          journey: 'login',
          error_type: error.name
        });
      }

      //remove auth personal and HE
      try{
        const method = this.storage.getUserCarrierBilling() ? this.storage.getUserCarrierBilling()?.authentication_method : null;
        const isHEPhone = Boolean(this.storage.getOptionLogin()?.webviewUrlHE);
        if(method === 'toolbox') {
          const token = this.storage.getUserCarrierBilling().token;
          await this.httpClient.delete(`${REMOVE_SESSION}/${token}`).toPromise();
        } else if (isHEPhone) {
          const refreshToken = this.storage.getRefreshTokenTigoId();
          const accessID = this.storage.getTokenTigoId();
          if (refreshToken && accessID) {
            const platform = Capacitor.getPlatform() === 'web' ? Capacitor.getPlatform() : 'movil';
            await this.httpClient.delete(`${LOGOUT_SESSION_HE}/${platform}`, {body: {refreshToken, accessID}}).toPromise();
          }
        }
      } catch (error) {
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_016, {
          error_id: error.name,
          error_message: error.message,
          journey: 'login',
          error_type: error.name
        });
      }

      //remove all user information+
      this.storage.removeAccessToken();
      this.storage.removeAuthToken();
      this.storage.removeRefreshToken();
      this.storage.removeTokenExpires();
      this.storage.removeRefreshTokenTigoId();
      this.storage.removeTokenTigoId();
      this.storage.removeUser();
      this.storage.removeUserPersonal();
      this.storage.removeEntitlement();
      this.storage.removeTokenPersonal();
      this.storage.removeMiddlewareAuthorization();
      this.storage.removeAccountMasterAppSync();
      if (deletePayment !== true) {
        this.storage.RemovePvv();
        this.storage.RemoveSuscription();
      }
      this.storage.RemoveObjPayment();
      this.storage.removePlatformInfo();
      if (this.storage.getPayloadPaypal()) {
        this.storage.removePayloadPaypal();
      }
      if (this.storage.getDeviceNative()) {
        this.storage.removeDeviceNative();
      }
      if (this.storage.getIsModalAuth0()) {
        this.storage.removeModalAuth0();
      }
    }


    //init middle auth
    public async initializeMiddlewareAuthorization(): Promise<void> {
      try {
       // const authorization = await this.middlewareService.getAuthorization();

          await this.locationService.locate();
         // this.scriptRegister.register();
          this.segmentAnalytics.startTracking();
          await this.updateService.verifyAppUpdate();
          await this.textManagementService.getTexts();
          let conf: string = this.storage.getNotificationsFlag();
          if (conf === null || conf === '') {
            this.storage.setNotificationsFlag('true');
            conf = 'true';
          }
          if (conf === 'true') {
            await this.notificationsService.initPush();
          }

          if (Capacitor.getPlatform() !== 'web') {
            await SplashScreen.hide();
            await StatusBar.setBackgroundColor({color: ANDROID_PLATFORM_STATUS_BAR_COLOR});
          }

      } catch (err) {}
    }

    // get Store Config Payment Whitelist
  public async getConfigPaymentWhitelist() {
      return await this.configurationsService.getConfigurationByVariable(this.WHITELIST_CONFIGPAYMENT).catch((err) => {
        console.error(err);
      });
  }


    // get and set turnoffsuscription
  public async TurnoffPayment(bodycountry, country) {
      try {
         if (bodycountry[country]) {
           await this.textManagementService.setPaymentConfig();
           await this.textManagementService.setTurnoffsuscription(bodycountry[country].OnSucription.toString());
           await this.textManagementService.setTurnoffPvv(bodycountry[country].OnPVV.toString());
           await this.textManagementService.setObjpayment(bodycountry[country]);
         } else {
           await this.textManagementService.setTurnoffsuscription('false');
           await this.textManagementService.setTurnoffPvv('false');
         }
      } catch (e) {
      }
  }

  subscription = async (forze = false) => {
    //Add platform for jwt
    const deviceInf = await Device.getInfo();
    const osName = deviceInf.operatingSystem;
    let deviceTypeH = '';
    const actualPlatform = Capacitor.getPlatform();
    let platformH = 'androidTv';

    if (IS_ADAPTABLE) {
      platformH = actualPlatform;
      if (platformH === 'android') {
        platformH = 'androidMovil';
      }
    }

    if (osName === 'mac' || osName === 'ios') {
      deviceTypeH = 'ios';
    } else {
      deviceTypeH = osName;
    }
    this.storage.setPlatform(deviceTypeH+'||'+platformH);
    //Add platform for jwt







    // turn off / on user concurrent
    const textsV2 = await this.textManagementV2Service.getTexts();
    this.onUserConcurrent = textsV2?.USERCONCURRENTON;
    const token = this.storage.getMiddlewareAuthorization();
    if (this.observableConnect == null || forze) {
      if (token && this.onUserConcurrent?.status && this.onUserConcurrent?.status === true) {
        //this.apollo.removeClient();
        //this.apollo.create(createApollo(this.httpLink, this.storage));
        this.observableConnect?.unsubscribe();
        this.observableSession?.unsubscribe();
        this.observableConnect = this.apollo.subscribe<any>({ query: SUBSCRIPTION_CONECTION}).subscribe(({data}) => {
          console.log(data);
          if (this.route.url.includes('/reproductor/')) {
            window.history.back();
          }
          this.isValidateUserNative();
          if (this.storage.getVecimaSession() || this.playerService.vecimaSessionId) {
            this.isvalidateSessionIdVecimaNative();
          }
        });
        this.observableSession = this.apollo.subscribe<any>({ query: SUBSCRIPTION_SESION}).subscribe(({data}) => {
          this.isValidateUserNative();
          console.log(data);
          this.observableConnect?.unsubscribe();
          this.observableSession?.unsubscribe();
          const route = IS_ADAPTABLE ? '/-/iniciar-sesion': '/-/iniciar-sesion-tv';
          if (this.storage.getVecimaSession() || this.playerService.vecimaSessionId) {
            this.closeSesionIdVecima().then((statusCode) => {
              if (statusCode === 200 || this.playerService.getStatusCodeDeleteVecima() === 200) {
                this.resetValuesEventsSuscription();
                this.removeAuthenticationEventsSuscription(true).then(() => {
                  this.navController.navigateRoot(route);
                });
              }
            });
          } else {
            this.resetValuesEventsSuscription();
            this.removeAuthenticationEventsSuscription(true).then(() => {
              this.navController.navigateRoot(route);
            });
          }
        });
      } else {
        this.observableConnect?.unsubscribe();
        this.observableSession?.unsubscribe();
        const bcFinish = new BroadcastChannel('eventClosetSocket');
        bcFinish.postMessage('START');
      }
    }
  };

 async isValidateUserNative() {
    if (Capacitor.isNativePlatform() &&
      (this.platform.platforms().indexOf('android') >= 0 || this.platform.platforms().indexOf('ios') >= 0)) {
     await this.broadCasterNative.fireNativeEvent('exitPlayer', false,{})
        .then((res: any) => {
          console.log('brodcaste', res);
        }).catch((err) => {
      });
    }
  }

  isvalidateSessionIdVecimaNative() {
    if (Capacitor.isNativePlatform() &&
      (this.platform.platforms().indexOf('android') >= 0 || this.platform.platforms().indexOf('ios') >= 0)) {
      this.closeSesionIdVecima().then();
    }
  }

  async setAppVersion() {
   if (Capacitor.getPlatform() !== 'web') {
     this.appNumberVersion = await this.appVersion.getVersionNumber();
   }
 }

  public async closeSesionIdVecima(isRefresh? : boolean) {
    return await this.playerService.deleteSessionIdStreaming(isRefresh).catch(() => {});
  }

  private resetValuesEventsSuscription() {
    this.observableConnect = null;
    this.observableSession = null;
  }

}
