import {Injectable} from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import {from, Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Storage} from '../storage/storage';
import {COUNTRY_CODE_KEY} from '../../config/constants';
import {Location} from '../interfaces/location';
import { AuthService } from 'src/app/services/auth.service';
import { EventType, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import {IS_ADAPTABLE} from '../../config/constants';
import { BehaviorSubject, throwError } from 'rxjs';
import { filter,take } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/loading.service';
import {AES, enc} from 'crypto-ts';
import {SecurityHelper} from '../helpers/security.helper';
const requestForbiddenErrorMaxCount = 9;
const headerForbiddenCount = 'ts-forbbiden-count';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  cont = 0;
  isRefreshing  = false;
  isCarrierBilling = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private storage: Storage,
    private authService: AuthService,
    private route: Router,
    private navController: NavController,
    private loadingService: LoadingService,
    private security: SecurityHelper
  ) {
  }

  addHoursToDate(objDate, intHours) {
    const numberOfMlSeconds = objDate.getTime();
    const addMlSeconds = (intHours * 60) * 60000;
    return new Date(numberOfMlSeconds + addMlSeconds);
  }
  /* Add headers in all Http Requests */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const location: Location = this.storage.getLocation();
    const user = this.storage.getUser() ? this.storage.getUser() : this.storage.getUserCarrierBilling();
    const token = this.storage.getMiddlewareAuthorization();
    const isAuth0 = Boolean(this.storage.getOptionLogin()?.webviewUrlAuth0);
    const isHEPhone = Boolean(this.storage.getOptionLogin()?.webviewUrlHE);
    let platform = this.storage.getPlatform();
    if (platform) {
      const timestamp = Date.now();
      platform = platform+'||'+timestamp;
    }

    const { PYT_IV, PYT_VALUE} = environment;
    const secret = enc.Utf8.parse(this.security.decrypt(PYT_VALUE,1));
    const iv = enc.Utf8.parse(this.security.decrypt(PYT_IV,1));
    const dataEncrypted = AES.encrypt(JSON.stringify(platform ?? ''), secret, { iv });

    if (location) {
      request = request.clone({
        headers: request.headers
          .set('Country', location ? (location.originCode ?? '--') : '-')
          .set('Space', location.code)
          .set('logm', isAuth0 ? 'auth0' : isHEPhone ? 'HE' : 'ct')
          .set('user_uuid', user ? user.id: 'anonymous')
          .set('X-Aws-Server-Site-By', dataEncrypted ? dataEncrypted.toString() : '')
      });
    }

    if (token) {
        request = request.clone({
          headers: request.headers.set('Authorization', `Bearer ${token}`)
        });
    }

    if ((request.url).search(COUNTRY_CODE_KEY) !== -1) {
      request = request.clone({
        url: request.url.replace(COUNTRY_CODE_KEY, location.code),
      });
    }
    return next.handle(request)
    .pipe(catchError((err) => {
      //console.log('Handerl error interceptro', err);
      if (err.status === 0 && this.route.url !== '/-/red') {
        this.navController.navigateRoot('/-/red?buttom=true').then();
      }


      // validate list domains
      const urlVecima = this.storage.getTextsV2()?.data?.vecimaOptions?.url
      const parseUrl = new URL(err?.url);
      if (!parseUrl?.origin.includes(environment.MIDDLEWARE_PATH) && err.url !== environment.APP_SYNC_PATH) {
        if (urlVecima && urlVecima !== '') {
          if (!err.url.includes(urlVecima)) {
            err.status = 405;
          }
        }
      }

      //appSync error validation status = 401 UnAuth
      if (err.url && err.status && err.url === environment.APP_SYNC_PATH && err.status === 401){
       // err.error.code = 403;
        err.status = 403;
      }

      switch (err.status) {
        case 401:
          this.redirectToLogin();
          throw new HttpErrorResponse(err);
        case 403:
          return this.handle403Error(request, next);
        default:
          throw new HttpErrorResponse(err);
      }
    }))
    .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
      if(evt.type == HttpEventType.Response){
        //La sesión se ha cerrado y se debe redirigir al login
        if(evt?.body?.errors){
          evt.body.errors.forEach(error => {
            if(error?.message && error?.message.toLowerCase().includes('sesion cerrada')){
              const route = IS_ADAPTABLE ? '/-/iniciar-sesion': '/-/iniciar-sesion-tv';
              this.authService.removeAuthentication().then();
              this.authService.removeAuthenticationRefresh(route).then();
            }
          });
        }
      }

      return evt;
    }));


  }

  private async handleRefreshToken(request: HttpRequest<any>, next: HttpHandler, user: any) {
    const token = this.storage.getMiddlewareAuthorization();
    const domain = new URL(request.url);
    if (token) {
      const {MIDDLEWARE_PATH} = environment;
      if (domain && domain.origin === MIDDLEWARE_PATH) {
        request = request.clone({
          headers: request.headers.set('Authorization', `Bearer ${token}`)
        });
        if (!request.url.includes('refresh')){
          await this.validateRefresh(user,403,this.route.url);
        }
      }
    }
    return;
  }

  private validateForbiddenErroCount(request: HttpRequest<any>){
    const countForbidden = Number(request.headers.get(headerForbiddenCount));
    return countForbidden >= requestForbiddenErrorMaxCount;
  }

  private incrementForbiddenErrorCount(request: HttpRequest<any>){
    request = request.clone({
      headers: request.headers
        .set(headerForbiddenCount, (Number(request.headers.get(headerForbiddenCount))+1).toString())
    });

    return request;
  }

  //handle error for fresh token
  private  handle403Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.storage.getRefreshTokenTigoId() ? this.storage.getRefreshTokenTigoId() : this.storage.getRefreshToken();
      this.loadingService.setLoading(true,'refreshing');
      if (token){
        return this.decideRefrehMethod().pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.loadingService.setLoading(false,'refreshing');
            this.refreshTokenSubject.next(token.accessToken);
            this.storage.setMiddlewareAuthorization(token.accessToken);
            this.authService.subscription().then();
            if (this.storage.getIsChromecast() !== 'true') {
              this.authService.closeSesionIdVecima(true).then(); //deleteSessionIdVecima
            }
            if(this.isCarrierBilling){
              this.authService.setAuthPersonal(token);
              return next.handle(this.addTokenHeader(request, token.accessToken));
            }
            this.authService.setAuthentication(token,this.authService.getAuthType(),false,'',true);

            return next.handle(this.addTokenHeader(request, token.accessToken));
          }),
          catchError((err) => {
            this.loadingService.setLoading(false,'refreshing');
            this.isRefreshing = false;
            if (err.status !== 0 && err?.url.includes('refresh')) {
              this.authService.trackSendEventFail(err);
              this.redirectToLogin();
            }
           // console.log(err);
            return throwError(err);
          })
        );
      }else{
        this.loadingService.setLoading(false,'refreshing');
        this.isRefreshing = false;
        this.redirectToLogin();
      }
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }
  //decide refresh token method , between carrier billing and tigo ID
  private decideRefrehMethod(){
    if(this.storage.getUserCarrierBilling()){
       this.isCarrierBilling = true;
       return this.authService.refreshCarrierBilling();
    } else {
      this.isCarrierBilling = false;
    }
    return this.authService.refreshTokenTigoID();
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', token) });
  }

  private updateRequestToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    const token = this.storage.getMiddlewareAuthorization();
    request = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });

    return next.handle(request)
      .pipe(catchError((err) => {
        switch (err.error.code) {
          case 401:
            this.redirectToLogin();
            return err;
          case 403:
            if(token)
              {if (!this.validateForbiddenErroCount(request))
                {return this.intercept(this.incrementForbiddenErrorCount(request), next);}}
            this.redirectToLogin();
            return err;
          default:
            return err;
        }
      }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => evt));
  }

  private redirectToLogin(){
    const route = IS_ADAPTABLE ? '/-/iniciar-sesion': '/-/iniciar-sesion-tv';
    this.authService.removeAuthenticationRefresh(route);
  }

  private async validateRefresh(user, cod?,route?) {
    try{
      const date = new Date(user.exp * 1000);
      const today = new Date(Date.parse(Date()));
      if(today >= date && !this.isRefreshing){
        this.isRefreshing = true;
        await this.authService.refreshAuthentication(403,route);
        this.isRefreshing = false;

       }else if(today <= date && this.isRefreshing){
          this.isRefreshing = false;
       }
    }catch(e){ }
  }

}
