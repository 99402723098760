import { Injectable } from '@angular/core';
import { registerPlugin } from '@capacitor/core';
import {VideoStreamingService} from './video-streaming.service';
import {PlayerService} from './player.service';
import {Subject} from 'rxjs';
import {Storage} from '../core/storage/storage';
import {TextManagementService} from './text-management.service';
import {DevicesService} from './devices.service';
import {LoadingService} from './loading.service';
import { TextManagementV2Service } from './text-management-v2.service';
import {Broadcaster} from "@awesome-cordova-plugins/broadcaster/ngx";
import {DeviceHelper} from "../core/helpers/device.helper";
import {MessageDialogService} from "./message-dialog.service";
const PluginChromecast = registerPlugin<any>('PluginChromecast');
export default PluginChromecast;
@Injectable({
  providedIn: 'root'
})
export class ChromecastService {
  datacast: any = [];
  datamediachomecast;
  iscontroldevice = false;
  iscountDeactiveUser = 0;
  onUserConcurrent: any;
  //url = '';
  //licenseDrm = '';
  //urlLicense = '';
  //token = ''
  //private addItemDevice = new Subject<boolean>();
  //addItemDevice$ = this.addItemDevice.asObservable();


  constructor(private videostreaming: VideoStreamingService,
              private playerService: PlayerService,
              private storage: Storage,
              private textManagementService: TextManagementService,
              private textManagementV2Service: TextManagementV2Service,
              private deviceService: DevicesService,
              private loadingService: LoadingService,
              private broadCasterNative: Broadcaster,
              private deviceHelper: DeviceHelper,
              private msgService: MessageDialogService) {
    this.broadCasterNative.addEventListener('eventRunChromecast').subscribe((event) => {
      this.switchEventsForChromecast(event).then();
    });

    this.broadCasterNative.addEventListener('ChromecastSetDataVideo').subscribe( (event) => {
      this.initBroadCasterNativeData(event).then();
    });
  }

  async Initializatedplugin(appId) {
    await PluginChromecast.initialize({appId});
  }

  async StreamChromeCast(title, subtitle, userinfo, islive: boolean, urlLicense, isVecima: boolean) {
    const response = await PluginChromecast.requestSession(
      {title, subtitle, userinfo, islive, urlLicense, isVecima});
    this.setDataMediaChomecast(response);
    this.closePIPChromecast().then();
  }

  async StopAutomaticChromecast(plugindata) {
    await PluginChromecast.sessionStop(plugindata);
    this.RemoveDataMediaChromecast();
  }

  /* tranmission for chromecast */

  setTransmitionChromecast(data: any): void {
    this.datacast = data;
  }

  getTransmitionChromecast() {
    return this.datacast;
  }

  setDataMediaChomecast(data: any): void {
    this.datamediachomecast = data;
    this.storage.setChromecastBody(data);
   // this.storage.setIsChromecast('true');
  }

  getDataMediaChomecast() {
    return this.datamediachomecast;
  }

  RemoveDataMediaChromecast() {
    this.datamediachomecast = null;
    this.storage.removeChromecastBody();
    this.storage.removeIsChromecast();
   // this.RemoveStreamingChromecast();
    //this.storage.removeStatusInitChromecast();
  }

 /* RemoveStreamingChromecast() {
    this.playerService.removeStreaming();
  } */


  /* events for chromecast */
  async switchEventsForChromecast(event) {
    if (event) {
      switch (event.event) {
        case 'cancel': {
         // this.isDeactiveSesionUser().then();
         // this.playerService.deleteSessionIdStreaming().then();
          this.RemoveDataMediaChromecast();
          this.loadingService.setLoading(false, 'RequestSessionChromecast');
          break;
        }
        case 'stop': {
          this.isDeactiveSesionUser().then();
          this.playerService.deleteSessionIdStreaming().then();
          this.RemoveDataMediaChromecast();
          break;
        }
        case 'stopAutomatic': {
          this.isDeactiveSesionUser().then();
          this.playerService.deleteSessionIdStreaming().then();
          this.RemoveDataMediaChromecast();
          break;
        }
        case 'requestSession_Send': {
          this.loadingService.setLoading(true, 'RequestSessionChromecast')
          let urlDrmLicense = '';
          this.storage.setIsChromecast('true');
          let userData: any = this.storage.getUser();
          let userData2 = userData ? (userData.phone_number ? userData.phone_number : userData.email) : 'anon';
          const textsV2 = await this.textManagementV2Service.getTexts();
          const isEnableVecima = textsV2?.VECIMAOPTIONS?.vecimaGenerateTokensOn;
          if (this.getTransmitionChromecast()?.isDrm) {
            const texts = await this.textManagementService.getTexts();
            const drmEndpoints = texts.VIDEO_STREAMING;
            urlDrmLicense = drmEndpoints.wServer;
          }
         // console.log("urlDrmLicense", urlDrmLicense);
          this.StreamChromeCast(this.getTransmitionChromecast()?.title, 'FHD', userData2, this.getTransmitionChromecast()?.isLive, urlDrmLicense, isEnableVecima).then();
          break;
        }
      }
    }
  }

  async initBroadCasterNativeData(event: any) {
    try {
      const textsV2 = await this.textManagementV2Service.getTexts();
      const isEnableVecima = textsV2?.VECIMAOPTIONS?.vecimaGenerateTokensOn;
      let uuid = this.storage.getUser() ? this.storage.getUser() : this.storage.getUserCarrierBilling();
      let isEnableDevice = true;
      let item = {
        url: '',
        tokenDrm: '',
        tokenStreaming: ''
      };
      const deviceId = await this.deviceHelper.getUUID().then();
      try {
        const resConnectionDevices: any = await this.deviceService.setConnectionDevice('', this.getTransmitionChromecast()?.title);
        if (!resConnectionDevices?.enableView && resConnectionDevices?.deviceId !== deviceId) {
          isEnableDevice = false;
        }
      } catch (e) {
        isEnableDevice = false;
        this.loadingService.setLoading(false, 'RequestSessionChromecast');
      }
      if (isEnableDevice) {
        if (isEnableVecima) {
          const responseSls = await this.playerService.processVecimaStructure(textsV2);
          if (responseSls) {
            let urlUseMode = '';
            let url = '';
            urlUseMode = event?.isDrm ? this.playerService.getStreamingUrlDash() : this.playerService.getStreamingUrl();
            const urlPlay = `${urlUseMode}?t=${encodeURIComponent(this.playerService.getTokenStreamingVecima())}&s=${encodeURIComponent(this.playerService.getSessionIdVecima())}&c=${encodeURIComponent(this.playerService.getIpUserVecima())}&e=${encodeURIComponent(this.playerService.getDateExpirationVecima())}&v=${encodeURIComponent(this.playerService.getVersionVecima())}&u=${encodeURIComponent(uuid?.id)}`;;
            url = this.playerService.addQueryStringVecima(urlPlay);
            const tokenDrm = this.playerService.getTokenDrmVecima();
            const tokenStreaming = this.playerService.getTokenStreamingVecima();
            item = this.bodyResponsePluginChromecast(url, tokenDrm, tokenStreaming);
          } else {
            this.storage.removeIsPIP();
            this.loadingService.setLoading(false, 'RequestSessionChromecast');
          }
        } else {
          if (event?.isDrm) {
            let licenseToken = await this.playerService.getTokenPlayer('widevine', this.playerService.getTransmissionData());
            let url = this.playerService.getStreamingUrlDash();
            let tokenStreaming = this.playerService.getTransmissionData()?.token;
            item = this.bodyResponsePluginChromecast(url, licenseToken, tokenStreaming);
          } else {
            let tokenDrm = null;
            let url = this.playerService.getStreamingUrl();
            let tokenStreaming = this.playerService.getTransmissionData()?.token;
            item = this.bodyResponsePluginChromecast(url, tokenDrm, tokenStreaming);
          }
        }
      } else {
        this.storage.removeIsPIP();
        this.msgService.messageConcurrentUserNoMaster(this.getTransmitionChromecast());
        this.loadingService.setLoading(false, 'RequestSessionChromecast');
      }
      this.broadCasterNative.fireNativeEvent('ChromecastGetDataVideo', false, item).then(() => {
        this.loadingService.setLoading(false, 'RequestSessionChromecast');
      });
    } catch (e) {
      this.storage.removeIsPIP();
    }
  }


  bodyResponsePluginChromecast(url: any, tokenDrm: any, tokenStreaming: any) {
    return { url, tokenDrm, tokenStreaming }
  }


  /**** deactiveSesionUser ***/

  async isDeactiveSesionUser() {
    if (!this.onUserConcurrent) {
      const textsV2 = await this.textManagementV2Service.getTexts();
      this.onUserConcurrent = textsV2?.USERCONCURRENTON;
    }
    if (this.onUserConcurrent?.status && this.onUserConcurrent?.status === true) {
      if (this.iscountDeactiveUser === 0) {
        // this.loadingService.setLoading(true, 'setConnectionDeactiveChromecast');
        try {
          const response: any = await this.deviceService.setConectionDeactive();
        } catch (e) {
          console.error(e);
        }
        this.iscountDeactiveUser++;
      }
    }
  }

  /*** Close PIP *****/

  async closePIPChromecast() {
    await this.broadCasterNative.fireNativeEvent('ClosePIPExoplayer', false, {}).then(() => {
     // console.log('eliminar session vecima por PIP');
      this.storage.removeIsPIP();
      this.playerService.deleteSessionIdStreaming().then();
    });
  }






}
