import {Component, Input, OnInit, OnChanges, SimpleChanges, HostListener, OnDestroy} from '@angular/core';
import {Storage} from 'src/app/core/storage/storage';
import {NavController, Platform} from '@ionic/angular';
import {EVENT_TRACK_072} from 'src/app/config/analytics/events-track';
import {SegmentAnalytics} from 'src/app/core/providers/segment-analytics';
import {IS_ADAPTABLE} from 'src/app/config/constants';
//import * as moment from 'moment';
import moment from 'moment';
import { VideoStreamingService } from 'src/app/services/video-streaming.service';
import { RecommendedService } from 'src/app/services/recommended.service';
import { EventDetailService } from 'src/app/services/event-detail.service';
import { ENTITLEMENT } from 'src/app/config/settings';
import { Capacitor } from '@capacitor/core';
import { DevicesService } from 'src/app/services/devices.service';
import { AuthService } from 'src/app/services/auth.service';
import { TextManagementV2Service } from 'src/app/services/text-management-v2.service';

@Component({
  selector: 'app-recommended',
  templateUrl: './recommended.component.html',
  styleUrls: ['./recommended.component.scss'],
})
export class RecommendedComponent implements OnInit, OnChanges, OnDestroy {
  textsV2: any;
  assetDomain: any;
  @Input() data: any;
  @Input() title: string;
  @Input() banneradvertising: any;
  @Input() isMultiEntitlement: any;
  @Input() multiEntitlement: any;
  @Input() queryParams: any;

  recommended = [];
  showRecommended = false;
  isAdaptable = IS_ADAPTABLE;
  loadingImg = '../../../../assets/graphics/pictures/load-img.png';
  isWeb = Capacitor.getPlatform() === 'web';
  pageNumber = 2;
  showafter = false;
  temporaryobj = [];
  banneravailable: boolean = true;
  widthbanner=0;
  heightbanner=0;
  marginbanner=0;
  slot: any;
  iddiv = 'div-gpt-ad-1661901197211-0';
  isemptybanner: boolean = true;

  constructor(
    private storage: Storage,
    private platform: Platform,
    private navController: NavController,
    private segmentAnalytics: SegmentAnalytics,
    private videoStreamingService: VideoStreamingService,
    private recommendedService: RecommendedService,
    private eventDetailService: EventDetailService,
    private devicesService: DevicesService,
    private textManagementV2Service: TextManagementV2Service
  ) {
  }

  async ngOnInit() {
    this.textsV2 = await this.textManagementV2Service.getTexts();
    this.assetDomain = this.textsV2?.ASSET_DOMAIN;
    if (this.data.length > 0) {
      try {
        const data = this.data;
        this.recommended = (data.length > 0) ? data : [];
        this.showRecommended = (data.length);
      } catch (error) {
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  async doSomethingOnScroll(event) {
    const totalwidth = Math.round(event.target.offsetWidth + event.target.scrollLeft);
    if (totalwidth === event.target.scrollWidth) {
      const newArr = await this.recommendedService.getEvents(this.pageNumber, this.assetDomain, this.queryParams, this.multiEntitlement);
      this.pageNumber++;
      this.recommended.push(...newArr);
    }
  }

  ngOnChanges(change: SimpleChanges): void {
  //  this.data = [];
 //   const results = change.data;
    this.data = [];
    const results = change.data;
    const banner = change.banneradvertising;

    if (results) {
      this.recommended = results.currentValue;
      this.showRecommended = (results.currentValue.length > 0);
      this.pageNumber = 2;
      this.showafter = this.showRecommended;
      this.temporaryobj = this.recommended;
    }

    if (this.showafter) {
      this.showRecommended = this.showafter;
      this.recommended = this.temporaryobj;
    }

    if (this.recommended.length > 0 && this.showRecommended) {
      if (banner &&  this.isAdaptable) {
        this.loadScript(banner.currentValue);
      }
    }
    /*this.recommended = change.data?.currentValue;

    if (this.recommended) {
     // this.recommended = results.currentValue;
      this.showRecommended = (this.recommended.length > 0);
    }*/
  }

  ngOnDestroy(): void {
    if (this.isAdaptable) {
      this.Removeslot();
    }
  }

  /* If user is authenticated then redirect to detail page, else redirect to login page */
  async viewDetail(event): Promise<void> {
    const authenticated = this.storage.getAccessToken();
    let route: string;
    const segmentData = {
      championship_id: event.championship_id,
      championship_name: event.championship_id,
      airdate: event.createdAt,
      sport_name: event.sport_name,
      video_title: event.video_title,
      journey: 'player',
      content_id: event.contentful_id,
      home_team_id: event.home_team_id,
      home_team: event.home_team,
      away_team_id: event.away_team_id,
      away_team: event.away_team,
      total_length: event.total_length,
      video_position: 0,
      session_id: event.session_id,
      video_quality: event.video_quality,
      content_category: event.content_category,
      content_type: this.defineContentTypeSegment(event.end_date),
    };
    await this.segmentAnalytics.eventTrack(EVENT_TRACK_072, segmentData);
    this.videoStreamingService.setEventCategory(event.content_category);
    let eventType = '';
    if (event.content_type === 'gameSchedules') {
      eventType = 'game';
    } else {
      eventType = 'highlights';
    }
    //let streamingType = await this.eventDetailService.getStreamType(event.contentful_id, eventType);
    route = `/detail/${event.contentful_id}/${eventType}`;
    this.storage.setPreviousRoute(route);
/*     if (!authenticated && streamingType != ENTITLEMENT.FREE_TYPE) {
      if (this.isAdaptable) {
        route = '/-/iniciar-sesion';
      } else {
        route = '/-/iniciar-sesion-tv';
      }
    } */
    this.navController.navigateForward(route);
  }

  private defineContentTypeSegment(end_date: Date): string {
    let contentTypeFixed = '';
    const endDate = end_date;
    if (endDate) {
      const now = moment();
      const deadline = moment(endDate);

      const isAfter = moment(deadline).isAfter(now);
      if (isAfter) {
        contentTypeFixed = 'live';
      } else {
        contentTypeFixed = 'replay';
      }
    } else {
      contentTypeFixed = 'highlights';
    }
    return contentTypeFixed;
  }

  handleScrollSegment(qty: number): void {
    let segmentAux = document.getElementById('recommended__segment');
    segmentAux.scrollLeft = (segmentAux.scrollLeft + qty);
  }

  async loadScript(banners) {
    const platform = Capacitor.getPlatform();
    if (banners[platform]) {
      let bannervalidate: any = [];
      if (this.platform.is('mobileweb') || this.platform.is('mobile')) {
        bannervalidate = banners.android;
      } else {
        bannervalidate = banners[platform];
      }
      const databanner: any = bannervalidate.length > 0 ? bannervalidate.find(E => E.type === 'Recomendados') : null;
      if (databanner && databanner?.enable) {
        let slottarget;
        this.banneravailable = databanner?.enable;
        try {

          const googletag: any = window['googletag'] || {};
          if (window['googletag'] && googletag.apiReady) {
            googletag.cmd = googletag.cmd || [];
            // this.slot = googletag.pubads().getSlots();
            const divbanner = document.getElementById(this.iddiv);
            if (!this.slot && divbanner) {
              let that = this;
              googletag.cmd.push(() => {
                slottarget =  googletag.defineSlot(databanner.banner, [[databanner.width, databanner.height]], this.iddiv)
                  .addService(googletag.pubads());
                googletag.pubads().enableSingleRequest();
                googletag.pubads().collapseEmptyDivs(true, true);
                googletag.enableServices();
              });
              googletag.display(this.iddiv);

              // event is fired where code is injected into a slot
              googletag.pubads().addEventListener('slotRenderEnded',
                function(event) {
                  const slot = event.slot;
                  if (slot === slottarget) {
                    that.slot = slottarget;
                    that.isemptybanner = event.isEmpty;
                    if (event.isEmpty) {
                      that.marginbanner = 0;
                      that.banneravailable = false;
                    }
                  }
                }
              );
              this.widthbanner = databanner.width;
              this.heightbanner = databanner.height;
              this.marginbanner = 1;
            } else {
              if (this.slot) {
                if (!this.isemptybanner) {
                  this.marginbanner = 1;
                  this.banneravailable = true;
                } else {
                  this.marginbanner = 0;
                  this.banneravailable = false;
                }
              } else {
                this.marginbanner = 0;
                this.banneravailable = false;
              }
            }
          }
        } catch (e) {
          console.log(e);
          this.banneravailable = false;
          this.marginbanner = 0;
        }
      } else {
        this.banneravailable = false;
      }
    }
  }


// RemoveSlot

  Removeslot() {
    const googletag: any = window['googletag'] || {};
    if (window['googletag'] && googletag.apiReady) {
      googletag.cmd = googletag.cmd || [];
      if (this.slot) {
        googletag.destroySlots([this.slot]);
      }
    }
  }
}
