export const environment = {
    production: false,
	A1:'xz$%23!t3@ibpm',
	TITLE:'Tigo Sports Paraguay',
	WEB_VERSION:'6.0.2',
	COUNTRY:'PY',
	DATA_FACTORY_PATH:'https://estaticopy.tigocloud.net/',
	IMA_ADS_PATH:'https://pubads.g.doubleclick.net/gampad/ads',
	GRANT_TYPE:'client_credentials',
	APPE_ID:'U2FsdGVkX183/UGzQDyC8ce9qHnivHq+oR5pofOPZSI=',
	CREATION_CHANNEL:'tigosports',
	DEEPLINK_HOST:'https://ts.pxt.onl/dl/',
	DEEPLINK_SCHEMA:'tigo-sports-app://',
	BW_API_PIX:'U2FsdGVkX1+zeofzN5ooY0sWbWNOMMvZZx1msijuV9YMmcacb0ODpyp/8dyKWDHCMNec+A1SU6GXdkHhK3avCg==',
	BW_BASE:'U2FsdGVkX19tTarUPt6sl+kPASp+9eUn4eKXk+lHT509UuETTVkWPMCIrPhxiP0U',
	SAFARI_WEBSITE_PUSH_ID:'web.onl.pxt.preprod-ott',
	O5:'!XZ15BHBZ4LEmyz',
	DEEPLINK_IOS_APP:'https://apps.apple.com/app/tigo-sports-paraguay/id963028258',
	PROCESS_PIX:'U2FsdGVkX19BAxq3zmn/+55W6c//SOP6EuHKpObqo3w=',
	PYT_TYPE:'ingenico',
	SEGMENT_SOURCE_NAME:'Tigo Sports-PY',
	FIREBASE_KY:'AIzaSyB4v4pL1BA-0PmJzvWfH9-zN3yzApq_JYc',
	FIREBASE_PDI:'tigo-sports-app',
	FIREBASE_APP:'1:285925071575:web:0aea346d35f69f7ea52e92',
	FIREBASE_MDI:'G-2W4R22ZSMN',
	MIXPANEL_PROXY:'https://analytics.tigocloud.net',
	MIXPANEL_TOKEN:'64ed5288178a75148bf4dcd41696a3b3',
	GENERAL_PROJECT:false,
	APP_DEBUG:true,
	MIDDLEWARE_PATH:'https://preprod-mdl-ott.pxt.onl',
	MIDDLEWARE_VERSION:'v4',
	PPV_URL:'https://preprod-ppv.pxt.onl',
	PROCESS_VERSION:'0',
	BASE_URL:'https://preprod-ott.pxt.onl',
	PYT_VALUE:'U2FsdGVkX1/M4b4nn/UoekENEjb+KNTNAiBhNV7H7H3CzjFLownFo9Uwo1TtF62UmicsqMFWtZpvehO1uqHFbg==',
	PYT_IV:'U2FsdGVkX1+11FJGSwBP8fYCjjedthbJRwOvu71iZsG+T08dQZQGfJ0LkoqLdOew',
	MERCHANT_ID:'millicom_gt_tigosports',
	ORG_ID:'U2FsdGVkX18uSBn4m272jkYo76B21Ye35Z7HQSVytDs=',
	APP_SYNC_PATH:'https://5ecq46ge4zeulixbor7xf7l5su.appsync-api.us-east-1.amazonaws.com/graphql',
	APP_SYNC_PATH_WS:'wss://5ecq46ge4zeulixbor7xf7l5su.appsync-realtime-api.us-east-1.amazonaws.com/graphql',
	APP_VECIMA_CREATE_TOKENS_PATH:'https://e68eqvprx9.execute-api.us-east-1.amazonaws.com/dev/v1',
	PATH_CONFIG_S3:'https://assets.pxt.onl/config/values.json',
}; 

export const MIDDLEWARE_HOST = environment.MIDDLEWARE_PATH + '/api/' + environment.MIDDLEWARE_VERSION + '/';