export const environment = {
    production: true,
	A1:'xz$%23!t3@ibpm',
	TITLE:'Tigo Sports Paraguay',
	WEB_VERSION:'6.0.2',
	COUNTRY:'PY',
	DATA_FACTORY_PATH:'https://estaticopy.tigocloud.net/',
	IMA_ADS_PATH:'https://pubads.g.doubleclick.net/gampad/ads',
	GRANT_TYPE:'client_credentials',
	APPE_ID:'U2FsdGVkX1+ZM0W7qtn8a5/2wU6WUSirROq6qeHCtxQ=',
	CREATION_CHANNEL:'tigosports',
	DEEPLINK_HOST:'https://ts.pxt.onl/dl/',
	DEEPLINK_SCHEMA:'tigo-sports-app://',
	BW_API_PIX:'U2FsdGVkX19B3rGhWxLazceAZLydIwQqlNzcrFNJmyQqIcLlg63E/sG/VFdXVsBGgVrF5wfAWct0zNAe9NvwtA==',
	BW_BASE:'U2FsdGVkX1/VB402wNZxXjDG5ru1CyxCoAP9HrzHXix9IwvHh0TF1WcSv7wmd6Xy',
	SAFARI_WEBSITE_PUSH_ID:'web.onl.pxt.preprod-ott',
	O5:'!XZ15BHBZ4LEmyz',
	DEEPLINK_IOS_APP:'https://apps.apple.com/app/tigo-sports-paraguay/id963028258',
	PROCESS_PIX:'U2FsdGVkX19EPH+pkTj9CGZGI2giu9y2Z7Oi9hjiYTY=',
	PYT_TYPE:'ingenico',
	SEGMENT_SOURCE_NAME:'Tigo Sports-PY',
	FIREBASE_KY:'AIzaSyB4v4pL1BA-0PmJzvWfH9-zN3yzApq_JYc',
	FIREBASE_PDI:'tigo-sports-app',
	FIREBASE_APP:'1:285925071575:web:0aea346d35f69f7ea52e92',
	FIREBASE_MDI:'G-2W4R22ZSMN',
	MIXPANEL_PROXY:'https://analytics.tigocloud.net',
	MIXPANEL_TOKEN:'64ed5288178a75148bf4dcd41696a3b3',
	GENERAL_PROJECT:false,
	APP_DEBUG:false,
	MIDDLEWARE_PATH:'https://ts-ott.pxt.onl',
	ENVIRONMENT_VERSION:'1',
	MIDDLEWARE_VERSION:'v4',
	PPV_URL:'https://pagos.tigosports.com.py',
	PROCESS_VERSION:'1',
	BASE_URL:'https://www.tigosports.com.py',
	PYT_VALUE:'U2FsdGVkX19Gn3JAYU4AOeV5n95gGdoxdBeJad12SaM=',
	PYT_IV:'U2FsdGVkX19UX7VukvYqDwuAMUPigKXmsmezjQmORVM=',
	MERCHANT_ID:'',
	ORG_ID:'',
	APP_SYNC_PATH:'https://',
	APP_SYNC_PATH_WS:'wss://5ecq46ge4zeulixbor7xf7l5su.appsync-realtime-api.us-east-1.amazonaws.com/graphql',
	APP_VECIMA_CREATE_TOKENS_PATH:'https://e68eqvprx9.execute-api.us-east-1.amazonaws.com/dev/v1',
	PATH_CONFIG_S3:'https://assets.pxt.onl/config/values.json',
}; 

export const MIDDLEWARE_HOST = environment.MIDDLEWARE_PATH + '/api/' + environment.MIDDLEWARE_VERSION + '/';