import {Injectable} from '@angular/core';
import {GlobalProperties} from '../interfaces/analytics/global-properties';
import {Capacitor} from '@capacitor/core';
import {Storage} from '../storage/storage';
import {Location} from '../interfaces/location';
import {User} from '../interfaces/user';
import {IdentifyProperties} from '../interfaces/analytics/identify-properties';
import {IS_ADAPTABLE} from '../../config/constants';
import {DeviceContext} from '../interfaces/analytics/device-context';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {DeviceHelper} from '../helpers/device.helper';
import {Device} from '@capacitor/device';
import {Network} from '@capacitor/network';
//import {Sim} from '@awesome-cordova-plugins/sim/ngx';
import {Platform} from '@ionic/angular';
//import { Idfa, AdvertisingInfoResponse } from '@sparkfabrik/capacitor-plugin-idfa';
import { AdvertisingId } from '@capacitor-community/advertising-id';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SegmentUtil {

  constructor(
    private storage: Storage,
    private appVersion: AppVersion,
    private deviceHelper: DeviceHelper,
    //private sim: Sim,
    private platform: Platform
  ) {
  }

  /* Build segment global properties object */
  public async getGlobalProperties(): Promise<GlobalProperties> {
    const location: Location = this.storage.getLocation();
    const user = this.storage.getUser() ? this.storage.getUser() : this.storage.getUserCarrierBilling();
    const entitlement: any = this.storage.getEntitlement();
    return {
      authentication_method: (user ? user.authentication_method : ''),
      authentication_type: (user ? user.authentication_type : ''),
      screen_name: '',
      page_name: '',
      business_category: (user ? user.business_category : ''),
      authentication_provider: (user ? user.authentication_provider ? user.authentication_provider : '' : ''),
      suscriber_id: (user ? user.suscriber_id ? user.suscriber_id : '' : ''),
      entitlement: (user ? user.entitlement : ''),
      country: (location ? location.code : ''),
      linked_homeids: user ? user.homeid : '.',
      linked_msisdns: user ? user.msisdns : '.',
      prev_page: '',
      segment_source_name: environment.SEGMENT_SOURCE_NAME,
      ...await this.getDeviceContext()
    };
  }

  /* Return device type based on device platform */

  /* Available platforms: web | app | tv */
  private getDeviceType(): string {
    let deviceType = Capacitor.getPlatform();
    if (deviceType !== 'web') {
      if (IS_ADAPTABLE) {
        deviceType = 'app';
      } else {
        deviceType = 'tv';
      }
    }

    return deviceType;
  }

  /* Build segment identify properties */
  public getIdentifyProperties(): IdentifyProperties {
    const user = this.storage.getUser() ? this.storage.getUser() : this.storage.getUserCarrierBilling();
    const location: Location = this.storage.getLocation();
    const identifyProperties: IdentifyProperties = {
      userId: (user ? user.id : ''),
      tigo_id: (user ? user.id : ''),
      email: (user ? user.email : ''),
      msisdn: (user ? user.phone_number : ''),
      user_name: (user ? user?.userInfo?.userName : ''),
      registration_date: (user ? user.registration_date : ''),
      national_id_type: (user ? user.national_id_type : ''),
      national_id: (user ? user.national_id : ''),
      $braze_external_id: (user ? user.id : ''),
      stored_cards: (user ? user.stored_cards : 0),
      country: (location ? location.code : ''),
      currency: (location ? location.currency : ''),
      home_contract_id: (user ? user?.userInfo?.billingAccountId : ''),
      mobile_contract_id: (user ? user.mobile_contract_id : ''),
      plan_type: (user ? user?.userInfo?.planType : ''),
      plan_name: (user ? user.plan_name : '')
    };
    if(user?.authentication_method === "toolbox"){
      identifyProperties.tigo_id = undefined;
    }
    return identifyProperties;
  }

  public async getDeviceContext(): Promise<DeviceContext> {
    const advertisingInfo = await this.getAdvertisingInfo();
    const deviceInfo = await Device.getInfo();
    const networkStatus = await this.getNetworkStatus();
    const location = this.storage.getLocation();

    return {
      app_build: (Capacitor.getPlatform() !== 'web') ? await this.appVersion.getVersionCode() : '',
      app_name: (Capacitor.getPlatform() !== 'web') ? await this.appVersion.getAppName() : '',
      version: (Capacitor.getPlatform() !== 'web') ? await this.appVersion.getVersionNumber() : environment.WEB_VERSION,
      app_namespace: (Capacitor.getPlatform() !== 'web') ? await this.appVersion.getPackageName() : '',
      adTrackingEnabled: (advertisingInfo ? advertisingInfo.isAdTrackingLimited : false),
      advertisingId: (advertisingInfo ? advertisingInfo.id : ''),
      device_id: await this.deviceHelper.getUUID().then(),
      device_manufacturer: deviceInfo.manufacturer,
      device_model: deviceInfo.model,
      device_name: deviceInfo.name,
      device_type: this.getDeviceType(),
      ip: location?.ip,
      //carrier: await this.sim.getSimInfo().then(info => info.carrierName, err => ''),
      carrier: '',
      cellular: networkStatus.cellular,
      wifi: networkStatus.wifi,
      os_name: deviceInfo.operatingSystem,
      os_version: deviceInfo.osVersion,
      density: this.platform.width() + 'x' + this.platform.height(),
      height: this.platform.height() + 'px',
      width: this.platform.width() + 'px'
    };
  }

  private async getNetworkStatus(): Promise<any> {
    const network = await Network.getStatus();

    const result = {
      cellular: false,
      wifi: false
    };

    if (network.connected) {
      result.wifi = (network.connectionType === 'wifi');
      result.cellular = (network.connectionType === 'cellular');
    }

    return result;
  }

  private async getAdvertisingInfo(): Promise<any> {
    let advertisingInfo: any;
    try {
      // advertisingInfo = await Idfa.getAdvertisingInfo();
      if (this.platform.platforms().indexOf('ios') >= 0 && Capacitor.isNativePlatform()) {
        await AdvertisingId.requestTracking();
      }
      const advertisingResponse : any = await AdvertisingId.getAdvertisingId();
      advertisingInfo = advertisingResponse?.id;
    } catch (e) {}

    return advertisingInfo;
  }

}
