import {Component, NgZone} from '@angular/core';
import {NetworkUtil} from './core/utils/network.util';
import {Capacitor} from '@capacitor/core';
import {ANDROID_PLATFORM_STATUS_BAR_COLOR, IS_ADAPTABLE} from './config/constants';
import {MiddlewareService} from './services/middleware.service';
import {NotificationsService} from './services/notifications.service';
import {UpdateService} from './services/update.service';
import {ScriptRegisterUtil} from './core/utils/script-register.util';
import {SegmentAnalytics} from './core/providers/segment-analytics';
import {LocationService} from './services/location.service';
import {App} from '@capacitor/app';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import {SplashScreen} from '@capacitor/splash-screen';
import {StatusBar} from '@capacitor/status-bar';
import {QueryParamsService} from './services/query-params.service';
import {TextManagementService} from './services/text-management.service';
import {NavController, Platform} from '@ionic/angular';
import {convertToParamMap, Router} from '@angular/router';
import { Storage } from './core/storage/storage';
import { environment } from '../environments/environment.prod';
import { delay } from 'rxjs/operators';
import { ProgressLoadComponent } from './shared/components/progress-load/progress-load.component';
import { LoadingService } from './services/loading.service';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import {EventDetailService} from './services/event-detail.service';
import { AuthService } from './services/auth.service';
import {Device} from '@capacitor/device';
import { ScriptNewRelicUtil } from './core/utils/script-new-relic.util';
import { CSSOverlayButtons } from './core/utils/overlay-buttons.util';
import { PaymentService } from './services/payment.service';
import BrowserDetector from 'browser-dtector';
import { UAParser } from 'ua-parser-js';
import { MaintenanceService } from './services/maintenance.service';
import { DevicesService } from './services/devices.service';
import {Network} from '@capacitor/network';
import { Browser } from '@capacitor/browser';
import {EVENT_TRACK_002} from './config/analytics/events-track';
import {TigoIdService} from './services/tigo-id.service';
import {OnOfPayment} from './core/interfaces/on-of-payment';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {AndroidFullScreen} from '@awesome-cordova-plugins/android-full-screen/ngx';
import {Apollo} from 'apollo-angular';
//import {SUBSCRIPTION_CONECTION, SUBSCRIPTION_SESION} from './queries/query';
import {Auth0Service} from './services/auth0.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  isVisible = false;
  showAll = false;
  isAdaptable = IS_ADAPTABLE;
  private spinnerRef: OverlayRef = this.cdkSpinnerCreate();
  constructor(
    private network: NetworkUtil,
    private middlewareService: MiddlewareService,
    private notificationsService: NotificationsService,
    private zone: NgZone,
    private navController: NavController,
    private updateService: UpdateService,
    private scriptRegister: ScriptRegisterUtil,
    private segmentAnalytics: SegmentAnalytics,
    private locationService: LocationService,
    private queryParamsService: QueryParamsService,
    private textManagementService: TextManagementService,
    private router: Router,
    private storage: Storage,
    private overlay: Overlay,
    private loadingService: LoadingService,
    private screenOrientation: ScreenOrientation,
    private eventDetailService: EventDetailService,
    private authService: AuthService,
    private scriptNewRelic: ScriptNewRelicUtil,
    private cssOverlaButtons: CSSOverlayButtons,
    private paymentService: PaymentService,
    private maintenanceService: MaintenanceService,
    private deviceService: DevicesService,
    private tigoID: TigoIdService,
    private appVersion: AppVersion,
    private androidFullScreen: AndroidFullScreen,
    private platform: Platform,
    private auth0service: Auth0Service,
    private apollo: Apollo
  ) {
    this.authService.subscription().then();
    this.segmentAnalytics.identifyPages();
    this.initializeApp();
    this.backButton();
  }

  toDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => callback(reader.result);
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  async initializeApp(): Promise<void>  {
    this.isvalidateOrderPaypal();
    if (Capacitor.getPlatform() === 'android') {
      //Funciona para que la app se adapte al UI del telefono
      await this.androidFullScreen.showSystemUI();
    }
    this.toDataURL('assets/graphics/network/network-alert.svg', (dataUrl) => {
      this.storage.setImgCacheAlert(dataUrl);
    });
    //this.toDataURL('assets/graphics/app/logo_tigo-sport_inline.svg', (dataUrl) => {
    this.toDataURL('assets/graphics/app/logo_tigo-sport_inline_2.svg', (dataUrl) => {
      this.storage.setImgCacheLogo(dataUrl);
    });
    this.toDataURL('assets/graphics/app/loading.svg', (dataUrl) => {
      this.storage.setImgCacheLoadign(dataUrl);
    });
  //await this.storage.removelocationUser();
  let Oldtoken = this.storage.getOldMiddlewareAuthorization();
  let token = this.storage.getMiddlewareAuthorization();
  const user = this.storage.getUser() ? this.storage.getUser() :this.storage.getUserCarrierBilling();

  if (Oldtoken || (user && !token)) {
      this.storage.removelocationUser();
      this.storage.removeOldMiddlewareAuthorization();
      this.authService.removeAuthenticationRefresh();
      }

    if(user && !this.storage.getPlatformInfo()){
       this.deviceService.getDeviceData();
    }

    const platform = Capacitor.getPlatform();
    if (platform !== 'web' && this.isAdaptable) {
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      if(platform !== 'ios'){
        await StatusBar.setBackgroundColor({color: ANDROID_PLATFORM_STATUS_BAR_COLOR});
        await StatusBar.setOverlaysWebView({overlay: false});
      }
    }
    await this.locationService.locate();
    this.loadingListener();
    this.storage.removePreviousRoute();

    await this.initializeMiddlewareAuthorization();
    await this.network.verifyCurrentNetworkStatus();
    if(!Network.getStatus()){
      await SplashScreen.hide();
    }

    await this.queryParamsService.queryParamsListener();
    //await this.authService.refreshAuthentication();
    if(!this.storage.getVideoQuality()){
     // this.storage.setVideoQuality('UHD');
      this.storage.setVideoQuality('FHD');
    }
    App.addListener('appStateChange', (data) => {
      if (data.isActive) {
        this.loadingService.isProcessNetwork.next(false);
      } else if (data.isActive && this.router.url !== '/-/red') {
        this.loadingService.isProcessNetwork.next(false);
      }
    });
    const spinner = this.loadingService;
    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run( () => {
        //Flujo Auth0
        spinner.setLoading(true, 'auht0Loading');
        let { url } = data;
        const callbackUri = this.storage.getOptionLogin().redirectUrimovil;
        if (url?.startsWith(callbackUri)) {
          this.appVersion.getPackageName().then(value => {
            url = url.replace(`${value}://`, 'https://');
            // If the URL is an authentication callback URL..
            if (url.includes('error=') || url.includes('code='))
            {
              const urlParse= new URL(String(url));
              const searchParams = new URLSearchParams(urlParse.search);
              const code = searchParams.get('code');
              if (code) {
                const option = this.storage.getOptionLogin();
                const turnofsucription: OnOfPayment = this.textManagementService.getTurnoPaymentState();
                if (this.storage.getUserCarrierBilling()) {
                  this.storage.removeDevicesLimit();
                  this.authService.removeAuthentication(true);
                }
                let tvAuthValidation = this.storage.getTVAuthState();
                this.authService.setAuthType(option.type);
                this.tigoID.generateTokenTigoID(code, option.type).then((restponse) => {
                  if (restponse) {
                    //await this.validEntitlement();
                    this.segmentAnalytics.eventTrack(EVENT_TRACK_002, {journey: 'user interface'});
                    const previousRoute = this.storage.getPreviousRoute();
                    const isValidateEntitlement = this.storage.getUser()
                      ? this.storage.getUser().entitlement
                      : this.storage.getUserCarrierBilling().entitlement;
                    this.spinnerRef.detach();
                    spinner.setLoading(false, 'auht0Loading');
                    if (tvAuthValidation && !this.storage.getTVAuthState()) {
                      tvAuthValidation = false;
                      this.navController.navigateForward('/-/success-activate');
                    } else if (!isValidateEntitlement) {
                      if (turnofsucription.pvv === 'true') {
                        this.navController.navigateForward('/-/PasarelaAcceso');
                      } else if (turnofsucription.suscription === 'true') {
                        this.navController.navigateForward('/-/no-suscripcion');
                      } else {
                        this.navController.navigateRoot('/inicio');
                      }
                    } else if (previousRoute) {
                      this.storage.removePreviousRoute();
                      this.navController.navigateForward(previousRoute);
                    } else {
                      this.navController.navigateRoot('/inicio');
                    }
                  }
                });
              } else {
                this.spinnerRef.detach();
                spinner.setLoading(false, 'auht0Loading');
                if (Capacitor.isNativePlatform() && (this.platform.platforms().indexOf('android') >= 0
                || this.platform.platforms().indexOf('ios') >= 0)) {
                  this.auth0service.addItemisAuth0(true);
                }
                this.navController.navigateForward(
                  '/-/error-login',
                  {
                    state: {
                      error: searchParams.get('error'),
                      errorDescription: searchParams.get('errorDescription')
                    }
                  }
                ).then(r => {});
              }
            }
            try {
              Browser.close();
            } catch (e) {console.log('Browser, error:', e);}
          });
          this.spinnerRef.detach();
          spinner.setLoading(false, 'auht0Loading');
        } else if (data) {
          //Flujo DeeepLink
          //let item: string;
          const { url } = data;
          const isShared = data.url.includes(environment.DEEPLINK_HOST);
          const isDeeplink = data.url.includes(environment.DEEPLINK_SCHEMA);

          const item = data.url.replace(/.*?:\/\//, '');


          if (item) {
            let route = '';
            if (item === 'carrier-billing-providers') {
              const authenticated: string = this.storage.getAccessToken();
              if (!authenticated) {
                route = '/-/TiposProveedores';
                Device.getInfo();
              }
            }
            else if (item === 'home') {
              const authenticated: string = this.storage.getAccessToken();
              if (!authenticated) {
                route = '/inicio';
                Device.getInfo();
              }
            }
             else {
              const itemData = item.split('/');
              route = `/${item}`;
              this.eventDetailService.getStreamType(itemData[0], itemData[1]);
            }
            // await this.navController.navigateRoot(route);
            this.router.navigateByUrl(route);
          }
        }

        this.spinnerRef.detach();
        spinner.setLoading(false, 'auht0Loading');
      });
    });

  }


  private loadingListener(): void {
    this.loadingService.visibility.pipe(delay(0)).subscribe((value) => {
      if (value && !this.isVisible) {
        this.showAll = false;
        this.isVisible = true;
        this.spinnerRef.attach(new ComponentPortal(ProgressLoadComponent));
      }

      if (!value) {
        this.showAll = true;
        this.isVisible = false;
        this.spinnerRef.detach();
      }
    });
  }

  private cdkSpinnerCreate(): any {
    return this.overlay.create({
      backdropClass: 'loading-background',
      hasBackdrop: true,
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
  }

  private async initializeMiddlewareAuthorization(): Promise<void> {
    this.storage.setMiddleAuth('true');
    try {
      //const authorization = await this.middlewareService.getAuthorization();
      if(this.storage.getmakePayment()){
       // this.paymentService.cicleToken();
      }
      //TODO descomentar feature/kevin-new-jwt
      //this.scriptNewRelic.register(); // Register NewRelic
      this.cssOverlaButtons.register();
      this.middlewareService.$eventtoken.emit(true);
      // await this.locationService.locate();
     // this.scriptRegister.register();
      this.segmentAnalytics.startTracking();
      const status = await this.getMaintenanceStatus();
      await this.updateService.verifyAppUpdate();
      await this.textManagementService.getTexts();
      this.textManagementService.csutoinfo$.emit(true);

      if(!status){
        await this.ValidateBrowser();
      }

      let conf: string = this.storage.getNotificationsFlag();
      if (conf === null || conf === '') {
        this.storage.setNotificationsFlag('true');
        conf = 'true';
      }
      if (conf === 'true') {
        await this.notificationsService.initPush();
      }

      if (Capacitor.getPlatform() !== 'web') {
        await SplashScreen.hide();
      }
      this.storage.removeMiddleAuth();
    } catch (err) {
      console.log('initializeMiddlewareAuthorization', err);
      this.storage.removeMiddleAuth();
    }
  }

  private backButton(): void {
    App.addListener('backButton', ({canGoBack}) => {
      if(!canGoBack){
        App.exitApp();
      } else {
        if (this.router.url !== '/-/red') {
          window.history.back();
        }
      }
    });
  }

  private async getMaintenanceStatus(){
    const mainTenanceInfo = await this.maintenanceService.getMaintenanceRedirect();
    if(this.storage.getMaintenanceInfo()?.data?.active){
      //this.navController.navigateRoot('/-/Mantenimiento');
      return true;
    }
  }

 private async isvalidateOrderPaypal() {
    if (Capacitor.getPlatform() === 'web') {
      const body = this.storage.getPayloadPaypal();
      if (body) {
        const payloadPaypal = JSON.parse(body);
        if (payloadPaypal?.isBack && !location.pathname.includes('/-/loading')
        && !location.href.includes('PayerID')  && !location.href.includes('token')
        ) {
          const data = {
            isBack: false,
            orderId: true,
            token: payloadPaypal.token
          };
          this.storage.removePayloadPaypal();
          this.storage.setPayloadPaypal(JSON.stringify(data));
          if (this.storage.getIsWebView() === 'true') {
            this.navController.navigateRoot('/inicio?togoHome=true');
          } else {
            this.navController.navigateForward('/inicio');
          }
        }
      }
    }
 }

  async ValidateBrowser() {
    const platform = Capacitor.getPlatform();
    await this.locationService.locate();

    if(window.location.href.includes("/-/loading") || window.location.href.includes('/auth/callback') || window.location.href.includes("/-/ingresar") || window.location.href.includes("/-/ingresa") || (window.location.href.includes("code=") && this.storage.getTVAuthState()) || window.location.href.includes("/-/success-activate")){
      return;
    }
    if (platform === 'web')  {
      const text: any = await this.textManagementService.getTexts();
      const { AvailableBrowser } = text.NOTAVAILABLE_TS;
      if (AvailableBrowser) {
        if (AvailableBrowser.availablebrowserflag) {
          const browser = new BrowserDetector(window.navigator.userAgent);
          if ((browser.parseUserAgent().isMobile || browser.parseUserAgent().isTablet) && !browser.parseUserAgent().isWebkit) {
            const windows: any = window.navigator;
            if (windows.brave) {
              const findbrowserBrave = AvailableBrowser.browser.find(E => E.typemobile === windows.brave.isBrave.name);
              if (!findbrowserBrave) {
                this.navController.navigateRoot('/-/No-Disponible');
                return;
              }
            }
            if (!browser.parseUserAgent().name) {
              if ((!window.location.href.includes('loading') || !window.location.href.includes('payment-methods'))
                && !window.location.href.includes('token') &&
                !window.location.href.includes('type') && !window.location.href.includes('Scp')
                && !window.location.href.includes('Pvv')) {
                this.navController.navigateRoot('/-/No-Disponible');
                return;
              }
            }
            const parser = new UAParser(window.navigator.userAgent);
            if (parser.getResult().browser.name !== 'WebKit') {
              const findbrowsermobile = AvailableBrowser.browser.find(E => E.typemobile === parser.getResult().browser.name);
              if (!findbrowsermobile) {
                this.navController.navigateRoot('/-/No-Disponible');
              }
            }
          } else {
            if (browser.parseUserAgent().name) {
              const findbrowser = AvailableBrowser.browser.find(E => E.typedesktop === browser.parseUserAgent().name);
              if (!findbrowser) {
                this.navController.navigateRoot('/-/No-Disponible');
              }
            } else {
              if ((!window.location.href.includes('loading') || !window.location.href.includes('payment-methods'))
                && !window.location.href.includes('token') &&
                !window.location.href.includes('type') && !window.location.href.includes('Scp')
                && !window.location.href.includes('Pvv')) {
                this.navController.navigateRoot('/-/No-Disponible');
              }
            }
          }
        }
      }
    }
  }
}
