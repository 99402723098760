import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {VALIDATE_PROVIDER,VALIDATE_SESSION,VALIDATE_ENTITLEMENT,REMOVE_SESSION, CHANGE_CODE} from '../config/endpoints';
import {Storage} from '../core/storage/storage';
import { AuthService } from './auth.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import {Location} from '../core/interfaces/location';
import {Capacitor} from'@capacitor/core';
import { SegmentAnalytics } from '../core/providers/segment-analytics';
import {ActivateCodeService} from './activate-code.service';
import { DevicesService } from './devices.service';
import {COUNTRY_CODE_KEY} from '../config/constants';
import {EVENT_TRACK_013} from '../config/analytics/events-track';
import {environment} from '../../environments/environment';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
@Injectable({
  providedIn: 'root'
})
export class CarrierBillingService {

  constructor(private httpClient: HttpClient, private storage: Storage, private authService: AuthService,private iab: InAppBrowser,
              private segmentAnalytics: SegmentAnalytics, private activatedCodeService: ActivateCodeService,
              private deviceService: DevicesService,
              private appVersion: AppVersion) { }

  //method to validate providers
  public async validateProviders(){
    const headers: any = {
      'Content-Type': 'application/json',
    };
    return this.httpClient.get(VALIDATE_PROVIDER, { headers }).toPromise();
  }


  //method to validate session and get user information
  public async validateSession(token: string){
    const tvAuthState = this.activatedCodeService.getTVAuthState();
    const headers: any = {
      'Content-Type': 'application/json',
      'platform' : Capacitor.getPlatform() === 'web' ?  'web' : 'mobile'
    };
    const url = `${CHANGE_CODE}/${token}`;
    const deviceInfo = await this.deviceService.getDeviceData();
    deviceInfo.versionApp = Capacitor.getPlatform() === 'web' ? environment.WEB_VERSION : await this.appVersion.getVersionNumber();
    const response: any = await this.httpClient.post(url,deviceInfo,{ headers }).toPromise().catch((err) => {
      const objSegment = {
        journey: 'login Provider',
        error_id: err?.status ? err?.status : '',
        error_message: err?.message ? err?.message : '',
        error_type: err?.name ? err?.name : ''
      };
      this.segmentAnalytics.eventTrack(EVENT_TRACK_013, objSegment).then();
    });

   // const userInformation: any = response?.data;

    if(response.code === 200){
         await this.authService.setAuthPersonal(response);
         const user = this.storage.getUserCarrierBilling();
        //if(result){
        if (tvAuthState) {
          await this.activatedCodeService.activateCode(user, 'Toolbox');
        } else {
         // this.storage.setAccessToken(userInformation.token);
         // await this.authService.setAuthPersonal(userInformation);
          this.segmentAnalytics.identifyCustomer();
          this.storage.setEntitlement(user.entitlement, user);
        }
        return user.entitlement;
        //}
        //await this.httpClient.delete(`${REMOVE_SESSION}/${userInformation.token}`).toPromise();
       // return result;
    }
    return false;
  }

  //method to remove session of a personal user
  public async removeSession(id: string){
    const headers: any = {
      'Content-Type': 'application/json',
    };

   // this.browserService.openExternalLink(REMOVE_SESSION);
  }

  //method to get entitlemnt of the user login with personal
  public async validateEntitlement(idUser: string){
    let hasEntitlement = false;
    const headers: any = {
      'Content-Type': 'application/json',
    };
    const url = `${VALIDATE_ENTITLEMENT}/${idUser}`;
    const response: any = await this.httpClient.get(url, { headers }).toPromise();
    if(response.data && response.code === 200){
       hasEntitlement = response.data.access;
    }
    return hasEntitlement;
  }

  // Method for version 3 //
  public async validateSessionV3(token: string) {
    const tvAuthState = this.activatedCodeService.getTVAuthState();
    const headers: any = {
      'Content-Type': 'application/json',
      'platform' :  'mobile'
    };
    //const url = `https://preprod-mdl-ott.pxt.onl/api/v3/carrier-billing/change-code-tigosport/${COUNTRY_CODE_KEY}/${token}`;
    const url = `${environment?.MIDDLEWARE_PATH}/api/v3/carrier-billing/change-code-tigosport/${COUNTRY_CODE_KEY}/${token}`;
    const deviceInfo = await this.deviceService.getDeviceData();
    const response: any = await this.httpClient.post(url,deviceInfo,{ headers }).toPromise().catch((err) => {
      const objSegment = {
        journey: 'login Provider tv v3',
        error_id: err?.status ? err?.status : '',
        error_message: err?.message ? err?.message : '',
        error_type: err?.name ? err?.name : ''
      };
      this.segmentAnalytics.eventTrack(EVENT_TRACK_013, objSegment).then();
    });

    // const userInformation: any = response.data;

    if(response.code === 200){
      await this.authService.setAuthPersonal(response);
      const user = this.storage.getUserCarrierBilling();
      const autobj = {
        customer: {
          id: user ? user.id : '',
          subscriberId: user ? user.suscriber_id : '',
        },
        token: user ? user.token : ''
      };
      //if(result){
      if (tvAuthState) {
        await this.activatedCodeService.activateCodeV3(autobj, 'Toolbox');
      } else {
        // this.storage.setAccessToken(userInformation.token);
        // await this.authService.setAuthPersonal(userInformation);
        this.segmentAnalytics.identifyCustomer();
        this.storage.setEntitlement(user.entitlement, user);
      }
      return user.entitlement;
      //}
      //await this.httpClient.delete(`${REMOVE_SESSION}/${userInformation.token}`).toPromise();
      // return result;
    }
    return false;
  }

}
