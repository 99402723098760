import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ConfigurationsService} from './configurations.service';
import { Storage } from '../core/storage/storage';
//import * as moment from 'moment';
import moment from 'moment';
import {NavController, Platform} from '@ionic/angular';
import { LocationService } from './location.service';
import {AwsS3ServicesService} from './aws-s3-services.service';
import {Capacitor} from '@capacitor/core';
import {IS_ADAPTABLE} from '../config/constants';

@Injectable({
  providedIn: 'root'
})
export class TextManagementV2Service {
  isAdaptable = IS_ADAPTABLE;

    constructor(private configurationService: ConfigurationsService,private storage: Storage, private navController:NavController,private locationService:LocationService,
                private serviceAmazonS3: AwsS3ServicesService, private platform: Platform) { }

    public async getTexts() {
        try{
            let storedOptions = this.storage.getTextsV2();
            if (storedOptions) {
                const day = moment().diff(storedOptions.createdAt, 'minutes',true);
                if (day >= 1) {
                    await this.saveTexts();
                }
            } else {
                await this.saveTexts();
            }
            storedOptions = this.storage.getTextsV2();

            const texts = this.getFormattedTexts(storedOptions);

            return texts;
        }catch(e){
            console.log(e);
        }

    }

    private async saveTexts(){
        await this.locationService.locate();
        try{
            const currentTexts =  await this.configurationService.getConfigurationByVariable("ott_text_v2");
            if(currentTexts){
                const objResponse = await this.sendRequestValuesS3();
                currentTexts.UserConcurrentsOn.status = await this.getValuesS3('UserConcurrentsOn', objResponse);
                currentTexts.concurrentMasterUsersOn.status = await this.getValuesS3('concurrentMasterUsersOn', objResponse);
                currentTexts.isEntitlement = await this.getValuesS3('entitlement', objResponse);
                currentTexts.multiEntitlement.status = await this.getValuesS3('multiEntitlement', objResponse);
                this.storage.setTextsV2(currentTexts);
            }
        }catch(e){
            console.log(e);
        }
    }

    private getFormattedTexts(storedTexts: any): any {
        const currentTexts = (storedTexts) ? storedTexts?.data : '';

        return {
            USERCONCURRENTON: currentTexts ? (currentTexts.UserConcurrentsOn) : {},
            CONCURRENT_MASTER_USERS_ON: currentTexts ? (currentTexts.concurrentMasterUsersOn) : {},
            FAVORITE_TEAMS: currentTexts ? (currentTexts.favoriteTeamsOn) : {},
            ASSET_DOMAIN: currentTexts ? (currentTexts.assetDomain) : {},
            MULTI_ENTITLEMENT: currentTexts ? (currentTexts.multiEntitlement) : {},
            WEB_GL: currentTexts ? (currentTexts.webGL) : {},
            VECIMAOPTIONS: currentTexts ? (currentTexts.vecimaOptions) : {},
            ENTITLEMENT: currentTexts ? (currentTexts.isEntitlement) : false,
        };
      }


  private async sendRequestValuesS3() {
      return await this.serviceAmazonS3.getConfigValuesS3().catch(() => {});
  }

  private async getValuesS3(event: string, responseConfig: any) {
    let value: boolean;
    if (Capacitor.isNativePlatform() && this.platform.platforms().indexOf('ios') >= 0) {
      console.log('***** ES IOS');
      value = this.returnOptionConfig(responseConfig, event, 'ios');
    } else if (Capacitor.isNativePlatform() && this.platform.platforms().indexOf('android') >= 0 && this.isAdaptable) {
      console.log('***** ES ANDROID MOVIL');
      value = this.returnOptionConfig(responseConfig, event, 'androidMovil');
    } else if (Capacitor.isNativePlatform() && this.platform.platforms().indexOf('android') >= 0 && !this.isAdaptable) {
      console.log('***** ES ANDROID TV');
      value = this.returnOptionConfig(responseConfig, event, 'androidTv');
    } else {
      console.log('***** ES WEB');
      value = this.returnOptionConfig(responseConfig, event, 'web');
    }
    return value
  }

  private returnOptionConfig(responseConfig: any, event: string, platform: string) {
      return responseConfig[event][platform];
  }

}
