import {Injectable} from '@angular/core';
import {USER_DATA, USER_REGISTER} from '../config/endpoints';
import { HttpClient } from '@angular/common/http';
import {Response} from '../core/interfaces/response';
import {RegisterRequest} from '../core/interfaces/register-request';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  /* Request creation of a new user  */
  public async create(body: RegisterRequest): Promise<boolean> {
    let status = false;

    try {
      const response: Response = await this.httpClient.post<Response>(USER_REGISTER, body).toPromise();
      status = response.success;
    } catch (error) {}
    return status;
  }

  /* Return user data */
  public async getUserData(body: any): Promise<any> {
    let data: any;

    try {
      data = await this.httpClient.post<Response>(USER_DATA, body).toPromise();
    } catch (error) {}

    return data;
  }
}
