import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GET_OTHER_CONTENT,GET_CONTENT_CATEGORY } from '../config/endpoints';
import { CARROUSEL_LIMIT, IS_ADAPTABLE } from '../config/constants';
//import * as moment from "moment";
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class OtherContentService {

  isAdaptable = IS_ADAPTABLE;
  carrouselLimit = 10;
  contPage = 1;
  constructor(private httpClient: HttpClient) { }

  /* Get 'other-content' events */
  public async getEvents(page: number, assetDomain: any, id?:any, queryParams?: any): Promise<any> {
    let events: any = [];
    let eventsAux = [];
    let eventsDataAux = {};
    let cont = 0;
    let url = ""
    if(page === 1){
      this.contPage = 1;
    }
    url = id ?   `${GET_OTHER_CONTENT}/${id}/${this.contPage++}/${this.carrouselLimit}` : `${GET_OTHER_CONTENT}/${this.contPage++}/${this.carrouselLimit}`;
    try {
      const dataResponse : any = await this.httpClient.get(url, {params: queryParams}).toPromise();
      dataResponse.forEach((event) => {
        eventsDataAux = {
          contentful_id: event.contentful_id,
          title : event.contentful_data.fields.name,
          image: this.getImage(event, assetDomain),//`https:${event.contentful_data.fields.vertical_thumbnails.file.fields.file.url}`,
          createdAt: event.contentful_data.sys.createdAt,
          championship_id : event.contentful_data.fields.league ? event.contentful_data.fields.league.sys.id : '',
          championship_name : event.league,
          sport_name : event.sport ? event.sport : '',
          video_title: event.contentful_data.fields.name,
          content_id: event.contentful_id,
          home_team_id: event.home_team_data ? event.home_team_data.contentful_id : '',
          home_team: event.home_team_data ? event.home_team_data.json.fields.name : '',
          away_team_id: event.away_team_data ? event.away_team_data.contentful_id : '',
          away_team: event.away_team_data ? event.away_team_data.json.fields.name : '',
          total_length: event.contentful_data ? (event.contentful_data.fields.totalLength ? event.contentful_data.fields.totalLength : event.contentful_data?.fields?.videoTime ) : 0,
          session_id: '',
          video_quality: '',
          content_category: 'other content',
          content_type: event.contentful_data.sys.contentType.sys.id,
          schedule: this.getFormattedSchedule(new Date(event.created_at)),
        }
        cont += 1;
        if (cont <= this.carrouselLimit) {
          eventsAux.push(eventsDataAux);
        }
      });
      events = dataResponse ? eventsAux : [];
    } catch (error) {}
    return events;
  }

  public async getCategoryList(){
    const dataResponse : any = await this.httpClient.get(`${GET_CONTENT_CATEGORY}`).toPromise();
    return dataResponse;
  }



  /* Get formatted schedule */
  private getFormattedSchedule(startDate: Date) {
    let date = startDate.getDate();
    let month = startDate.getMonth();
    let year = startDate.getFullYear();
    let startHour = moment(startDate).format("HH:mm");
    let formattedDate = ""
    if (startDate){
      formattedDate = `${date}/${month + 1}/${year} ${startHour}`;
    }
    return formattedDate;
  }

  private getImage(obj, assetDomain){
    let domain = assetDomain?.highlights ? assetDomain.highlights : assetDomain.default

    if(assetDomain?.status && assetDomain.status === true && obj?.verticalThumbnailsS3){
      return domain + obj.verticalThumbnailsS3;
    }
    else
      return `https:${obj.contentful_data.fields.vertical_thumbnails.file.fields.file.url}`
  }
}
