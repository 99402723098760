<div *ngIf="showOtherContent" class="eventCardsVertical">
  <div class="row">
    <div class="col">
    <h3>{{ title }}</h3>
  </div>
  <ng-container *ngIf="id_ev == 'home'">
   <div class="col d-flex d-flex justify-content-end text-center tv-row">

    <button id="view-more-otherContent" class="view-more" [ngClass]="{'tv-control': !isAdaptable}"  [class.focus-button]="!isAdaptable"  (click)="moreContent()" tabindex="8">Ver más</button>
</div>
</ng-container>
</div>
  <ion-grid>
    <ion-row>
      <div *ngIf="isWeb && others.length > 3" class="carrousel__arrow arrowLeft" tabindex="0" (click)="handleScrollSegment(-250)">
        <ion-img src="assets/graphics/options/buttons/ico_arrow.svg"></ion-img>
      </div>
      <ion-col>
        <ion-segment id="{{id_ev}}" class="tv-row tv-control-none tv-otherContents-rows" [ngClass]="{'segment-tv-vertical': !isAdaptable}" scrollable (scroll)="doSomethingOnScroll($event)">
          <div *ngIf="banneravailable && isAdaptable" id='div-gpt-ad-1661900411589-0' style='min-width: {{widthbanner}}px; min-height: {{heightbanner}}px; margin-right: {{marginbanner}}em;'>
          </div>
          <div class="eventCardsVertical__Item" [ngClass]="{'tv-control': !isAdaptable}" *ngFor="let item of others" value="item.title" (click)="viewDetail(item)" [id]="'other-content-' + item.contentful_id" tabindex="7">
            <ion-img [src]="item.image ? item.image : loadingImg" alt={{item.id}}></ion-img>
            <img class="icoPlayInternal" src="assets/graphics/options/buttons/ico_play_internal.svg" alt="">
            <div class="eventCardsVertical__Info">
              <ion-label class="infoTitle">{{item.title}}</ion-label>
            </div>
          </div>
        </ion-segment>
      </ion-col>
      <div *ngIf="isWeb && others.length > 3" class="carrousel__arrow arrowRight" tabindex="2" (click)="handleScrollSegment(250)">
        <ion-img src="assets/graphics/options/buttons/ico_arrow.svg"></ion-img>
      </div>
    </ion-row>
  </ion-grid>
</div>
